<template>
    <div class="row rounded">
        <div class="col-12 p-0">
            <nav class="navbar navbar-expand-lg navbar-light navbar-laravel rounded-top pt-1 pb-1" style="background-color:#a02949;">
                <!--<a class="navbar-brand" href="#">Analysis</a>-->
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#analysisNavbar" aria-controls="analysisNavbar" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="analysisNavbar">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item" v-for="tab in charts" :class="{ 'active' : tab.isActive }"><a class="nav-link" href="#" @click="displayChart(tab)">{{tab.name}}</a></li>
                    </ul>
                    <form class="form-inline">
                        <input class="form-control w-100" type="search" placeholder="Select Date Range:" aria-label="Select Date:" id="input_date_analytics" v-model="selectedDate">
                    </form>
                </div>
            </nav>
        </div>
        <div class="loadingSpinnerContainer align-middle rounded" v-if="isLoadingData">
            <self-building-square-spinner class="mx-auto"
                :animation-duration="6000"
                :size="40"
                color="#ff1d5e"
            />
        </div>
        <div class="col-lg-10 order-1 order-lg-0 navbar-expand-md">
            <div v-for="chart in charts">
                <div v-if="chart.isDownload" v-show="chart.isActive">
                  <div class="d-flex justify-content-center">
                    <a class="btn btn-primary mt-5" :href="currentDownloadUrl(chart.url)" download>Download CSV</a>
                  </div>
                </div>
                <div v-else-if="chart.type == null || chart.type == 'chart'" :id="chart.name" v-show="chart.isActive" class="w-100 chartHeight"></div>
                <div v-else-if="chart.type == 'pie'" :id="chart.name" v-show="chart.isActive" class="w-100 chartHeight"><h2 class="text-center w-100" style="position: absolute;top: 40%;transform: translateY(-60%);">Coming Soon</h2></div>
                <div v-else v-show="chart.isActive">
                    <br>
                    <div class="progress h-35px">
                        <div :id="chart.name" class="progress-bar progress-bar-striped" role="progressbar" :style="{'width':chart.data.width+'%'}" :aria-valuenow="chart.data.width" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <a>Total {{ chart.data.data }}</a>
                        </div>
                        <div class="col-4 text-center">
                            <a> {{ chart.data.width + '%' }}</a>
                        </div>
                        <div class="col-4 text-right">
                            <a>Total Target {{ chart.data.target }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 p-0 pr-0 order-0 order-lg-1">
            <nav class="navbar navbar-expand-lg navbar-light rounded-bottom m-0" style="background-color:#a02949;">
                <a class="navbar-brand d-block d-lg-none" href="#">Hide Information About Franchise</a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#checkBoxHideRows" aria-controls="checkBoxHideRows" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse pb-2" id="checkBoxHideRows">
                    <div class="row">
                        <div class="col-12"  v-if="currentChart.hasPeriod">
                            <select class="form-control" v-model="week_period">
                                <option class="select_route_multiple" value="4">4 Weeks</option>
                                <option class="select_route_multiple"  value="12">12 Weeks</option>
                            </select>
                        </div>
                        <div class="col-12"  v-if="franchise_id !== null">
                            <select class="form-control" multiple="multiple" v-model="selected_routes">
                                <option class="select_route_multiple" v-for="route in routes" :value="route.id">{{ route.name }}</option>
                            </select>
                        </div>
                        <div class="col-12">
                            <div class="input-group mb-2 mt-2">
                                <input type="text" v-model="search_term" class="form-control" placeholder="Search">
                            </div>

                            <button class="btn btn-primary" @click="showAll()">Select All</button>
                            <button class="btn btn-primary" @click="showAll(false)">Clear All</button>
                            <ul class="list-group w-100 pt-2" v-if="franchise_id !== null || currentChart.customData !== undefined">
                                <li class="list-group-item pl-5 show-all-checkbox-container" v-for="item in shown_data">
                                    <input type="checkbox" class="form-check-input checkbox-display show-all-checkbox" :id="item.name + 'ShowHideLine'" @click="hideItem(item.name)" checked>
                                    <label class="form-check-label text-primary checkbox-item" :for="item.name + 'ShowHideLine'">{{item.name}}</label>
                                </li>
                            </ul>
                            <ul class="list-group w-100 pt-2"  v-else>
                                <li class="list-group-item pl-5 show-all-checkbox" v-for="item in shown_data">
                                    <input type="checkbox" class="form-check-input show-all-checkbox" :id="item.user.name + 'ShowHideLine'" @click="hideItem(item.user.name)" checked>
                                    <label class="form-check-label text-primary checkbox-item" :for="item.user.name + 'ShowHideLine'">{{item.user.name}}</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    import { SelfBuildingSquareSpinner  } from 'epic-spinners'
    export default {
        created() {
            this.charts = this.chart_data;
            this.charts.forEach(chart => {
                if(chart.isActive){
                    this.currentChart = chart;
                }
            });
        },
        mounted(){
            if(this.franchise_id != null){
                axios.get('/api/analytics/franchise/product_groups').then(response => {
                    this.product_groups = response.data;
                    this.shown_data = this.product_groups;
                });
                axios.get('/api/analytics/franchise/'+ this.franchise_id +'/routes').then(response => {
                    this.routes = response.data;
                });
            } else {
                axios.get('/api/analytics/franchise/all').then(response => {
                    this.product_groups = response.data;
                    this.shown_data = response.data;
                });
            }
        },
        components: { SelfBuildingSquareSpinner },
        props: {
                    'chart_data': Array,
                    'franchise_id' : {required: false, default: null}
                },
        data() {
            return {
                product_groups: null,
                routes: null,
                selected_routes: [],
                shown_data: null,
                charts: null,
                currentChart: null,
                selectedDate: null,
                isLoadingData: false,
                checkedItems: [],
                selected_franchises: [],
                week_period: null,
                search_term: ''
            };
        },
        methods: {
            displayChart(selectedChart, force_reload = false){
                this.setDate();
                if(this.selectedDate != null || selectedChart.lastSearchedDate == null){
                    this.showSelectedChart(selectedChart);
                    this.currentChart = selectedChart;
                    if(selectedChart.customData != null){
                        this.shown_data = selectedChart.customData;
                    } else {
                        this.shown_data = this.product_groups;
                    }
                    if(selectedChart.data == null || selectedChart.lastSearchedDate != this.selectedDate || force_reload){
                        this.isLoadingData = true;
                        selectedChart.lastSearchedDate = this.selectedDate;
                        this.getData(selectedChart);
                    }
                }
            },
            showSelectedChart(selectedChart){
                this.charts.forEach(chart => {
                    chart.isActive = (chart.name == selectedChart.name);
                });
            },
            setDate(){
                if(this.selectedDate != null && this.selectedDate.indexOf('to') == -1){
                    this.selectedDate = null;
                }
            },
            getData(selectedChart){

                if(selectedChart.isDownload){
                  this.isLoadingData = false;

                  // let tmpDate = new Date();
                  // let month = (tmpDate.getMonth() + 1).toString().padStart(2, '0');
                  //
                  // let element = document.querySelector('#input_date_analytics')._flatpickr;
                  //
                  // element.clear();
                  // element.setDate([
                  //             `${tmpDate.getFullYear()}-${month}-01`,
                  //             `${tmpDate.getFullYear()}-${month}-${tmpDate.getDate()}`
                  //         ]);
                  //
                  // this.selectedDate = `${tmpDate.getFullYear()}-${month}-01 to ${tmpDate.getFullYear()}-${month}-${tmpDate.getDate()}`;

                  return;
                }

                axios.get(selectedChart.url, {
                    params: {
                        date_analytics: this.selectedDate,
                        routes: this.selected_routes,
                        selected_franchises: this.selected_franchises,
                        week_period: this.week_period
                    }
                }).then(response => {

                    if(response.data.customData != null){
                        this.data = response.data.customData;
                        this.shown_data = response.data.customData;
                        selectedChart.customData =  $.extend(true, {}, response.data.customData);
                    }
                    if(selectedChart.type == null || selectedChart.type == 'chart'){
                        selectedChart.data = response.data;
                        selectedChart.fullData = $.extend(true, {}, response.data);
                        selectedChart.subtext = ((response.data.subtext != null) ? response.data.subtext : null);
                        this.createChart(selectedChart);
                    } else if (selectedChart.type == 'pie') {
                        selectedChart.data = response.data;

                    } else {
                        selectedChart.data = response.data;
                    }
                    this.isLoadingData = false;
                });
            },
            currentDownloadUrl(url){
                let params = {
                  date_analytics: this.selectedDate
                };

                return url + '?' + Object.keys(params)
                                    .map((item) => {
                                        return params[item] != null
                                          ? item + '=' + encodeURI(params[item])
                                          : null;
                                    })
                                    .filter((item) => {
                                        return item != null;
                                    })
                                    .join('&');
            },
            createPieChart(selectedChart, id, index = 0) {
                let linechart = echarts.init(document.getElementById(selectedChart.name));
                option = {
                    title: {
                        text: id,
                        //subtext: ,
                        left: 'center'
                    },
                    tooltip : {
                        trigger: 'item',
                        formatter: "{a} <br/>{b} : {c} ({d}%)"
                    },
                    series : [
                        {
                            type: 'pie',
                            radius : '65%',
                            center: ['50%', '50%'],
                            data: selectedChart.data[index]
                            // data:[
                            //     {
                            //         value:1548,
                            //         name: '幽州',
                            //     },
                            //     {value:535, name: '荆州'},
                            //     {value:510, name: '兖州'},
                            //     {value:634, name: '益州'},
                            //     {value:735, name: '西凉'}
                            // ]
                        }
                    ]
                };
                linechart.setOption(option);
                selectedChart.chart = linechart;
            },
            createChart(selectedChart){
                //document.getElementById(selectedChart.name).innerHTML = '';
                if(selectedChart.chart != null){
                    selectedChart.chart.dispose();
                }
                let linechart = echarts.init(document.getElementById(selectedChart.name));

                const option = {
                    title: {
                        text: selectedChart.name,
                        subtext: selectedChart.subtext
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data:['1','2','3','4','5']
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: (selectedChart.data.headers)
                    },
                    yAxis: {
                        type: 'value',
                    },
                    series: (selectedChart.data.data)
                };

                var colour_order = [];
                $.each(selectedChart.data.data, function(index, value){
                    if(value.colour != null) {
                        colour_order.push(value.colour);
                    }
                });
                if(colour_order.length != 0) {
                    option.color = colour_order;
                }

                linechart.setOption(option);
                selectedChart.chart = linechart;




            },
            hideItem(itemName){
                let self = this;
                if(this.checkedItems.indexOf(itemName) == -1){
                    this.checkedItems.push(itemName);

                    if(this.currentChart.data === undefined){
                      return
                    }

                    $.each(this.currentChart.data.data, function (index, value) {
                        if(value != undefined && value.name == itemName){
                            self.currentChart.data.data.splice(index, 1);
                            self.redrawChart();
                        }
                    });
                } else {
                  if(this.currentChart.fullData === undefined){
                    return
                  }

                    this.checkedItems.splice(this.checkedItems.indexOf(itemName),1);
                    $.each(this.currentChart.fullData.data, function (index, value) {
                        if(value.name == itemName){
                            self.currentChart.data.data.push(self.currentChart.fullData.data[index]);
                            self.redrawChart();
                        }
                    });
                }
            },
            redrawChart(){
                this.currentChart.chart.clear();
                this.createChart(this.currentChart);
            },
            showAll(show = true){
                let self = this;

                if(show) {
                    $('.show-all-checkbox').prop('checked', true);
                } else {
                    $('.show-all-checkbox').prop('checked', false);
                }

                $.each(this.shown_data, function (index,value) {
                    if(value.user != undefined){
                        self.hideItem(value.user.name);
                    } else {
                        self.hideItem(value.name);
                    }
                });
            },
        },
        watch: {
            selectedDate: function (val) {
                if(val.indexOf("to") != -1){
                    this.displayChart(this.currentChart);
                }
            },
            selected_routes: function(val) {
                this.displayChart(this.currentChart, true);
            },
            selected_franchises: function(val) {
                if(this.currentChart !== undefined){
                  this.displayChart(this.currentChart, true);
                }
            },
            week_period: function(val) {
                this.displayChart(this.currentChart, true);
            },
            search_term: function(val) {
                $('.show-all-checkbox-container').each(function(index, value){
                    if(value.children[1].innerHTML.toLowerCase().indexOf(val.toLowerCase()) != -1){
                        value.classList.remove('d-none');
                    } else {
                        value.classList.add('d-none');
                    }
                });
            }
         }
    }
</script>
