<template>
	<div class="container mx-0">
		<div class="row">
			<div class="col-5">
				<pie-chart
					:data="[
						{value: data.more_than_3, name: 'More than 3'},
						{value: data.between_1_and_3, name: 'Between 1 and 3'},
						{value: data.less_1, name: 'Less than 1'},
					]"

					:no_margin="true"
					:width="200"
					:color="colors"
				></pie-chart>
			</div>
			<div class="col-7 my-auto">
				<div class="container px-0 mt-4">

					<div class="row">
						<div class="col-7">
							<h5 class="font-weight-bold">Buying 3+ Times p/week</h5>
						</div>
						<div class="col-2">
							<h5 class="font-weight-bold">{{numberFormatWCurrencyCheck(data.more_than_3)}}</h5>
						</div>
						<div class="col-3">
							<h5 class="font-weight-bold">{{numberFormatWCurrencyCheck(Math.round(data.more_than_3 / data.total * 100) + '%')}}</h5>
						</div>
					</div>

					<div class="row">
						<div class="col-7">
							<h5 class="font-weight-bold">Buying 1-3 Times p/week</h5>
						</div>
						<div class="col-2">
							<h5 class="font-weight-bold">{{numberFormatWCurrencyCheck(data.between_1_and_3)}}</h5>
						</div>
						<div class="col-3">
							<h5 class="font-weight-bold">{{numberFormatWCurrencyCheck(Math.round(data.between_1_and_3 / data.total * 100) + '%')}}</h5>
						</div>
					</div>

					<div class="row">
						<div class="col-7">
							<h5 class="font-weight-bold">Buying less than once p/week</h5>
						</div>
						<div class="col-2">
							<h5 class="font-weight-bold">{{numberFormatWCurrencyCheck(data.less_1)}}</h5>
						</div>
						<div class="col-3">
							<h5 class="font-weight-bold">{{numberFormatWCurrencyCheck(Math.round(data.less_1 / data.total * 100) + '%')}}</h5>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PieChart from "./PieChart";
	export default {
		name: "CustomerBuyingHabits",
		components: {PieChart},
		props: [
			'data',
			'colors'
		]
	}
</script>