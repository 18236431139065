
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('epic-spinners');
import Formatter from './formatter';
import Sorter from './sorter';

import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'

window.Formatter = Formatter;
window.Sorter = Sorter;

window.Vue = require('vue').default;
window.Flatpickr = require("flatpickr");
require("flatpickr/dist/themes/material_red.css");

$(document).ready(function(){
    $('.flatpickr-init').each(function(){
        $(this).flatpickr({
            altInput: true,
            altFormat: "d/m/Y",
            dateFormat: "Y-m-d",
            defaultDate: $(this).data('date')
        });
    });
});

function getCheckValue(value, options){
    let returnValue = {
        value: 0,
        prepend: '',
        append: '',
        options: { minimumFractionDigits: 0, maximumFractionDigits: 0 }
    };
    if (typeof(value) == "undefined" || value === null) {
        return returnValue;
    }

    returnValue.options = $.extend({}, returnValue.options, options);

    returnValue.value = value;

    if(value.toString().charAt(0) == '£'){
        returnValue.value = Formatter(parseFloat(value.substr(1)), 'currency');
    }

    else if(value.toString().charAt(value.toString().length - 1) == '%') {
        returnValue.value = Formatter(value.substr(0, value.toString().length - 1), 'percentage');
    }

    else if(value.toString().substr(value.toString().length - 1) == 'h') {
        returnValue.value = Formatter(value.substr(0, value.toString().length - 1)) + 'h';
    }

    else if(value.toString().substr(value.toString().length - 1) == 'M') {
        returnValue.value = Formatter(value.substr(0, value.toString().length - 1), 'time');
    }

    else if(value.toString().substr(value.toString().length - 3) == 'h:m') {
        returnValue.value = Formatter(value.substr(0, value.toString().length - 3), 'nearest5');
    }

    else {
        returnValue.format = true;
    }

    return returnValue;
}

function numberFormatWCurrencyCheck(value, options = {}) {

    let _options = getCheckValue(value, options);
    let formattedValue = _options.value;
    
    if(_options.format == true) {
        formattedValue = new Intl.NumberFormat('en-GB', _options.options).format(_options.value);
    }

    if(value === ''){
        formattedValue = '';
    }

    if(formattedValue != 'NaN'){
        return _options.prepend + formattedValue + _options.append;
    }
    return value;
}

function nonZero(value, ifZero = 1){
    return value != 0 ? value : ifZero;
}

Vue.mixin({
    methods: {
        numberFormat: (value, options, defaultValue = 0) => {
            if(isNaN(value)){
                value = defaultValue;
            }

            options = $.extend({minimumFractionDigits: 2, maximumFractionDigits: 2}, options);
            return new Intl.NumberFormat('en-GB', options).format(value)
        },

        format: Formatter,

        numberFormatWCurrencyCheck: numberFormatWCurrencyCheck,

        nonZero: nonZero,

        asset: (path) => {
            return (process.env.MIX_ASSET_URL || '') + '/' + path.replace(/^\/+/, '')
        }
    }
});

require('./vue_components');

const app = new Vue({
    el: '#app'
});


Vue.directive('tooltip', VTooltip)


flatpickr("#input_date_analytics", {
    mode: "range",
    maxDate: "today",
    dateFormat: "Y-m-d",
});
flatpickr("#input_date_franchise_leaderboard", {
    mode: "range",
    maxDate: "today",
    dateFormat: "Y-m-d"
});

$(document).ready(function(){

    $('.franchiseStatusInput').select2();

    $('[data-form].flatpickr-init').each(function(){
        flatpickr(this, {
            dateFormat: "d/m/Y",
            defaultDate: $(this).data('value'),
            onChange: function(selectedDates, date, instance){
                $(`#${$(instance.input).data('form')} input[name='${$(instance.input).data('maps-to')}']`).val(date);
            }
        });
    });

    $('[data-form]').on('keyup', function(){
        console.log($(this).val(), $(this).data('maps-to'));
        $(`#${$(this).data('form')} input[name='${$(this).data('maps-to')}']`).val($(this).val());
    });

    $('#target-csv').change(function(event){
        var content = '';
        if( this.files && this.files.length > 1 ) {
            content = `Upload File {${this.files[0].name}}`;
        }
        else {
            content = event.target.value.split( "\\" ).pop();
        }

        console.log($(this), $(this).attr('id'));
        console.log(`[for="${$(this).attr('id')}"]`, $(`[for="${$(this).attr('id')}"]`), content);

        $(`[for="${$(this).attr('id')}"]`).html(`<i class="fas fa-upload mr-1"></i> ${content}`);
    });
    // var label = input.nextElementSibling,
    //     labelVal = label.innerHTML;
    //
    // input.addEventListener( 'change', function( e )
    // {
    //     var fileName = '';
    //     if( this.files && this.files.length > 1 )
    //         fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
    //     else
    //         fileName = e.target.value.split( '\' ).pop();
    //
    //     if( fileName )
    //         label.querySelector( 'span' ).innerHTML = fileName;
    //     else
    //         label.innerHTML = labelVal;
    // });
});

$('#show-impersonation').click(function(){
    $(this).toggleClass('active');
});
