<template>
    <div class="collapse show">
        <div class="collapse-title d-flex justify-content-between" @click="toggleCollapse">
            <div>
                <slot name="title"></slot>
            </div>

            <div class="collapse-icon" :class="{'active': currentValue}">
                <i class="fa fa-chevron-down"></i>
            </div>
        </div>
        <transition
            v-on:before-enter="beforeEnter"
            v-on:enter="enter"
            v-on:after-enter="afterEnter"

            v-on:before-leave="beforeLeave"
            v-on:leave="leave"
        >
            <div class="collapse-content overflow-hidden" ref="collapseContent" v-if="currentValue">
                <slot></slot>
            </div>
        </transition>

    </div>
</template>

<script>
export default {
    name: "Collapse",

    props: {
        value: {
            type: Boolean,
            default: false
        }
    },

    data(){
        return {
            currentValue: this.value
        }
    },

    methods: {
        toggleCollapse(){
            this.currentValue = !this.currentValue;

            this.$emit('input', this.currentValue);
        },

        //enter starts from 0 to scroll height
        beforeEnter(el){
            el.style.height = '0px';
        },

        enter(el, done){
            el.style.height = el.scrollHeight + 'px';

            setTimeout(() => {
                done();
            }, 250);
        },

        afterEnter(el){
            el.style.removeProperty('height');
        },

        beforeLeave(el){
            el.style.height = el.scrollHeight + 'px';
        },

        leave(el, done){
            //requestAnimationFrame occasionally wouldn't trigger the animation so timeout makes sure
            //that the height has persisted before the animation starts
            setTimeout(() => {
                el.style.height = '0px';

                setTimeout(() => {
                    done();
                }, 250);
            }, 2)
        }
    },

    watch: {
        value(newValue){
            this.currentValue = newValue;
        }
    }
}
</script>

<style lang="scss" scoped>
    .collapse-content {
        transition: height 0.25s;
    }

    .collapse-icon {
        transition: transform 0.25s;

        &.active {
            transform: rotateZ(180deg);
        }
    }
</style>
