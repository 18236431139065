<template>
    <div class="row">
        <div class="col-3 notification__sidebar p-4">
            <a class="w-100 btn btn-primary mb-4" :href="notificationSetUrl">Use a Notification Set</a>

            <h3 class="mb-4">Select Pre-designed Notification</h3>

            <div class="notification-template-group mb-4">
                <h5 class="title mb-3">Create your own</h5>

                <ul class="notification-template-group__list">
                    <li class="notification-template-group__list--item py-1" :class="{active: selectedNotification.id == undefined}">
                        <div class="custom-control custom-radio">
                            <input type="radio" id="predefined-notification--none" name="selected-notification" class="custom-control-input" :value="{}" v-model="selectedNotification">
                            <label class="custom-control-label" for="predefined-notification--none">Blank Template</label>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="notification-template-group mb-4" v-for="(notificationTemplateGroup, notificationTemplateName) in groupedPredefinedNotifications">
                <h5 class="title mb-3">{{notificationTemplateName}}</h5>

                <collapse v-for="(groupPredefinedNotifications, notificationGroupId) in notificationTemplateGroup" :key="notificationGroupId">
                    <template slot="title">
                        <h6 class="subtitle">{{groupPredefinedNotifications.name}}</h6>
                    </template>
                    <template>
                        <ul class="notification-template-group__list">
                            <li class="notification-template-group__list--item py-1" :class="{active: selectedNotification.id == predefinedNotification.id}" v-for="predefinedNotification in groupPredefinedNotifications.items">
                                <div class="custom-control custom-radio">
                                    <input type="radio" :id="`predefined-notification--${predefinedNotification.id}`" name="selected-notification" class="custom-control-input" :value="predefinedNotification" v-model="selectedNotification">
                                    <label class="custom-control-label" :for="`predefined-notification--${predefinedNotification.id}`">{{predefinedNotification.title}}</label>
                                </div>
                            </li>
                        </ul>
                    </template>
                </collapse>
            </div>
        </div>
        <div class="col-9 notification__form p-4">
            <h3 class="mb-4">Build Notification</h3>

            <notification-create
                v-bind="$props"

                :notification="selectedNotification"
                :key="selectedNotification.id"
            ></notification-create>
        </div>
    </div>
</template>

<script>
import Collapse from "./generic/Collapse";

export default {
    name: "FranchiseNotificationCreate",

    components: {
        Collapse
    },

    props: {
        predefinedNotifications: {
            type: Array,
            required: true
        },

        templates: {
            required: true,
            type: Object
        },

        routes: {
            required: false,
            type: Array,
            default: () => []
        },

        notificationTypes: {
            required: true,
            type: Array
        },

        recipientTypes: {
            required: true,
            type: Array
        },

        submitUrl: {
            required: true,
            type: String
        },

        redirectUrl: {
            required: true,
            type: String
        },

        franchiseNumber: {
            required: false,
            type: String,
            default: ''
        },

        notification: {
            required: false,
            type: Object,
            default: () => { return {}; }
        },

        imageLibraryUrl: {
            required: true,
            type: String
        },

        notificationSetUrl: {
            required: true,
            type: String
        }
    },

    data(){
        return {
            selectedNotification: {}
        }
    },

    beforeMount() {
        this.selectedNotification = this.notification;
    },

    computed: {
        groupedPredefinedNotifications(){
            return this.predefinedNotifications.reduce((notifications, notification) => {

                let templateGroup = {
                    'product_update': 'Special Offers, Products and Promotions',
                    'status_update' : 'Van Status Notifications'
                }[notification.template.slug];

                if(notifications[templateGroup] === undefined){
                    notifications[templateGroup] = {};
                }

                if(notification.notification_groups.length == 0){
                    notification.notification_groups = [
                        {
                            id: 'uncategorised',
                            name: 'Uncategorised'
                        }
                    ];
                }

                Object.values(notification.notification_groups).forEach((group) => {
                    console.log(group);
                    if(notifications[templateGroup][group.id] === undefined){
                        notifications[templateGroup][group.id] = {
                            name: group.name,
                            items: []
                        };
                    }

                    notifications[templateGroup][group.id]['items'].push(notification);
                })


                return notifications;
            }, {})
        }
    }
}
</script>
