<template>
    <th scope="col" class="light-label" @click="sort">
        <div class="d-inline-flex">
            <div class="text-center justify-content-center pr-2">
                <slot></slot>
            </div>
            <span v-show="isSorted && sortDirection == 'asc'">
                <i class="fas fa-sort-up"></i>
            </span>
            <span v-show="isSorted && sortDirection == 'desc'">
                <i class="fas fa-sort-down"></i>
            </span>
            <span v-show="!isSorted">
                <i class="fas fa-sort" ></i>
            </span>
        </div>
    </th>
</template>

<script>
    export default {
        name: "TableSortingHelper",

        props: {
            'isSorted': {
                required: true,
                type: Boolean
            },
            'sortDirection': {
                required: true,
                type: String
            }
        },

        computed: {
            sorted: () => (direction) => {
                return this.isSorted && this.sortDirection == direction;
            }
        },

        methods: {
            sort() {
                return this.$emit('sort');
            },

        }
    }
</script>