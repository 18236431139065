<template>
	<div class="dashboard-page">

		<!--Top Filters Header-->
		<report-nav-bar
			:routes="routes"
			:date_ranges="date_ranges"

			:default_range="0"
			:hide_today="true"

			:is-event="true"

			@load="loadData"

			@avg="avg = $event"
			@route_selected="route_selected = $event"
			@to_date="to_date = $event"
			@from_date="from_date = $event"
		></report-nav-bar>

		<!--Loader-->
		<self-building-square-spinner
			v-if="loading"
			class="mx-auto"
			:animation-duration="6000"
			:size="40"
			color="#ff1d5e"
		/>

		<!--Content of report-->
		<div v-else-if="daily_report" class="dashboard-page--content">

			<!--Events & Transactions-->
			<div class="dashboard-page--component">
				<div class="row">
					<div class="col-6">
						<h4 class="dashboard-page--component-title">Events & Transactions</h4>
					</div>
				</div>

				<report-generic-table
					:data="[
						{title: avg ? 'Events' : 'Events', value: num_events},
						{title: avg ? 'Gross Takings Per Event' : 'Gross Takings', value: '£' + compiled_daily_report.total_gross.toFixed(2)},
						{title: avg ? 'Transactions per Event' : 'Total Transactions', value: Math.round(compiled_daily_report.total_transactions)},
						{title: avg ? 'GP Per Event' : 'Total GP', value: '£' + compiled_daily_report.total_gross_profit.toFixed(2)},
						{title: avg ? 'Trading time' : 'Total Trading Time', value: getHumanTime(compiled_daily_report.total_time - compiled_daily_report.total_time_driving)},
						{title: avg ? 'Avg Event Fee' : 'Total Event Fees', value: '£' + compiled_daily_report.event_fees.toFixed(2)},
						{title: avg ? 'Profit Margin' : 'Profit Margin', value: Math.round(compiled_daily_report.total_gross_profit / compiled_daily_report.total_gross * 100) + '%'},
						{title: avg ? 'Wastage' : 'Wastage', value: '£' + compiled_daily_report.wastage.toFixed(2)},
						{title: avg ? 'GP minus Event Fees' : 'Total GP minus Events Fees', value: '£' + (compiled_daily_report.total_gross_profit - compiled_daily_report.event_fees).toFixed(2)},
						{title: avg ? 'GP minus Fees & Wastage' : 'Total GP minus Fees & Wastage', value: '£' + (compiled_daily_report.total_gross_profit - compiled_daily_report.event_fees - compiled_daily_report.wastage).toFixed(2)},
					]"
				></report-generic-table>

				<hr class="dashboard-page--content-spacer">
			</div>

			<!--Payments by type-->
			<div class="dashboard-page--component">
				<div class="row">
					<div class="col-12">
						<h4 class="dashboard-page--component-title">{{avg ? 'Average Payments By Type' : 'Total Payments By Type'}}</h4>
					</div>
				</div>

				<div class="row">
					<div class="col-6">
						<pie-chart
							:data="[
								{value: compiled_daily_report.cash.toFixed(2), name: 'Cash'},
								{value: compiled_daily_report.card.toFixed(2), name: 'Card'},
								// {value: compiled_daily_report.credit_accounts.toFixed(2), name: 'Credit Account'},
								// {value: compiled_daily_report.app_payments.toFixed(2), name: 'App'}
							]"
							name="Payments"
							prefix="£"
							:color="colors"
						></pie-chart>
					</div>
					<div class="col-6 d-flex align-items-center">
                        <report-payment-type-table
                            :cash="{value: (parseFloat(compiled_daily_report.cash) + parseFloat(compiled_daily_report.cash_adjustment)).toFixed(2), percentage: ((parseFloat(compiled_daily_report.cash) + parseFloat(compiled_daily_report.cash_adjustment)) / compiled_daily_report.total_gross * 100).toFixed(2)}"
                            :card="{value: (parseFloat(compiled_daily_report.card) + parseFloat(compiled_daily_report.card_adjustment)).toFixed(2), percentage: ((parseFloat(compiled_daily_report.card) + parseFloat(compiled_daily_report.card_adjustment)) / compiled_daily_report.total_gross * 100).toFixed(2)}"
                            :credit_accounts="{value: (compiled_daily_report.credit_accounts).toFixed(2), percentage: (compiled_daily_report.credit_accounts / compiled_daily_report.total_gross * 100).toFixed(2)}"
                            :app_payments="{value: (compiled_daily_report.app_payments).toFixed(2), percentage: (compiled_daily_report.app_payments / compiled_daily_report.total_gross * 100).toFixed(2)}"
                        ></report-payment-type-table>
					</div>
				</div>

				<hr class="dashboard-page--content-spacer">
			</div>

			<!--Product Stats-->
			<div class="dashboard-page--component">
				<div class="row">
					<div class="col-12">
						<h4 class="dashboard-page--component-title">{{avg ? 'Average Product Stats' : 'Total Product Stats'}}</h4>
					</div>
				</div>

				<div class="row">
					<div class="col-12 col-sm-6">
						<report-generic-table
							:data="[
								{title: this.avg ? 'Gourmet Drinks p/event' : 'Total Gourmet Drinks', value: num_hot_drinks + num_blended_drinks},
								{title: 'Hot Drinks', value: num_hot_drinks, light: true},
								{title: 'Blended Drinks', value: num_blended_drinks, light: true},
							]"
							:single-col="true"
						></report-generic-table>

						<div class="row mt-4">
							<div class="col-12">
								<h4 class="font-weight-bold text-center">Percentage of GP</h4>
							</div>
							<div class="col-12">
								<div class="row">
									<div class="col-3">
										<h5 class="font-weight-bold text-center">Gourmet Drinks</h5>
									</div>
									<div class="col-1">:</div>
									<div class="col-3">
										<h5 class="font-weight-bold text-center">Hot & Chilled Food</h5>
									</div>
									<div class="col-1">:</div>
									<div class="col-3">
										<h5 class="font-weight-bold text-center">Other</h5>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="row">
									<div class="col-3">
										<h5 class="font-weight-bold text-center">{{numberFormatWCurrencyCheck(GPGourmetDrinks + '%')}}</h5>
									</div>
									<div class="col-1">:</div>
									<div class="col-3">
										<h5 class="font-weight-bold text-center">{{numberFormatWCurrencyCheck(GPFood + '%')}}</h5>
									</div>
									<div class="col-1">:</div>
									<div class="col-3">
										<h5 class="font-weight-bold text-center">{{numberFormatWCurrencyCheck(GPOther + '%')}}</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6">
						<report-generic-table
							:data="foodItemsColumnData"
							:single-col="true"
						></report-generic-table>
					</div>
				</div>

				<hr class="dashboard-page--content-spacer">
			</div>

			<!--GP & GP Minus Event Fees Graph-->
			<div class="dashboard-page--component">
				<div class="row">
					<div class="col-12">
						<h4 class="dashboard-page--component-title">GP & GP Minus Event Fees</h4>
					</div>
				</div>

				<div class="colors mb-2 d-flex">
					<div class="ml-2">
						<div class="badge badge-pill badge-primary ml-2 d-inline-block" style="width: 16px; height: 16px; background-color:#ae1648;"></div>
						GP after Event Fees have been deducted
					</div>
					<div class="ml-2">
						<div class="badge badge-pill badge-primary ml-2 d-inline-block" style="width: 16px; height: 16px; background-color:#ff8500;"></div>
						Event Fees
					</div>
					<div class="ml-2">
						<div class="badge badge-pill badge-primary ml-2 d-inline-block half-and-half" style="width: 16px; height: 16px;">
							<span style="background-color:#ae1648;"></span>
							<span style="background-color:#ff8500;"></span>
						</div>
						Event GP
					</div>
				</div>

				<bar-chart
					:bar_data="getBarGraphData()"
					:bar_secondary_data="getFeesBarGraphData()"
					bar_secondary_name="Event Fees"
					:no_margin="true"
					bar_name="GP minus Event Fees"
					prefix="£"
					:color="colors"
				></bar-chart>

				<hr class="dashboard-page--content-spacer">
			</div>

			<!--Profit vs Target-->
<!--			<div class="dashboard-page&#45;&#45;component">-->
<!--				<div class="row">-->
<!--					<div class="col-12">-->
<!--						<h4 class="dashboard-page&#45;&#45;component-title">GP minus Event Fees Target</h4>-->
<!--					</div>-->
<!--				</div>-->

<!--				<div class="input-toggle mb-3">-->
<!--					<div class="input-toggle&#45;&#45;item" v-on:click="useTradingYear = false" v-bind:class="!useTradingYear ? 'active' : ''">-->
<!--						Calendar Year-->
<!--					</div>-->
<!--					<div class="input-toggle&#45;&#45;item" v-on:click="useTradingYear = true" v-bind:class="useTradingYear ? 'active' : ''">-->
<!--						Trading Year-->
<!--					</div>-->
<!--				</div>-->

<!--				<div class="progress-content-block d-flex justify-content-between">-->
<!--					<span>0</span>-->
<!--					<span>6</span>-->
<!--					<span>12</span>-->
<!--				</div>-->

<!--				<div class="progress position-relative" style="height:auto;">-->
<!--					<div class="progress-bar bg-primary" role="progressbar" :style="'width:' +  Math.min(100, TargetBarPercentage) + '%;height:75px;'" :aria-valuenow="Math.min(100, TargetBarPercentage)" aria-valuemin="0" aria-valuemax="100"></div>-->
<!--					<div class="current-progress bg-secondary" :style="'left:'+PercentageIntoYear+'%'"></div>-->
<!--				</div>-->

<!--				<div class="progress-content-block d-flex justify-content-between">-->
<!--					<span>£0</span>-->
<!--					<span>£{{(CurrentValue).toFixed(2)}}</span>-->
<!--					<span>£{{(TargetValue).toFixed(2)}}</span>-->
<!--				</div>-->

<!--				<div class="progress-content-block d-flex justify-content-between">-->
<!--					<span>0%</span>-->
<!--					<span>{{(TargetBarPercentage).toFixed(2)}}%</span>-->
<!--					<span>100%</span>-->
<!--				</div>-->

<!--				<hr class="dashboard-page&#45;&#45;content-spacer">-->
<!--			</div>-->
		</div>

		<!--No data to show-->
		<div v-else>
			<h2>No results found</h2>
		</div>
	</div>
</template>

<script>
	import PieChart from "../components/reporting/PieChart";
	import { SelfBuildingSquareSpinner  } from 'epic-spinners'
	import ReportTimingTable from "../components/reporting/ReportTimingTable";
	import ReportPaymentTypeTable from "../components/reporting/ReportPaymentTypeTable";
	import ReportGenericTable from "../components/reporting/ReportGenericTable";
	import ReportNavBar from "../components/reporting/ReportNavBar";
	import BarChart from "../components/reporting/BarChart";
	export default {
		name: "EventReportsPage",
		components: {
			BarChart,
			ReportNavBar,
			ReportGenericTable, ReportPaymentTypeTable, ReportTimingTable, PieChart, SelfBuildingSquareSpinner},
		props: [
			'colors',
			'routes',
			'franchise',

			'load_url',

			'date_ranges'
		],
		data(){
			return {
				loading: false,
				reload: false,

				avg: true,
				useTradingYear: false,

				today: null,

				daily_report: null,
				sales_product_group: null,
				gp_event_data: null,
				num_events: null,
				yearly_targets: null,

				from_date: null,
				to_date: null,

				route_selected: [],
			}
		},

		methods: {
			loadData(){
				let _this = this;

				if (_this.loading){
					_this.reload = true;
					return;
				}

				_this.loading = true;

				let _routes = null;
				if(_this.route_selected.length > 0){
					_routes = _this.route_selected.filter(item => item != null).map(function(item){
						return _this.routes[item].id;
					});
				}

				axios.post(_this.load_url, {
					route: _routes,
					from_date: _this.from_date,
					to_date: _this.to_date,
				})
					.then(function (response){
						_this.loading = false;

						if (_this.reload){
							_this.reload = false;
							_this.loadData();
							return;
						}

						_this.daily_report = response.data.daily_report;
						_this.num_events = response.data.num_events;
						_this.sales_product_group = response.data.sales_product_group;
						_this.gp_event_data = response.data.gp_event_data;
						_this.yearly_targets = response.data.yearly_targets;
					})
					.catch(function (e){

					});
			},


			getHumanTime(seconds){
				let date = moment.duration(Math.round(seconds) * 1000);
				return Math.round(date.asHours()) + ' h ' + (date.minutes() > 9 ? date.minutes() : '0' + date.minutes()) + ' m';
			},

			getProductGroupByName(name, use_avg = true){
				for (let i = 0; i < this.sales_product_group.length; i ++){
					if (this.sales_product_group[i].product_group === name){
						return this.avg && use_avg ?  this.sales_product_group[i]._avg : this.sales_product_group[i];
					}
				}
				return null;
			},

			getBarGraphData(){
				let data = [];
				for (let key in this.gp_event_data){
					if (this.gp_event_data.hasOwnProperty(key)){
						data.push({
							label: key,
							value: Math.round(this.gp_event_data[key] ? this.gp_event_data[key].gp_minus_fees : [])
						})
					}
				}
				return data;
			},

			getFeesBarGraphData(){
				let data = [];
				for (let key in this.gp_event_data){
					if (this.gp_event_data.hasOwnProperty(key)){
						data.push({
							label: key,
							value: Math.round(this.gp_event_data[key] ? this.gp_event_data[key].event_fees : [])
						})
					}
				}
				return data;
			}
		},

		computed: {
			compiled_daily_report(){
				return this.avg ? this.daily_report._avg : this.daily_report;
			},

			GPGourmetDrinks(){
				if (!this.daily_report.total_gross_profit) return 0;

				let gp = parseFloat(this.getProductGroupByName('Hot Drinks', false).gross_profit)
						+ parseFloat(this.getProductGroupByName('Blended Drinks', false).gross_profit);
				return (gp / this.daily_report.total_gross_profit * 100).toFixed(2);
			},
			GPFood(){
				if (!this.daily_report.total_gross_profit) return 0;

				let gp = parseFloat(this.getProductGroupByName('Chilled Food', false).gross_profit) + parseFloat(this.getProductGroupByName('Hot Food', false).gross_profit);
				return (gp / this.daily_report.total_gross_profit * 100).toFixed(2);
			},
			GPOther(){
				if (!this.daily_report.total_gross_profit) return 0;

				let gp = 0, _this = this;

				this.sales_product_group.forEach(function (group){
					if (group.product_group !== 'Hot Drinks' && group.product_group !== 'Blended Drinks' && group.product_group !== 'Chilled Food' && group.product_group !== 'Hot Food'){
						gp +=  parseFloat(group.gross_profit);
					}
				});

				return (gp / this.daily_report.total_gross_profit * 100).toFixed(2);
			},

			foodItemsColumnData(){
				let data = [{
					title: this.avg ? 'Other Items p/event' : 'Other Items',
					value: ''
				}];

				this.sales_product_group.forEach(group => {
					if (group.product_group !== 'Hot Drinks' && group.product_group !== 'Blended Drinks'){
						let val = group.number_of_sales;
						val = this.avg ? val / this.num_events : val;
						data.push({'title': group.product_group, value: val, light: true});
					}
				});

				return data;
			},

			num_hot_drinks(){
				return Math.round(this.getProductGroupByName('Hot Drinks').number_of_sales);
			},
			num_blended_drinks(){
				return Math.round(this.getProductGroupByName('Blended Drinks').number_of_sales);
			},

			num_chilled_food(){
				return Math.round(this.getProductGroupByName('Chilled Food').number_of_sales);
			},
			num_hot_food(){
				return Math.round(this.getProductGroupByName('Hot Food').number_of_sales);
			},

			TargetBarPercentage(){
				let use = this.yearly_targets.year;
				if(this.useTradingYear){
					use = this.yearly_targets.trading_year;
				}

				return ((use.reports.total_gp - use.reports.total_wastage) / use.target * 100);
			},

			TargetValue(){
				return this.useTradingYear ? this.yearly_targets.trading_year.target : this.yearly_targets.year.target;
			},

			CurrentValue(){
				return this.useTradingYear ? (this.yearly_targets.trading_year.reports.total_gp - this.yearly_targets.trading_year.reports.total_wastage) : (this.yearly_targets.year.reports.total_gp - this.yearly_targets.year.reports.total_wastage);
			},

			PercentageIntoYear(){
				return ((this.useTradingYear ? this.yearly_targets.trading_year.start : this.yearly_targets.year.start) / 365) * 100;
			}
		}
	}
</script>
