<template>
	<div class="chart-wrapper position-relative">
		<div :id="'line-chart-' + _uid" v-bind:class="no_margin ? '' : 'graph-position'" :style="{width: width ? width : '100%', height: currentHeight ? currentHeight + 'px' : '330px'}"></div>
		<button class="position-absolute btn btn-primary" @click="expand">+</button>
	</div>
</template>

<script>
	export default {
		name: "LineChart",
		props: [
			'width',
			'height',

			'title',
			'name',

			'prefix',
			'suffix',
			'hide_values',
			'no_margin',

			'colors',
			'data',
			'headers'
		],

		data(){
			return {
				chart: null,
				isExpanded: false,
				currentHeight: null,
				defaultHeight: null,
				config: {
					toolbox: {
						feature: {
							saveAsImage: {}
						}
					},
					tooltip : {
						trigger: 'axis',
						formatter: (params, ticket, callback) => {
							let response = params[0].name;

							for (var i = 0; i < params.length; i++) {
								let param = params[i];
								response += '<br/>' + param.seriesName + ': ' + this.numberFormatWCurrencyCheck(param.value, { minimumFractionDigits: 0, maximumFractionDigits: 2 });
							}

							return response;
						}
					},
					legend: {
						data:['1','2','3','4','5']
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: []
					},
					yAxis: {
						type: 'value',
					},

					series: [],
				}
			}
		},

		beforeMount(){
			if (this.hide_values){
				let self = this;
				this.config.tooltip.formatter = function(params, ticket, callback){
					return params.name + ': (' + self.numberFormatWCurrencyCheck(params.percent + '%') + ')';
				}
			}
			else if (this.suffix || this.prefix){
				let self = this;
				this.config.tooltip.formatter = function(params, ticket, callback){
					return params.name + ': ' + self.numberFormatWCurrencyCheck((self.prefix ? self.prefix : '') + params.value + (self.suffix ? self.suffix : '')) + ' (' + self.numberFormatWCurrencyCheck(params.percent + '%') + ')';
				}
			}

			if (this.title){
					this.config.title = {
					text: this.title,
					x: 'center'
				};
			}

			let color_order = [];

			this.data.forEach(function (item){
				if (item.colour != null)
					color_order.push(item.colour);
			});

			if(color_order.length !== 0) {
				this.config.color = color_order;
			}


			this.config.series = this.data;
			this.config.xAxis.data = this.headers;
		},

		mounted(){
			this.setup();
		},

		methods: {
			expand(){
				this.currentHeight = this.isExpanded ? this.defaultHeight : parseFloat(this.defaultHeight) * 2;
				this.chart.resize({height: this.currentHeight});
				this.isExpanded = !this.isExpanded;
			},
			setup(){
				this.defaultHeight = this.height == undefined ? 330 : this.height;
				this.currentHeight = this.defaultHeight;

				this.chart = echarts.init(document.getElementById('line-chart-' + this._uid));
				this.chart.setOption(this.config);
			}
		},

		watch: {
			data(){
				this.config.series = this.data;
				this.config.xAxis.data = this.headers;
				this.chart.setOption(this.config);
			},
		}
	}
</script>