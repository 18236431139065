import sorter from "./default";
import moment from "moment";
export default class extends sorter{
    checkValue(value) {
        if(typeof value != 'string'
            ||
            (!value.includes("/") && !value.includes("-"))
        ){
            return false;
        }

        return moment(value).isValid() || moment(value, 'DD-MMM-YYYY').isValid();
    }

    getValue(value) {
        value = super.getValue(value);

        if(value == null){
            return value;
        }

        if(moment(value).isValid()){
            return moment(value).unix();
        } else {
            return moment(value, 'DD-MMM-YYYY').unix();
        }
    }

    compare(a, b) {

        a = this.getValue(a);
        b = this.getValue(b);

        if(a != null && b == null){
            return -1
        } else if(b != null && a == null){
            return 1;
        } else if(a == null && b == null) {
            return 0;
        }

        if (a > b) {
            return 1;
        } else if (a < b) {
            return -1;
        }

        return 0;
    }
}