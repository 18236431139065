<template>
	<div class="position-relative" style="max-height: 370px; overflow-y: auto; overflow-x: hidden">
		<table class="table table-striped table-sm text-center" style="position: relative">
			<thead class="thead-dark position-sticky">
            	<tr v-if="totals != undefined">
					<td v-for="(total, prop) in totals">{{formatValue(prop, total)}}</td>
				</tr>
				<tr style="">
					<table-sorting-helper :sort-direction="sort_dir" :is-sorted="sort_prop == prop" v-for="(label,prop) in props" :key="prop" @sort="sort(prop)" class="position-sticky" style="top:0;">{{label}}</table-sorting-helper>
				</tr>
			</thead>
			<tbody>
				<tr v-for="record in sort_table">
					<td v-for="(value, prop) in record">
						{{formatValue(prop, value)}}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
	import Sorter from '../../../../sorter';
	import TableSortingHelper from './helpers/TableSortingHelper';

	export default {
		name: "GenericReportTable",

		components: {
			TableSortingHelper
		},

		props: [
			'data',
			'sort_by',
			'sort_direction',

			'preformatted',
			'headers',
			'formatters',

			'totals'
		],

		data(){
			return {
				sort_dir: 'asc',
				sort_prop: '',
				sorter: ''
			}
		},

		beforeMount(){
			if (!this.sort_by){
				this.sort_prop = Object.keys(this.props)[0];
			}
			else {
				this.sort_prop = this.sort_by;
				this.sort_dir = this.sort_direction || 'asc';
			}

			this.sorter = new Sorter([],this.sort_prop,this.sort_dir);
		},

		methods:{
			sort(prop) {
				//if already select reverse direction
				if(prop === this.sort_prop)
					this.sort_dir = this.sort_dir === 'asc'
						? 'desc'
						: 'asc';
				else
					//defaults to asc
					this.sort_dir = 'asc';

				this.sort_prop = prop;
				this.$emit('sort', {sort_by: prop, sort_direction: this.sort_dir});
			},

			prefix(prop){
				let _prefix = '';
				this.data.forEach(function (col){
					if (col.prop === prop){
						_prefix = col.prefix || '';
					}
				});
				return _prefix;
			},

			formatValue(prop, value){
				if(this.formatters != undefined && this.formatters[prop] != undefined) {
					let formatter = this.formatters[prop];
					return this.format(value, formatter + '');
				}
				return value !== null ? this.numberFormatWCurrencyCheck(this.prefix(value) + value) : '--'
			}
		},

		computed: {
			sort_table(){
				let
					_this = this,
					modifier = _this.sort_dir === 'asc' ? 1 : -1,
					pre_data = {},
					rows = [];

				if(this.preformatted){
					rows = [...this.data]; //clone data
				} else {
					this.data.forEach(function(col){
						col.values.forEach(function (row){
							if (!pre_data[col.prop]) pre_data[col.prop] = [];
							pre_data[col.prop].push(row)
						});
					});

					for (let prop in pre_data){
						if (pre_data.hasOwnProperty(prop)){
							let i = 0;
							pre_data[prop].forEach(function (row){
								if (!rows[i])
									rows[i] = {};

								rows[i++][prop] = row;
							});
						}
					}
				}

				return this.sorter.setValues(rows).setDirection(_this.sort_dir).setKey(_this.sort_prop).sort();

				// return rows.sort(function (a, b) {
				// 	let
				// 		prop_a = a[_this.sort_prop],
				// 		prop_b = b[_this.sort_prop];
				//
				// 	if (!isNaN(prop_a)){
				// 		prop_a = parseFloat(a[_this.sort_prop]);
				// 		prop_b = parseFloat(b[_this.sort_prop]);
				// 	}
				//
				// 	if (prop_a < prop_b) return -1 * modifier;
				// 	if (prop_a > prop_b) return modifier;
				//
				// 	if (a[_this.sort_prop] === null){
				// 		if (b[_this.sort_prop] === null)
				// 			return modifier;
				// 		return -1 * modifier;
				// 	}
				//
				// 	return 0;
				// });
			},

			props(){
				if(this.preformatted && this.headers != undefined){
					return this.headers;
				}
				let props = {};
				this.data.forEach(function (col){
					props[col.prop] = col.label;
				});

				return props;
			}
		}
	}
</script>