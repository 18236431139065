<template>
    <div>
        <div class="row">
            <div class="col-9">
                <table class="table table-striped">
                    <thead>
                        <th>Franchise Name</th>
                        <th @click="sortBy('items_sold')">
                            Total Sales
                            <a v-show="sortByValue == 'items_sold' && desc == true"><i class="fas fa-caret-up"></i></a>
                            <a v-show="sortByValue == 'items_sold' && desc == false"><i class="fas fa-caret-down"></i></a>
                            <a v-show="sortByValue != 'items_sold'"><i class="fas fa-sort"></i></a>
                        </th>
                        <th @click="sortBy('gross_profit')">
                            Total Gross Profit
                            <a v-show="sortByValue == 'gross_profit' && desc == true"><i class="fas fa-caret-up"></i></a>
                            <a v-show="sortByValue == 'gross_profit' && desc == false"><i class="fas fa-caret-down"></i></a>
                            <a v-show="sortByValue != 'gross_profit'"><i class="fas fa-sort"></i></a>
                        </th>
                        <th @click="sortBy('hours_worked')">
                            Total Hours Worked
                            <a v-show="sortByValue == 'hours_worked' && desc == true"><i class="fas fa-caret-up"></i></a>
                            <a v-show="sortByValue == 'hours_worked' && desc == false"><i class="fas fa-caret-down"></i></a>
                            <a v-show="sortByValue != 'hours_worked'"><i class="fas fa-sort"></i></a>
                        </th>
                        <th @click="sortBy('profit_per_hour')">
                            Profit Per Hour
                            <a v-show="sortByValue == 'profit_per_hour' && desc == true"><i class="fas fa-caret-up"></i></a>
                            <a v-show="sortByValue == 'profit_per_hour' && desc == false"><i class="fas fa-caret-down"></i></a>
                            <a v-show="sortByValue != 'profit_per_hour'"><i class="fas fa-sort"></i></a>
                        </th>
                        <th @click="sortBy('days_worked')">
                            Number of Days Worked
                            <a v-show="sortByValue == 'days_worked' && desc == true"><i class="fas fa-caret-up"></i></a>
                            <a v-show="sortByValue == 'days_worked' && desc == false"><i class="fas fa-caret-down"></i></a>
                            <a v-show="sortByValue != 'days_worked'"><i class="fas fa-sort"></i></a>
                        </th>
                        <th>Total Profit From Events</th>
                    </thead>
                    <tbody>
                        <tr v-for="franchise in franchise_data">
                            <td>{{ franchise.name }}</td>
                            <td>{{ franchise.items_sold }}</td>
                            <td>{{ franchise.gross_profit }}</td>
                            <td>{{ franchise.hours_worked }}</td>
                            <td>{{ franchise.profit_per_hour }}</td>
                            <td>{{ franchise.days_worked }}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-3">
                <input class="form-control w-100 rounded-top-only" type="search" placeholder="Select Date:" aria-label="Select Date:" id="input_date_franchise_leaderboard" v-model="selectedDate">
                <select class="form-control w-100 rounded-bottom-only" multiple v-model="selected_franchises">
                    <option v-for="franchise in franchises" :value="franchise.id">{{ franchise.user.name }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                franchises: [],
                selectedDate: null,
                sortByValue: null,
                desc: true,
                selected_franchises: [],
                franchise_data: [],
            };
        },
        created() {
            axios.get('/super-admin/franchise/leaderboard/get').then(response => {
                this.franchise_data = response.data;
            });
            axios.get('/api/analytics/franchise/all').then(response => {
                this.franchises = response.data;
            });
        },
        watch: {
            selectedDate: function(val){
                if(val.indexOf("to") != -1){
                    this.selectedDate = val;
                    axios.get('/super-admin/franchise/leaderboard/get', {
                        params: {
                            date_analytics: this.selectedDate,
                            sortby: this.sortByValue,
                            desc: this.desc,
                            selected_franchises: this.selected_franchises
                        }
                    }).then(response => {
                        this.franchise_data = response.data;
                    });
                }
            },
            selected_franchises: function(val){
                axios.get('/super-admin/franchise/leaderboard/get', {
                    params: {
                        date_analytics: this.selectedDate,
                        sortby: this.sortByValue,
                        desc: this.desc,
                        selected_franchises: this.selected_franchises
                    }
                }).then(response => {
                    console.log(response.data);
                    this.franchise_data = response.data;
                });
            }
        },
        methods: {
            sortBy(val){
                if(this.sortByValue == val){
                    this.desc = !this.desc;
                } else {
                    this.desc = true;
                }

                this.sortByValue = val;
                axios.get('/super-admin/franchise/leaderboard/get', {
                    params: {
                        date_analytics: this.selectedDate,
                        sortby: val,
                        desc: this.desc,
                        selected_franchises: this.selected_franchises
                    }
                }).then(response => {
                    this.franchise_data = response.data;
                });
            }
        }
    }
</script>
