<template>
	<div :id="'pie-chart-' + _uid" v-bind:class="no_margin ? '' : 'graph-position'" :style="{width: width ? width : '100%', height: height ? height + 'px' : '330px'}"></div>
</template>

<script>
	export default {
		name: "PieChart",
		props: [
			'width',
			'height',

			'title',
			'name',

			'prefix',
			'suffix',
			'hide_values',
			'no_margin',

			'color',
			'data'
		],

		data(){
			return {
				pie: null,
				config: {
					tooltip : {
						trigger: 'item',
						formatter: (params, ticket, callback) => {
							return params.name + ': ' + this.numberFormatWCurrencyCheck((this.prefix ? this.prefix : '') + params.value + (this.suffix ? this.suffix : '')) + ' (' + this.numberFormatWCurrencyCheck(params.percent + '%') + ')';
						}
					},

					series: [
						{
							name: '',
							type: 'pie',
							radius : '55%',
							center: ['50%', '60%'],
							data:[

							],

							itemStyle: {
								emphasis: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowColor: 'rgba(0, 0, 0, 0.5)'
								}
							},
							color: []
						}
					],
				}
			}
		},

		beforeMount(){
			if (this.hide_values){
				let self = this;
				this.config.tooltip.formatter = function(params, ticket, callback){
					return params.name + ': (' + self.numberFormatWCurrencyCheck(params.percent + '%') + ')';
				}
			}
			else if (this.suffix || this.prefix){
				let self = this;
				this.config.tooltip.formatter = function(params, ticket, callback){
					return params.name + ': ' + self.numberFormatWCurrencyCheck((self.prefix ? self.prefix : '') + params.value + (self.suffix ? self.suffix : '')) + ' (' + self.numberFormatWCurrencyCheck(params.percent + '%') + ')';
				}
			}

			if (this.title){
				this.config.title = {
					text: this.title,
					x: 'center'
				};
			}

			this.config.series[0].name = this.name || '';
			this.config.series[0].data = this.data || [];
			this.config.series[0].color = this.color || ['#ae1648'];
		},

		mounted(){
			this.setup();
		},

		methods: {
			setup(){
				this.pie = echarts.init(document.getElementById('pie-chart-' + this._uid));
				this.pie.setOption(this.config);
			}
		},

		watch: {
			data(){
				this.config.series[0].name = this.name || '';
				this.config.series[0].data = this.data || [];
				this.config.series[0].color = this.color || ['#ae1648'];
				this.pie.setOption(this.config);
			}
		}
	}
</script>