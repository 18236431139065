<template>
	<div class="container mx-0">
		<div class="row">
			<div class="col-7 my-auto">
				<div class="container px-0 mt-4">

					<div class="row">
						<div class="col-5">
							<h5 class="font-weight-bold">New App customers</h5>
						</div>
						<div class="col-4 text-right">
							<h5 class="font-weight-bold">{{numberFormatWCurrencyCheck(data.new_app_customers)}}</h5>
						</div>
					</div>

					<div class="row">
						<div class="col-5">
							<h5 class="font-weight-bold">New from 'new' sites</h5>
						</div>
						<div class="col-4 text-right">
<!--							<h5 class="font-weight-bold">{{numberFormatWCurrencyCheck(data.new_app_customers_new_sites)}}</h5>-->
							<h5 class="font-weight-bold">Calculation to follow</h5>
						</div>
					</div>

					<div class="row">
						<div class="col-5">
							<h5 class="font-weight-bold">New from 'established' sites</h5>
						</div>
						<div class="col-4 text-right">
<!--							<h5 class="font-weight-bold">{{numberFormatWCurrencyCheck(data.new_app_customers_old_sites)}}</h5>-->
							<h5 class="font-weight-bold">Calculation to follow</h5>
						</div>
					</div>

					<div class="row mt-3">
						<div class="col-5">
							<h5 class="font-weight-bold">Reactivated customers</h5>
						</div>
						<div class="col-4 text-right">
<!--							<h5 class="font-weight-bold">{{numberFormatWCurrencyCheck(data.reactivated_users)}}</h5>-->
							<h5 class="font-weight-bold">Calculation to follow</h5>
						</div>
					</div>

					<div class="row">
						<div class="col-5">
							<h5 class="font-weight-bold">New inactive customers</h5>
						</div>
						<div class="col-4 text-right">
<!--							<h5 class="font-weight-bold">{{numberFormatWCurrencyCheck(data.new_inactive_users)}}</h5>-->
							<h5 class="font-weight-bold">Calculation to follow</h5>
						</div>
					</div>

				</div>
			</div>
			<div class="col-5">

                <div class="row">
					<div class="col-5">
						<h5 class="font-weight-bold text-center">Using App</h5>
					</div>
					<div class="col-1 text-center">:</div>
					<div class="col-5">
						<h5 class="font-weight-bold text-center">Non-App</h5>
					</div>

					<div class="col-5">
						<h5 class="font-weight-bold text-center">{{app_users_percentage}}</h5>
					</div>
					<div class="col-1 text-center">:</div>
					<div class="col-5">
						<h5 class="font-weight-bold text-center">{{non_app_users_percentage}}</h5>
					</div>
				</div>

				<pie-chart
					:data="[
						{value: data.app_users, name: 'Using App'},
						{value: data.non_app_users, name: 'Non-App'},
					]"

					:no_margin="true"
					:width="200"
					:color="colors"
				></pie-chart>
			</div>
		</div>
	</div>
</template>

<script>
	import PieChart from "./PieChart";
	export default {
		name: "CustomerDateRangeAnalysis",
		components: {PieChart},
		props: [
			'data',
			'colors'
		],
		computed: {
			app_users_percentage(){
				return this.numberFormatWCurrencyCheck(
						(
								(this.data.app_users /
										(parseFloat(this.data.app_users) + parseFloat(this.data.non_app_users))
								) * 100) + '%');
			},
			non_app_users_percentage(){
				return this.numberFormatWCurrencyCheck(
						(
								(this.data.non_app_users /
										(parseFloat(this.data.app_users) + parseFloat(this.data.non_app_users))
								) * 100) + '%');
			}
		}
	}
</script>