export default function(value){
    return isNaN(value)
        ? value
        : (
            "£" +
            Intl.NumberFormat("en-GB", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(value)
        );
}