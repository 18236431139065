<template>
	<div class="container px-0 mt-4">

		<div class="row">
			<div :class="{'col-6': singleCol == undefined || (singleCol != undefined && !singleCol), 'col-12': singleCol != undefined && singleCol}" class="px-0" v-for="item in data">
				<div v-if="item.multiple" class="d-flex w-50">
					<div v-for="sub_item in item.data" class="d-flex w-50" :style="sub_item.colour != undefined ? 'color: ' + sub_item.colour: ''">
						<div class="w-50" style="padding-left: 15px; padding-right: 15px">
							{{sub_item.title}}
						</div>
						<div class="w-50" style="padding-left: 15px; padding-right: 15px">
							{{numberFormatWCurrencyCheck(sub_item.value)}}
						</div>
					</div>
				</div>
				<div v-else class="d-flex" :style="item.colour != undefined ? 'color: ' + item.colour: ''">
					<div class="w-50" style="padding-left: 15px; padding-right: 15px">
						<h5 :class="{'font-weight-bold': (item.light == undefined) || (item.light != undefined && !item.light)}">{{item.title}}</h5>
					</div>
					<div class="w-50" style="padding-left: 15px; padding-right: 15px">
						<span class="dashboard-page--component-stats">{{item.ignore_formatter ? item.value : numberFormatWCurrencyCheck(item.value)}}</span>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: "ReportGenericTable",
		props: [
			'data',
			'singleCol'
		]
	}
</script>