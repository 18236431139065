<template>
    <div class="row row-eq-height rounded">
        <div class="col-12">
            <select class="form-control float-right" v-model="week_period">
                <option class="select_route_multiple" value="4">4 Weeks</option>
                <option class="select_route_multiple"  value="12">12 Weeks</option>
            </select>
        </div>
        <div class="card-deck m-2 p-0">
            <div class="card m-2" v-for="chart in charts" style="background-color:#eee">
                <div class="align-middle rounded" style="position:absolute;top:0;left:0;width:100%;" v-if="chart.is_loading">
                    <self-building-square-spinner class="mx-auto"
                      :animation-duration="6000"
                      :size="40"
                      color="#ff1d5e"
                    />
                </div>
                <div class="card-body">
                    <h5 class="card-title text-capitalize">{{ chart.name }}</h5>
                    <div class="card-text" v-if="chart.secondary_data == null">
                        <h1 style="color:#a02949;">{{ chart.data }}</h1>
                    </div>
                    <div class="card-text" v-else>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <h4 class="m-0 border-right border-secondary" style="color:#a02949;">{{ chart.data }}</h4>
                            </div>
                            <div class="col-12 col-md-6">
                                <h4 style="color:#a02949;">{{ chart.secondary_data }}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { SelfBuildingSquareSpinner  } from 'epic-spinners'
    export default {
        mounted(){
            this.charts = this.chart_data;
            this.updateData();
        },
        components: { SelfBuildingSquareSpinner },
        props: {
                    'chart_data': Array
                },
        data() {
            return {
                charts: null,
                week_period: 12
            };
        },
        methods: {
            updateData(){
                this.charts.forEach((chart, index) => {
                    chart.is_loading = true;
                    axios.get(chart.url, {
                        params: {
                            week_period: this.week_period
                        }
                    }).then(response => {
                        if(response.data != null && typeof(response.data) === 'object'){
                            chart.data = response.data.data;
                            chart.secondary_data = response.data.secondary_data;
                        } else {
                            chart.data = response.data;
                        }
                        chart.is_loading = false
                    });
                });
            }
        },
        watch: {
            week_period: function(value){
                this.updateData();
            }
        }
    }
</script>
