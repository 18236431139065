import sorter from "./default";
export default class extends sorter{
    checkValue(value) {
        return !isNaN(this.getValue(value));
    }

    getValue(value) {
        value = super.getValue(value);

        if(value == null){
            return value;
        }

        var valueArray = value.split(' ');

        let multipliers = {
            'h': 3600,
            'm': 60,
            's': 1
        };

        value = 0;
        valueArray.forEach(function (item, index) {
            value += (item.substr(0, item.length - 1) * multipliers[item.charAt(item.length - 1)]);
        });

        return value;
    }

    compare(a, b) {
        a = this.getValue(a);
        b = this.getValue(b);

        if(a == null && b != null){
            return -1;
        } else if (a != null && b == null){
            return 1;
        }

        if (a > b) {
            return 1;
        } else if (a < b) {
            return -1;
        }

        return 0;
    }
}