<template>
    <select :class="{'select2-hidden-accessible': initialised}">
        <option v-if="options !== undefined && options.ajax !== undefined && options.multiple !== undefined && !options.multiple"></option>

        <option v-if="options !== undefined && options.multiple" v-for="item in startingValue" :name="item" :key="`select-${item}`" :selected="true">{{item}}</option>

        <slot /> <!-- Doing it this way means the options cannot change. But less props (data doesn't change on this template) -->
    </select>
</template>

<script>
    export default {
        name: "Select2",

        props: {
            options: {
                default: () => {}
            },

            value: {
                default: null
            },

            hasError: {
                type: Boolean,
                default: false
            }
        },

        data(){
            return {
                initialised: false,

                emit: true,

                startingValue: []
            }
        },

        mounted(){
            if(!this.initialised){
                this.startingValue = this.value;

                $(this.$el)
                    .val(this.value)
                    .select2(this.options)
                    .change((e) => {
                        if(this.emit){
                            this.$emit('input-object', $(this.$el).select2('data'));
                            this.$emit('input', $(this.$el).val());
                        }

                        this.emit = true;
                    });

                this.initialised = true;
            }
        },

        watch: {
            value: function() {
                this.emit = false;

                $(this.$el)
                    .val(this.value)
                    .trigger('change');
            },

            hasError: function(value){
                if(this.initialised){
                    $(this.$el).data('select2').$selection.toggleClass('is-invalid', value);
                }
            }
        },

        beforeDestroy(){
            if(this.initialised){
                $(this.$el).select2('destroy');
            }
        }
    }
</script>
