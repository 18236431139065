import sorter from "./default";
export default class extends sorter{
    checkValue(value) {
        return !isNaN(value);
    }

    getValue(value) {
        value = super.getValue(value);
        return value != null ? parseFloat(value.toString().replace(',', '')) : value;
    }

    compare(a, b) {
        a = this.getValue(a);
        b = this.getValue(b);

        if(a == null && b != null){
            return -1;
        } else if (a != null && b == null){
            return 1;
        }

        if (a > b) {
            return 1;
        } else if (a < b) {
            return -1;
        }

        return 0;
    }
}