<template>
	<div class="dashboard-page">

		<!--Top Filters Header-->
		<report-nav-bar
			:routes="routes"
			:date_ranges="date_ranges"

			:default_range="0"
			:hide_today="true"
			:hide_avg="true"

			:default_route_selected="[]"

			@load="loadData"

			@route_selected="route_selected = $event"
			@to_date="to_date = $event"
			@from_date="from_date = $event"
		></report-nav-bar>

		<!--Loader-->
		<self-building-square-spinner
			v-if="loading"
			class="mx-auto"
			:animation-duration="6000"
			:size="40"
			color="#ff1d5e"
		/>

		<!--Content of report-->
		<div v-else-if="data" class="dashboard-page--content">
			<div class="dashboard-page--component">
				<div class="row pt-4">
					<div class="col-12 text-left">
						<h4 class="dashboard-page--component-title">Snapshot <span> - {{snapshotText}}</span></h4>
					</div>
					<div class="col-12">
						<report-generic-table
							:data="[
								{title: 'Active App Customers', value: data.customer_stats_snapshot.active_app_users},
								{title: 'Estimated Active Non-App Customers', value: data.customer_stats_snapshot.estimated_active},
								{title: 'Inactive App Customers', value: data.customer_stats_snapshot.inactive_app_users},
								{title: 'Estimated Total Active Customers', value: data.customer_stats_snapshot.total_estimated}
							]"
						></report-generic-table>
					</div>
				</div>
			</div>

			<div class="dashboard-page--component">
				<div class="row pt-4">
					<div class="col-12 text-left">
						<h4 class="dashboard-page--component-title">Customer Buying Habits</h4>
					</div>
					<div class="col-12">
						<customer-buying-habits
							:data="data.buys_per_week"
							:colors="colors"
						></customer-buying-habits>
					</div>
				</div>
			</div>

			<div class="dashboard-page--component">
				<div class="row pt-4">
					<div class="col-12 text-left">
						<h4 class="dashboard-page--component-title">Date Range Analysis</h4>
					</div>
					<div class="col-12">
						<customer-date-range-analysis
							:data="data.date_range_analysis"
							:colors="colors"
						></customer-date-range-analysis>
					</div>
				</div>
			</div>

			<div class="dashboard-page--component">
				<div class="row pt-4">
					<div class="col-12 text-left">
						<h4 class="dashboard-page--component-title">Pre-Orders</h4>
					</div>
					<div class="col-12">
						<pre-orders-table :data="[
							{name: 'Trans. ordered via App', primary_data: 'N/A', secondary_data: 'N/A'},
							{name: 'Average App Order gross', primary_data: 'N/A', secondary_data: ''},
							{name: 'App orders cancelled by customer', primary_data: 'N/A', secondary_data: 'N/A'},
							{name: 'Average App Order GP', primary_data: 'N/A', secondary_data: ''},
							{name: 'Orders left at collection point', primary_data: 'N/A', secondary_data: 'N/A'},
					    ]"></pre-orders-table>
					</div>
				</div>
			</div>

			<div class="dashboard-page--component">
				<div class="row pt-4">
					<div class="col-12 text-left">
						<h4 class="dashboard-page--component-title">Active Customers Graph</h4>
					</div>
					<div class="col-12 pt-4">
						<div class="colors mb-5 d-flex">
							<div v-for="colour in [ data.active_customers_data.active_app_users, data.active_customers_data.estimated_total_users ]" class="ml-2">
								<div class="badge badge-pill badge-primary ml-2 d-inline-block" style="width: 16px; height: 16px" :style="{'background-color': Object.keys(colour).length > 0 ? colour.colour : null}"></div>
								{{Object.keys(colour).length > 0 ? colour.name : null}}
							</div>
						</div>
						<line-chart
								:data="[
										data.active_customers_data.active_app_users,
										data.active_customers_data.estimated_total_users
								]"
								:headers="data.active_customers_data.headers"
						></line-chart>
					</div>
				</div>
			</div>
		</div>

		<!--No data to show-->
		<div v-else>
			<h2>No results found</h2>
		</div>
	</div>
</template>

<script>
	import PieChart from "../components/reporting/PieChart";
	import LineChart from "../components/reporting/LineChart";
	import { SelfBuildingSquareSpinner  } from 'epic-spinners'
	import ReportTimingTable from "../components/reporting/ReportTimingTable";
	import ReportPaymentTypeTable from "../components/reporting/ReportPaymentTypeTable";
	import ReportGenericTable from "../components/reporting/ReportGenericTable";
	import BarChart from "../components/reporting/BarChart";
	import ReportNavBar from "../components/reporting/ReportNavBar";
	import SiteSaleStatsTable from "../components/reporting/SiteSaleStatsTable";
	import CustomerBuyingHabits from "../components/reporting/CustomerBuyingHabits";
	import CustomerDateRangeAnalysis from "../components/reporting/CustomerDateRangeAnalysis";
	import PreOrdersTable from "../components/reporting/PreOrdersTable";
	export default {
		name: "CustomerStats",
		components: {
			PreOrdersTable,
			CustomerDateRangeAnalysis,
			CustomerBuyingHabits,
			SiteSaleStatsTable,
			ReportNavBar,
			BarChart,
			ReportGenericTable, ReportPaymentTypeTable, ReportTimingTable, PieChart, LineChart, SelfBuildingSquareSpinner},
		props: [
			'colors',
			'routes',
			'franchise',

			'load_url',

			'default_from_date',
			'default_to_date',

			'date_ranges',
		],
		data(){
			return {
				loading: false,
				reload: false,

				active_status: 'all',

				data: null,

				route_selected: [],
				from_date: null,
				to_date: null,
			}
		},

		methods: {
			loadData(){
				let _this = this;

				if (_this.loading){
					_this.reload = true;
					return;
				}

				_this.loading = true;

				let _routes = null;
				if(_this.route_selected.length > 0){
					_routes = _this.route_selected.filter(item => item != null).map(function(item){
						return _this.routes[item].id;
					});
				}

				axios.post(_this.load_url, {
					route: _routes,
					from_date: _this.from_date,
					to_date: _this.to_date,
				})
					.then(function (response){
						_this.loading = false;

						if (_this.reload){
							_this.reload = false;
							_this.loadData();
							return;
						}

						_this.data = response.data;
					})
					.catch(function (e){

					});
			},
		},

		computed: {
			snapshotText(){
				let date = moment(this.to_date, 'YYYY-MM-DD');

				if(date.isSame(moment(), 'day')){
					return 'Current';
				}

				return `on: ${date.format('DD/MM/YYYY')}`;
			},

			activeCustomersGraphData(){
				return [];
				// let data = {};
				//
				// for (let key in this.active_customers_data){
				// 	if (this.daily_report.hasOwnProperty(key)){
				// 		if(data['active_app_users'] == undefined){
				// 			data['active_app_users'] = {
				// 				name: 'Active App Users',
				// 				type: 'line',
				// 				colour: '#000000'
				// 			};
				// 		}
				// 	}
				// }
				//
				// return data;
			}
		}
	}
</script>