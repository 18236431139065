<template>
	<div class="dashboard-page">

		<!--Top Filters Header-->
		<report-nav-bar
			:routes="routes"
			:date_ranges="date_ranges"

			:default_range="0"
			:hide_today="true"
			:hide_avg="true"

			@load="loadData"

			@active_status="active_status = $event"
			@route_selected="route_selected = $event"
			@to_date="to_date = $event"
			@from_date="from_date = $event"
		></report-nav-bar>

		<!--Loader-->
		<self-building-square-spinner
			v-if="loading"
			class="mx-auto"
			:animation-duration="6000"
			:size="40"
			color="#ff1d5e"
		/>

		<!--Content of report-->
		<div v-else-if="multi_van_stats" class="dashboard-page--content">


			<!--Averages-->
			<div class="dashboard-page--component">
				<div class="row">
					<div class="col-12">
						<h4 class="dashboard-page--component-title">Averages</h4>
					</div>
				</div>

				<generic-report-table
						:preformatted="true"
						:data="average_stats"
						:headers="labels['avg']"
						:totals="avgTotalStats"
						:formatters="formatKeys"
				></generic-report-table>

				<hr class="dashboard-page--content-spacer">
			</div>

			<!--Totals-->
			<div class="dashboard-page--component">
				<div class="row">
					<div class="col-12">
						<h4 class="dashboard-page--component-title">Totals</h4>
					</div>
				</div>

				<generic-report-table
						:preformatted="true"
						:data="stats"
						:headers="labels['totals']"
						:totals="totalStats"
						:formatters="formatKeys"
				></generic-report-table>

				<hr class="dashboard-page--content-spacer">
			</div>

			<!--Gp Pie Chart-->
			<div class="dashboard-page--component">
				<div class="row">
					<div class="col-6">
						<h4 class="dashboard-page--component-title">GP Chart</h4>
					</div>
				</div>

				<div class="row">
					<div class="col-12">
						<pie-chart
								:data="pieData"
								name="GP Chart"
								:hide_values="false"
								:color="colors"
						></pie-chart>
					</div>
				</div>

				<hr class="dashboard-page--content-spacer">
			</div>
		</div>

		<!--No data to show-->
		<div v-else>
			<h2>No results found</h2>
		</div>
	</div>
</template>

<script>
	import { SelfBuildingSquareSpinner  } from 'epic-spinners'
	import ReportGenericTable from "../components/reporting/ReportGenericTable";
	import ReportNavBar from "../components/reporting/ReportNavBar";
	import GenericReportTable from "../components/reporting/GenericReportTable";
	import PieChart from "../components/reporting/PieChart";

	export default {
		name: "MultiVanPage",
		components: {
			GenericReportTable,
			ReportNavBar,
			ReportGenericTable,
			SelfBuildingSquareSpinner,
			PieChart
		},
		props: [
			'colors',
			'routes',
			'franchise',

			'load_url',

			'default_from_date',
			'default_to_date',

			'date_ranges',

			'default_selected_routes'
		],
		data(){
			return {
				loading: false,
				reload: false,

				route_selected: [],
				from_date: null,
				to_date: null,

				multi_van_stats: null,
				multi_van_totals: null,

				formatKeys: {
					'trading_time': 'time',
					'serving_time': 'time',
					'gross_sales': 'currency',
					'net_sales': 'currency',
					'gp': 'currency',
					'serving_percentage': 'percentage',
					'percent_margin': 'percentage',
				},

				labels: {
					'avg': {
						'route_name': 'Route',
						'total_instances': 'Times Completed',
						'trading_time': 'Average Trading Time',
						'serving_time': 'Average Serving Time',
						'serving_percentage': 'Average Serving Time %',
						'percent_margin': 'Average Margin %',
						'gross_sales': 'Average Gross Sales p/day',
						'net_sales': 'Average Net Sales p/day',
						'gp': 'Average GP p/day'
					},
					'totals': {
						'route_name': 'Route',
						'total_instances': 'Times Completed',
						'trading_time': 'Total Trading Time',
						'serving_time': 'Total Serving Time',
						'serving_percentage': 'Average Serving Time %',
						'percent_margin': 'Average Margin %',
						'gross_sales': 'Total Gross Sales',
						'net_sales': 'Total Net Sales',
						'gp': 'Total GP'
					},
				}
			}
		},

		methods: {
			loadData(){
				let _this = this;

				if (_this.loading){
					_this.reload = true;
					return;
				}

				_this.loading = true;

				let _routes = null;
				if(_this.route_selected.length > 0){
					_routes = _this.route_selected.filter(item => item != null).map(function(item){
						return _this.routes[item].id;
					});
				}

				axios.post(_this.load_url, {
					route: _routes,
					from_date: _this.from_date,
					to_date: _this.to_date,
				})
					.then(function (response){
						_this.loading = false;

						if (_this.reload){
							_this.reload = false;
							_this.loadData();
							return;
						}

						console.log(response);
						_this.multi_van_stats = response.data.routes;
						_this.multi_van_totals = response.data.totals;
					})
					.catch(function (e){

					});
			},
		},

		computed: {
            stats(){
				return this.multi_van_stats.map(function(item){
					let newItem = {...item};
					delete newItem._avg;
					return newItem;
				});
			},
			average_stats(){
				return this.multi_van_stats.map(function(item){
					return item._avg;
				});
			},
			totalStats(){
				let tmpData = {...this.multi_van_totals};
				delete tmpData._avg;
				return tmpData;
			},
			avgTotalStats(){
				return this.multi_van_totals._avg;
			},
			pieData(){
            	return this.multi_van_stats.map(function(item){
					return {
						name: item.route_name,
						value: item.gp
					}
				});
			}
		}
	}
</script>