<template>
    <textarea :name="name" :id="name" :placeholder="placeholder" :value="value" />
</template>

<script>
    export default {
        name: "Redactor",

        props: {
            value: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: null
            },
            name: {
                type: String,
                default: null
            },
            config: {
                type: Object,
                default: {}
            }
        },

        data(){
            return {
                instance: false
            }
        },

        mounted() {
            Vue.set(this.config, 'callbacks', {
                changed: (html) => {
                    this.handleInput(html);
                    return html;
                }
            });

            this.instance = $R(this.$el, this.config);
        },
        beforeDestroy() {
            $R(this.$el, 'destroy');
            this.instance = false;
        },
        methods: {
            handleInput (val) {
                this.$emit('input', val);
            }
        }
    }
</script>