<template>
	<div class="dashboard-page">

		<!--Top Filters Header-->
		<report-nav-bar
			:routes="routes"
			:default_route_selected="[]"
			:date_ranges="date_ranges"

			:default_range="0"
			:hide_today="true"
			:hide_avg="true"
			:show_active_product_status="true"

			@active_product_status="active_product_status = $event"
			@route_selected="route_selected = $event"
			@to_date="to_date = $event"
			@from_date="from_date = $event"
		></report-nav-bar>

		<!--Content of report-->
		<div class="dashboard-page--content">
			<div class="dashboard-page--component">
				<div class="row">
					<div class="col-12">
						<h4 class="dashboard-page--component-title">Overview</h4>
					</div>
				</div>

				<div class="row">
					<div class="col-12">
						<product-stats-overview-table
							:colors="colors"
							:load_url="load_url"
							:routes="routes"

							:active_product_status="active_product_status"
							:route_selected="route_selected"
							:to_date="to_date"
							:from_date="from_date"
						></product-stats-overview-table>
					</div>
				</div>
			</div>

			<div class="dashboard-page--component mt-4">
				<div class="row">
					<div class="col-12">
						<sales-by-product-group
							:load_url="load_url + '/sales_by_product_group'"
							:routes="routes"

							:active_product_status="active_product_status"
							:route_selected="route_selected"
							:to_date="to_date"
							:from_date="from_date"
						></sales-by-product-group>
					</div>
				</div>
			</div>


			<div class="dashboard-page--component mt-4">
				<div class="row">
					<div class="col-12">
						<h4 class="dashboard-page--component-title">Sales by Product</h4>
					</div>
				</div>

				<div class="row">
					<div class="col-12">
						<sales-by-product-table-with-filter
							:load_url="load_url + '/sales_by_product_filter'"
							:routes="routes"

							:product_groups="product_groups"

							:active_product_status="active_product_status"
							:route_selected="route_selected"
							:to_date="to_date"
							:from_date="from_date"

							sort_by="gross_profit"
							sort_direction="desc"

							:formatters="{
								'gross_profit': 'currency'
							}"
						></sales-by-product-table-with-filter>
					</div>
				</div>
			</div>


			<div class="dashboard-page--component mt-4">
				<div class="row">
					<div class="col-12">
						<h4 class="dashboard-page--component-title">Wastage by Product</h4>
					</div>
				</div>

				<div class="row">
					<div class="col-12">
						<sales-by-product-table-with-filter
							:load_url="load_url + '/wastage_by_product_filter'"
							:routes="routes"

							:product_groups="product_groups"

							:active_product_status="active_product_status"
							:route_selected="route_selected"
							:to_date="to_date"
							:from_date="from_date"

							sort_by="wastage"
							sort_direction="desc"

							:formatters="{
								'wastage': 'currency'
							}"
						></sales-by-product-table-with-filter>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PieChart from "../components/reporting/PieChart";
	import { SelfBuildingSquareSpinner  } from 'epic-spinners'
	import ReportTimingTable from "../components/reporting/ReportTimingTable";
	import ReportPaymentTypeTable from "../components/reporting/ReportPaymentTypeTable";
	import ReportGenericTable from "../components/reporting/ReportGenericTable";
	import BarChart from "../components/reporting/BarChart";
	import ReportNavBar from "../components/reporting/ReportNavBar";
	import SiteSaleStatsTable from "../components/reporting/SiteSaleStatsTable";
	import ProductStatsOverviewTable from "../components/reporting/ProductStatsOverviewTable";
	import SalesByProductGroup from "../components/reporting/SalesByProductGroup";
	import SalesByProductTableWithFilter from "../components/reporting/SalesByProductTableWithFilter";
	export default {
		name: "ProductStatsPage",
		components: {
			SalesByProductTableWithFilter,
			SalesByProductGroup,
			ProductStatsOverviewTable,
			SiteSaleStatsTable,
			ReportNavBar,
			BarChart,
			ReportGenericTable, ReportPaymentTypeTable, ReportTimingTable, PieChart, SelfBuildingSquareSpinner},
		props: [
			'colors',
			'routes',
			'franchise',

			'load_url',

			'product_groups',

			'default_from_date',
			'default_to_date',

			'date_ranges',
		],
		data(){
			return {
				active_product_status: 'all',

				product_stats: null,

				route_selected: [],
				from_date: null,
				to_date: null,
			}
		},

		methods: {

		},

		computed: {
			routeName(){
				if (this.routes && this.routes[this.route_selected]){
					return this.routes[this.route_selected].name || 'Ad Hoc';
				}

				return 'n/a';
			},
		},
	}
</script>