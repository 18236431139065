<template>
    <modal
        title="NOTIFICATION"
        :subtitle="`There ${updates.length > 1 ? 'are' : 'is'} ${updates.length} coffee stop which must be reviewed`"

        v-model="showModal"
    >
        <template>Please review the coffee stop{{updates.length > 1 ? 's' : ''}} and assign a traffic light marker</template>

        <template slot="footer">
            <button class="btn btn-black" @click="ignoreNotifications">Ignore</button>
            <button class="btn btn-primary" @click="goToStops">Go to stops</button>
        </template>
    </modal>
</template>

<script>
import Modal from "./generic/Modal";
export default {
    name: "StopUpdateRequiredModal",
    components: {Modal},
    props: {
        franchiseId: {
            required: true,
            type: String
        },

        updates: {
            required: true,
            type: Array
        },

        baseUrl: {
            required: true,
            type: String
        }
    },

    data(){
        return {
            showModal: false
        }
    },

    beforeMount() {
        this.showModal = this.updates.length > 0 && this.checkLocalStorage();
    },

    methods: {
        checkLocalStorage(){
            let item = localStorage.getItem(`franchise-${this.franchiseId}-stop-updates-ignored`);

            return item === null || (item !== null && this.doesDateRequireConfirmation(item));
        },

        doesDateRequireConfirmation(dateString){
            let parts = String(dateString).split(', ');

            let cachedDate = new Date(...parts[0].split('/').reverse(), ...parts[1].split(':'));
                cachedDate.setMonth(cachedDate.getMonth() - 1); //javascript month starts from zero so take 1 from the value we passed

            //1000 ms * 60 s * 60 m * 24 h * 7 d
            // let weekInMS = 1000 * 60 * 60 * 24 * 7;

            //1000 ms * 60 s * 60 m * 12 h
            let twelveHoursInMS = 1000 * 60 * 60 * 12;

            //if the date is more than a week old
            return new Date().valueOf() - twelveHoursInMS > cachedDate.valueOf();
        },

        ignoreNotifications(){
            localStorage.setItem(`franchise-${this.franchiseId}-stop-updates-ignored`, new Date().toLocaleString());

            this.showModal = false;
        },

        goToStops(){
            this.ignoreNotifications();

            if(this.updates.length === 1){
                return window.location.href = `${this.baseUrl}/${this.updates[0].route_id}`;
            }

            return window.location.href = this.baseUrl;
        }
    },

    watch: {
        showModal(newValue) {
            if(!newValue){
                this.ignoreNotifications();
            }
        }
    }
}
</script>
