var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-page"},[_c('report-nav-bar',{attrs:{"routes":_vm.routes,"date_ranges":_vm.date_ranges,"default_range":0,"hide_today":true,"hide_avg":true,"default_route_selected":[]},on:{"load":_vm.loadData,"route_selected":function($event){_vm.route_selected = $event},"to_date":function($event){_vm.to_date = $event},"from_date":function($event){_vm.from_date = $event}}}),_vm._v(" "),(_vm.loading)?_c('self-building-square-spinner',{staticClass:"mx-auto",attrs:{"animation-duration":6000,"size":40,"color":"#ff1d5e"}}):(_vm.data)?_c('div',{staticClass:"dashboard-page--content"},[_c('div',{staticClass:"dashboard-page--component"},[_c('div',{staticClass:"row pt-4"},[_c('div',{staticClass:"col-12 text-left"},[_c('h4',{staticClass:"dashboard-page--component-title"},[_vm._v("Snapshot "),_c('span',[_vm._v(" - "+_vm._s(_vm.snapshotText))])])]),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('report-generic-table',{attrs:{"data":[
							{title: 'Active App Customers', value: _vm.data.customer_stats_snapshot.active_app_users},
							{title: 'Estimated Active Non-App Customers', value: _vm.data.customer_stats_snapshot.estimated_active},
							{title: 'Inactive App Customers', value: _vm.data.customer_stats_snapshot.inactive_app_users},
							{title: 'Estimated Total Active Customers', value: _vm.data.customer_stats_snapshot.total_estimated}
						]}})],1)])]),_vm._v(" "),_c('div',{staticClass:"dashboard-page--component"},[_c('div',{staticClass:"row pt-4"},[_c('div',{staticClass:"col-12 text-left"},[_c('h4',{staticClass:"dashboard-page--component-title"},[_vm._v("Customer Buying Habits")])]),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('customer-buying-habits',{attrs:{"data":_vm.data.buys_per_week,"colors":_vm.colors}})],1)])]),_vm._v(" "),_c('div',{staticClass:"dashboard-page--component"},[_c('div',{staticClass:"row pt-4"},[_c('div',{staticClass:"col-12 text-left"},[_c('h4',{staticClass:"dashboard-page--component-title"},[_vm._v("Date Range Analysis")])]),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('customer-date-range-analysis',{attrs:{"data":_vm.data.date_range_analysis,"colors":_vm.colors}})],1)])]),_vm._v(" "),_c('div',{staticClass:"dashboard-page--component"},[_c('div',{staticClass:"row pt-4"},[_c('div',{staticClass:"col-12 text-left"},[_c('h4',{staticClass:"dashboard-page--component-title"},[_vm._v("Pre-Orders")])]),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('pre-orders-table',{attrs:{"data":[
						{name: 'Trans. ordered via App', primary_data: 'N/A', secondary_data: 'N/A'},
						{name: 'Average App Order gross', primary_data: 'N/A', secondary_data: ''},
						{name: 'App orders cancelled by customer', primary_data: 'N/A', secondary_data: 'N/A'},
						{name: 'Average App Order GP', primary_data: 'N/A', secondary_data: ''},
						{name: 'Orders left at collection point', primary_data: 'N/A', secondary_data: 'N/A'} ]}})],1)])]),_vm._v(" "),_c('div',{staticClass:"dashboard-page--component"},[_c('div',{staticClass:"row pt-4"},[_c('div',{staticClass:"col-12 text-left"},[_c('h4',{staticClass:"dashboard-page--component-title"},[_vm._v("Active Customers Graph")])]),_vm._v(" "),_c('div',{staticClass:"col-12 pt-4"},[_c('div',{staticClass:"colors mb-5 d-flex"},_vm._l(([ _vm.data.active_customers_data.active_app_users, _vm.data.active_customers_data.estimated_total_users ]),function(colour){return _c('div',{staticClass:"ml-2"},[_c('div',{staticClass:"badge badge-pill badge-primary ml-2 d-inline-block",staticStyle:{"width":"16px","height":"16px"},style:({'background-color': Object.keys(colour).length > 0 ? colour.colour : null})}),_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(Object.keys(colour).length > 0 ? colour.name : null)+"\n\t\t\t\t\t\t")])}),0),_vm._v(" "),_c('line-chart',{attrs:{"data":[
									_vm.data.active_customers_data.active_app_users,
									_vm.data.active_customers_data.estimated_total_users
							],"headers":_vm.data.active_customers_data.headers}})],1)])])]):_c('div',[_c('h2',[_vm._v("No results found")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }