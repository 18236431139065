<template>
	<div class="container px-0">

		<!--Loader-->
		<self-building-square-spinner
			v-if="loading"
			class="mx-auto"
			:animation-duration="6000"
			:size="40"
			color="#ff1d5e"
		/>

		<div v-else-if="data" class="row">
			<div class="col-12 col-sm-6">
				<div class="row mt-4">
					<div class="col-12">
<!--						<h5 class="font-weight-bold text-center">Food : Gourmet Drinks : Other</h5>-->
						<div class="row">
							<div class="col-3 col-sm-3"><h5 class="font-weight-bold text-center">Food</h5></div>
							<div class="col-1">:</div>
							<div class="col-3 col-sm-3"><h5 class="font-weight-bold text-center">Gourmet Drinks</h5></div>
							<div class="col-1">:</div>
							<div class="col-3 col-sm-3"><h5 class="font-weight-bold text-center">Other</h5></div>
						</div>
					</div>
					<div class="col-12">
<!--						<h5 class="font-weight-bold text-center">{{FoodPercent}}% : {{GourmetDrinksPercent}}% : {{OtherPercent}}%</h5>-->
						<div class="row">
							<div class="col-3 col-sm-3"><h5 class="font-weight-bold text-center">{{FoodPercent}}%</h5></div>
							<div class="col-1">:</div>
							<div class="col-3 col-sm-3"><h5 class="font-weight-bold text-center">{{GourmetDrinksPercent}}%</h5></div>
							<div class="col-1">:</div>
							<div class="col-3 col-sm-3"><h5 class="font-weight-bold text-center">{{OtherPercent}}%</h5></div>
						</div>
					</div>
				</div>

				<div class="row mt-3">
					<div class="col-12">
						<pie-chart
							:data="[{value: FoodPercent, name: 'Food'}, {value: GourmetDrinksPercent, name: 'Gourmet Drinks'}, {value: OtherPercent, name: 'Other', }]"
							name="item"
							prefix="£"
							:height="220"
							:no_margin="true"
							:color="colors"
						></pie-chart>
					</div>
				</div>
			</div>
			<div class="col-12 col-sm-6">
				<div class="row mt-4">
					<div class="col-12">
<!--						<h5 class="font-weight-bold text-center">Hot Food : Chilled Food</h5>-->
						<div class="row">
							<div class="col-3"><h5 class="font-weight-bold text-center">Hot Food</h5></div>
							<div class="col-1">:</div>
							<div class="col-3"><h5 class="font-weight-bold text-center">Chilled Food</h5></div>
							<div class="col-1">:</div>
							<div class="col-3"><h5 class="font-weight-bold text-center">Sandwich Platters</h5></div>
						</div>
					</div>
					<div class="col-12">
<!--						<h5 class="font-weight-bold text-center">{{HotFood}}% : {{ChilledFood}}%</h5>-->
						<div class="row">
							<div class="col-3 col-sm-3"><h5 class="font-weight-bold text-center">{{HotFood}}%</h5></div>
							<div class="col-1">:</div>
							<div class="col-3 col-sm-3"><h5 class="font-weight-bold text-center">{{ChilledFood}}%</h5></div>
							<div class="col-1">:</div>
							<div class="col-3 col-sm-3"><h5 class="font-weight-bold text-center">{{SandwichPlatters}}%</h5></div>
						</div>
					</div>
				</div>
				<div class="row mt-5">
					<div class="col-12">
<!--						<h5 class="font-weight-bold text-center">Hot Drinks : Blended Drinks</h5>-->
						<div class="row">
							<div class="offset-2 col-3 col-sm-3"><h5 class="font-weight-bold text-center">Hot Drinks</h5></div>
							<div class="col-1">:</div>
							<div class="col-3 col-sm-3"><h5 class="font-weight-bold text-center">Blended Drinks</h5></div>
						</div>
					</div>
					<div class="col-12">
<!--						<h5 class="font-weight-bold text-center">{{HotDrinks}}% : {{BlendedDrinks}}%</h5>-->
						<div class="row">
							<div class="offset-2 col-3 col-sm-3"><h5 class="font-weight-bold text-center">{{HotDrinks}}%</h5></div>
							<div class="col-1">:</div>
							<div class="col-3 col-sm-3"><h5 class="font-weight-bold text-center">{{BlendedDrinks}}%</h5></div>
						</div>
					</div>
				</div>
				<div class="row mt-5">
					<div class="col-12">
<!--						<h5 class="font-weight-bold text-center">Small : Regular : Large</h5>-->
						<div class="row">
							<div class="col-3 col-sm-3"><h5 class="font-weight-bold text-center">Small</h5></div>
							<div class="col-1">:</div>
							<div class="col-3 col-sm-3"><h5 class="font-weight-bold text-center">Regular</h5></div>
							<div class="col-1">:</div>
							<div class="col-3 col-sm-3"><h5 class="font-weight-bold text-center">Large</h5></div>
						</div>
					</div>
					<div class="col-12">
<!--						<h5 class="font-weight-bold text-center">{{Small}}% : {{Medium}}% : {{Large}}%</h5>-->
						<div class="row">
							<div class="col-3 col-sm-3"><h5 class="font-weight-bold text-center">{{Small}}%</h5></div>
							<div class="col-1">:</div>
							<div class="col-3 col-sm-3"><h5 class="font-weight-bold text-center">{{Medium}}%</h5></div>
							<div class="col-1">:</div>
							<div class="col-3 col-sm-3"><h5 class="font-weight-bold text-center">{{Large}}%</h5></div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-else>
			<h2>No results found</h2>
		</div>
	</div>
</template>

<script>
	import PieChart from "./PieChart";
	import { SelfBuildingSquareSpinner  } from 'epic-spinners'
	export default {
		name: "ProductStatsOverviewTable",
		components: {PieChart, SelfBuildingSquareSpinner},
		props: [
			'colors',
			'routes',

			'load_url',

			'active_product_status',
			'route_selected',
			'to_date',
			'from_date',
		],

		data(){
			return {
				loading: false,
				reload: false,

				data: null
			}
		},

		methods: {
			loadData(){
				let _this = this;

				if (_this.loading){
					_this.reload = true;
					return;
				}

				_this.loading = true;

				let _routes = null;
				if(_this.route_selected.length > 0){
					_routes = _this.route_selected.filter(item => item != null).map(function(item){
						return _this.routes[item].id;
					});
				}

				axios.post(_this.load_url, {
					route: _routes,
					filter: _this.active_product_status,
					from_date: _this.from_date,
					to_date: _this.to_date,
				})
					.then(function (response){
						_this.loading = false;

						if (_this.reload){
							_this.reload = false;
							_this.loadData();
							return;
						}

						_this.data = response.data;
					})
					.catch(function (e){

					});
			},

			itemsByGroupName(group_names){
				if (!this.data) return 0;
				let
					_this = this,
					items = 0;

				_this.data.sales_by_product_group.forEach(function (group_data){
					if (Array.isArray(group_names)){
						group_names.forEach(function (group_name){
							if (group_data.product_group_name === group_name)
								items += group_data.items_sold;
						});
					}
					else {
						if (group_data.product_group_name === group_names)
							items += group_data.items_sold;
					}
				});

				return items;
			}
		},

		computed: {
			FoodPercent(){
				if (!this.data) return 0;
				let
					total = this.data.total_sales,
					items = this.itemsByGroupName(['Chilled Food', 'Hot Food', 'Sandwich Platters']);

				return Math.round(items / total * 100);
			},
			GourmetDrinksPercent(){
				if (!this.data) return 0;
				let
					total = this.data.total_sales,
					items = this.itemsByGroupName(['Hot Drinks', 'Blended Drinks']);

				return Math.round(items / total * 100);
			},
			OtherPercent(){
				if (!this.data) return 0;
				let
					total = this.data.total_sales,
					items = this.itemsByGroupName(['Hot Drinks', 'Blended Drinks', 'Chilled Food', 'Hot Food']);

				return (Math.round(items / total * 100) - 100) * -1;
			},

			HotFood(){
				if (!this.data) return 0;
				let
					hot_items = this.itemsByGroupName('Hot Food'),
					chilled_items = this.itemsByGroupName('Chilled Food'),
					sandwich_platters = this.itemsByGroupName('Sandwich Platters');

				return (hot_items + chilled_items + sandwich_platters) ?
					Math.round(hot_items / (hot_items + chilled_items + sandwich_platters) * 100)
					: 'N/A';
			},
			ChilledFood(){
				if (!this.data) return 0;
				let
					hot_items = this.itemsByGroupName('Hot Food'),
					chilled_items = this.itemsByGroupName('Chilled Food'),
					sandwich_platters = this.itemsByGroupName('Sandwich Platters');

				return (hot_items + chilled_items + sandwich_platters) ?
					Math.round(chilled_items / (hot_items + chilled_items + sandwich_platters) * 100)
					: 'N/A';
			},

			SandwichPlatters(){
				if (!this.data) return 0;
				let
						hot_items = this.itemsByGroupName('Sandwich Platters'),
						chilled_items = this.itemsByGroupName('Chilled Food'),
						sandwich_platters = this.itemsByGroupName('Sandwich Platters');

				return (hot_items + chilled_items + sandwich_platters) ?
						Math.round(sandwich_platters / (hot_items + chilled_items + sandwich_platters) * 100)
						: 'N/A';
			},

			HotDrinks(){
				if (!this.data) return 0;
				let
					hot_items = this.itemsByGroupName('Hot Drinks'),
					chilled_items = this.itemsByGroupName('Blended Drinks');

				return (hot_items + chilled_items) ?
					Math.round(hot_items / (hot_items + chilled_items) * 100)
					: 'N/A';
			},
			BlendedDrinks(){
				if (!this.data) return 0;
				let
					hot_items = this.itemsByGroupName('Hot Drinks'),
					chilled_items = this.itemsByGroupName('Blended Drinks');

				return (hot_items + chilled_items) ?
					Math.round(chilled_items / (hot_items + chilled_items) * 100)
					: 'N/A';
			},

			Small(){
				if (!this.data) return 0;
				let
					small = this.data.sales_by_size[1] || 0,
					medium = this.data.sales_by_size[2] || 0,
					large = this.data.sales_by_size[3] || 0,
					total = small + medium + large;

				return total
					? Math.round(small / total * 100)
					: 0;
			},
			Medium(){
				if (!this.data) return 0;
				let
					small = this.data.sales_by_size[1] || 0,
					medium = this.data.sales_by_size[2] || 0,
					large = this.data.sales_by_size[3] || 0,
					total = small + medium + large;

				return total
					? Math.round(medium / total * 100)
					: 0;
			},
			Large(){
				if (!this.data) return 0;
				let
					small = this.data.sales_by_size[1] || 0,
					medium = this.data.sales_by_size[2] || 0,
					large = this.data.sales_by_size[3] || 0,
					total = small + medium + large;

				return total
					? Math.round(large / total * 100)
					: 0;
			},
		},

		watch: {
			active_product_status(){
				this.loadData();
			},
			route_selected(){
				this.loadData();
			},
			to_date(){
				this.loadData();
			},
			from_date(){
				this.loadData();
			},
		}
	}
</script>