<template>
	<div class="dashboard-page">
		<div class="dashboard-page--header-title">
			<div class="d-inline-block float-right">
				<a class="btn btn-primary" :href="redirect_url">Back</a>
			</div>
			<h4>{{route_name}}</h4>
			<h6 class="dashboard-page--component-title">{{event_date}}</h6>
			<h6>{{event.frequency !== null ? 'Recurring' : 'One Off'}}</h6>
		</div>

		<!--Content of report-->
		<div class="dashboard-page--content">

			<!--Events & Transactions-->
			<div class="dashboard-page--component">
				<div class="row">
					<div class="col-6">
						<h4 class="dashboard-page--component-title">Events & Transactions</h4>
					</div>
					<div class="col-6">
					</div>
				</div>

				<report-generic-table
					:data="[
						{title: 'Transactions', value: Math.round(compiled_daily_report.total_transactions)},
						{title: 'Gross Takings', value: '£' + compiled_daily_report.total_gross.toFixed(2)},
						{title: 'Trading Time', value: getHumanTime(compiled_daily_report.total_time - compiled_daily_report.total_time_driving)},
						{title: 'Total GP', value: '£' + compiled_daily_report.total_gross_profit.toFixed(2)},
						{title: 'Profit Margin', value: Math.round(compiled_daily_report.total_gross_profit / compiled_daily_report.total_gross * 100) + '%'},
						{title: 'Event Fee', value: '£' + compiled_daily_report.event_fees.toFixed(2)},
						{title: 'GP minus Event Fees', value: '£' + (compiled_daily_report.total_gross_profit - compiled_daily_report.event_fees).toFixed(2)},
						{title: 'Wastage', value: '£' + compiled_daily_report.wastage.toFixed(2)},
						{title: 'GP minus Fees & Wastage', value: '£' + (compiled_daily_report.total_gross_profit - compiled_daily_report.event_fees - compiled_daily_report.wastage).toFixed(2)},
					]"
				></report-generic-table>

				<hr class="dashboard-page--content-spacer">
			</div>

			<!--Payments by type-->
			<div class="dashboard-page--component">
				<div class="row">
					<div class="col-12">
						<h4 class="dashboard-page--component-title">Payment Types</h4>
					</div>
				</div>

				<div class="row">
					<div class="col-6">
						<pie-chart
							:data="[
								{value: compiled_daily_report.cash.toFixed(2), name: 'Cash'},
								{value: compiled_daily_report.card.toFixed(2), name: 'Card'},
								// {value: compiled_daily_report.credit_accounts.toFixed(2), name: 'Credit Account'},
								// {value: compiled_daily_report.app_payments.toFixed(2), name: 'App'}
							]"
							name="Payments"
							prefix="£"
							:color="colors"
						></pie-chart>
					</div>
					<div class="col-6 d-flex align-items-center">
                        <div>
                            <h5 class="font-weight-bold">Before Adjustment</h5>

                            <report-payment-type-table
                                :cash="{value: (compiled_daily_report.cash).toFixed(2), percentage: (compiled_daily_report.cash / compiled_daily_report.total_gross * 100).toFixed(2)}"
                                :card="{value: (compiled_daily_report.card).toFixed(2), percentage: (compiled_daily_report.card / compiled_daily_report.total_gross * 100).toFixed(2)}"
                                :credit_accounts="{value: (compiled_daily_report.credit_accounts).toFixed(2), percentage: (compiled_daily_report.credit_accounts / compiled_daily_report.total_gross * 100).toFixed(2)}"
                                :app_payments="{value: (compiled_daily_report.app_payments).toFixed(2), percentage: (compiled_daily_report.app_payments / compiled_daily_report.total_gross * 100).toFixed(2)}"
                            ></report-payment-type-table>

                            <h5 class="font-weight-bold mt-4">After Adjustment</h5>

                            <report-payment-type-table
                                :cash="{value: ((parseFloat(compiled_daily_report.cash) + parseFloat(compiled_daily_report.cash_adjustment))).toFixed(2), percentage: ((parseFloat(compiled_daily_report.cash) + parseFloat(compiled_daily_report.cash_adjustment)) / compiled_daily_report.total_gross * 100).toFixed(2)}"
                                :card="{value: ((parseFloat(compiled_daily_report.card) + parseFloat(compiled_daily_report.card_adjustment))).toFixed(2), percentage: ((parseFloat(compiled_daily_report.card) + parseFloat(compiled_daily_report.card_adjustment)) / compiled_daily_report.total_gross * 100).toFixed(2)}"
                                :credit_accounts="{value: (compiled_daily_report.credit_accounts).toFixed(2), percentage: (compiled_daily_report.credit_accounts / compiled_daily_report.total_gross * 100).toFixed(2)}"
                                :app_payments="{value: (compiled_daily_report.app_payments).toFixed(2), percentage: (compiled_daily_report.app_payments / compiled_daily_report.total_gross * 100).toFixed(2)}"
                            ></report-payment-type-table>
                        </div>
					</div>
				</div>

				<hr class="dashboard-page--content-spacer">
			</div>

			<!--Product Stats-->
			<div class="dashboard-page--component">
				<div class="row">
					<div class="col-12">
						<h4 class="dashboard-page--component-title">Product Stats</h4>
					</div>
				</div>

				<div class="row">
					<div class="col-12 col-sm-6">
						<report-generic-table
								:data="[
								{title: 'Total Gourmet Drinks', value: num_hot_drinks + num_blended_drinks},
								{title: 'Hot Drinks', value: num_hot_drinks, light: true},
								{title: 'Blended Drinks', value: num_blended_drinks, light: true},
							]"
								:single-col="true"
						></report-generic-table>

						<div class="row mt-4">
							<div class="col-12">
								<h4 class="font-weight-bold text-center">Percentage of GP</h4>
							</div>
							<div class="col-12">
								<div class="row">
									<div class="col-3">
										<h5 class="font-weight-bold text-center">Gourmet Drinks</h5>
									</div>
									<div class="col-1">:</div>
									<div class="col-3">
										<h5 class="font-weight-bold text-center">Hot & Chilled Food</h5>
									</div>
									<div class="col-1">:</div>
									<div class="col-3">
										<h5 class="font-weight-bold text-center">Other</h5>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="row">
									<div class="col-3">
										<h5 class="font-weight-bold text-center">{{numberFormatWCurrencyCheck(GPGourmetDrinks + '%')}}</h5>
									</div>
									<div class="col-1">:</div>
									<div class="col-3">
										<h5 class="font-weight-bold text-center">{{numberFormatWCurrencyCheck(GPFood + '%')}}</h5>
									</div>
									<div class="col-1">:</div>
									<div class="col-3">
										<h5 class="font-weight-bold text-center">{{numberFormatWCurrencyCheck(GPOther + '%')}}</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6">
						<report-generic-table
								:data="foodItemsColumnData"
								:single-col="true"
						></report-generic-table>
					</div>
				</div>

				<hr class="dashboard-page--content-spacer">
			</div>

			<!--Cumulative GP over day-->
			<div class="row">
				<div class="col-12">
					<div class="dashboard-page--component">
						<div class="row py-4">
							<div class="col-12">
								<bar-chart
										:bar_name="'Cumulative GP Over Day'"
										bar_color="#5fa3e5"
										prefix="£"
										:bar_data="GPPerHour"
								></bar-chart>
								<hr>
							</div>
						</div>
					</div>
				</div>
			</div>


		</div>
	</div>
</template>

<script>
	import PieChart from "../components/reporting/PieChart";
	import BarChart from "../components/reporting/BarChart";
	import ReportPaymentTypeTable from "../components/reporting/ReportPaymentTypeTable";
	import ReportGenericTable from "../components/reporting/ReportGenericTable";
	export default {
		name: "SingleEventReportPage",
		components: {
			ReportGenericTable, ReportPaymentTypeTable, BarChart, PieChart},
		props: [
			'colors',
			'hourly_data',

			'route_name',
			'daily_report',
			'sales_product_group',


			'event',
			'event_date',

			'redirect_url'
		],
		data(){
			return {
				loading: false,
				reload: false,

				avg: false,

				today: null,

				hourly_data_edit: null,

				daily_report_edit: null,
				sales_product_group_edit: null,
			}
		},

		beforeMount(){
			this.hourly_data_edit = this.hourly_data;
			this.daily_report_edit = this.daily_report;
			this.sales_product_group_edit = this.sales_product_group;
		},

		methods: {
			getHumanTime(seconds){
				let date = moment.duration(Math.round(seconds) * 1000);
				return Math.round(date.asHours()) + ' h ' + (date.minutes() > 9 ? date.minutes() : '0' + date.minutes()) + ' m';
			},

			getProductGroupByName(name, use_avg = true){
				for (let i = 0; i < this.sales_product_group_edit.length; i ++){
					if (this.sales_product_group_edit[i].product_group === name){
						return this.avg && use_avg ?  this.sales_product_group_edit[i]._avg : this.sales_product_group_edit[i];
					}
				}
				return null;
			}
		},

		computed: {
			compiled_daily_report(){
				return this.avg ? this.daily_report_edit._avg : this.daily_report_edit;
			},

			GPGourmetDrinks(){
				if (!this.daily_report_edit.total_gross_profit) return 0;

				let gp = parseFloat(this.getProductGroupByName('Hot Drinks', false).gross_profit)
						+ parseFloat(this.getProductGroupByName('Blended Drinks', false).gross_profit);
				return (gp / this.daily_report_edit.total_gross_profit * 100).toFixed(2);
			},
			GPFood(){
				if (!this.daily_report_edit.total_gross_profit) return 0;

				let gp = parseFloat(this.getProductGroupByName('Chilled Food', false).gross_profit) + parseFloat(this.getProductGroupByName('Hot Food', false).gross_profit);
				return (gp / this.daily_report_edit.total_gross_profit * 100).toFixed(2);
			},
			GPOther(){
				if (!this.daily_report_edit.total_gross_profit) return 0;

				let gp = 0, _this = this;

				this.sales_product_group_edit.forEach(function (group){
					if (group.product_group !== 'Hot Drinks' && group.product_group !== 'Blended Drinks' && group.product_group !== 'Chilled Food' && group.product_group !== 'Hot Food'){
						gp +=  parseFloat(group.gross_profit);
					}
				});

				return (gp / this.daily_report_edit.total_gross_profit * 100).toFixed(2);
			},
			GPPerHour(){
				let data = [];
				for (let key in this.hourly_data.data){
					if (this.hourly_data.data.hasOwnProperty(key)){
						data.push({
							label: key,
							value: this.hourly_data.data[key]
									? this.hourly_data.data[key]
									: null
						})
					}
				}
				return data;
			},

			foodItemsColumnData(){
				let data = [{
					title: 'Other Items',
					value: ''
				}];

				this.sales_product_group.forEach(function (group){
					if (group.product_group !== 'Hot Drinks' && group.product_group !== 'Blended Drinks'){
						data.push({'title': group.product_group, value: group.number_of_sales, light: true});
					}
				});

				return data;
			},

			num_hot_drinks(){
				return Math.round(this.getProductGroupByName('Hot Drinks').number_of_sales);
			},
			num_blended_drinks(){
				return Math.round(this.getProductGroupByName('Blended Drinks').number_of_sales);
			},

			num_chilled_food(){
				return Math.round(this.getProductGroupByName('Chilled Food').number_of_sales);
			},
			num_hot_food(){
				return Math.round(this.getProductGroupByName('Hot Food').number_of_sales);
			},
		}
	}
</script>
