<template>
    <div class="row">
        <div class="col-6" v-for="item in data">
            <div class="row">
                <div class="col-7">
                    <h5 class="font-weight-bold">{{item.name}}</h5>
                </div>
                <div class="col-2">
                    <h5 class="font-weight-bold">{{item.primary_data}}</h5>
                </div>
                <div class="col-3">
                    <h5 class="font-weight-bold">{{item.secondary_data}}</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PreOrdersTable",

        props: [
            'data'
        ]
    }
</script>

<style scoped>

</style>