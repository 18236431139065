<template>
	<div class="container px-0">
		<div class="row">
			<div class="col-3">
				<h5 class="font-weight-bold">Cash</h5>
			</div>
			<div class="col-3">
				<h5 class="font-weight-bold">Card</h5>
			</div>
			<div class="col-3">
				<h5 class="font-weight-bold" v-if="credit_accounts != undefined">Credit Accounts</h5>
			</div>
			<div class="col-3">
				<h5 class="font-weight-bold" v-if="app_payments != undefined">App</h5>
			</div>
		</div>

		<div class="row">
			<div class="col-3">
				<span class="dashboard-page--component-stats">£{{numberFormat(cash.value)}}</span>
			</div>
			<div class="col-3">
				<span class="dashboard-page--component-stats">£{{numberFormat(card.value)}}</span>
			</div>
			<div class="col-3" v-if="credit_accounts != undefined">
				<span class="dashboard-page--component-stats">£{{numberFormat(credit_accounts.value)}}</span>
			</div>
			<div class="col-3" v-if="app_payments != undefined">
				<span class="dashboard-page--component-stats">£{{numberFormat(app_payments.value)}}</span>
			</div>
		</div>

		<div class="row">
			<div class="col-3">
				<span class="dashboard-page--component-stats">{{numberFormatWCurrencyCheck(cash.percentage + '%')}}</span>
			</div>
			<div class="col-3">
				<span class="dashboard-page--component-stats">{{numberFormatWCurrencyCheck(card.percentage + '%')}}</span>
			</div>
			<div class="col-3" v-if="credit_accounts != undefined">
				<span class="dashboard-page--component-stats">{{numberFormatWCurrencyCheck(credit_accounts.percentage + '%')}}</span>
			</div>
			<div class="col-3" v-if="app_payments != undefined">
				<span class="dashboard-page--component-stats">{{numberFormatWCurrencyCheck(app_payments.percentage + '%')}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "ReportPaymentTypeTable",
		props: [
			'cash',
			'card',
			'credit_accounts',
			'app_payments'
		]
	}
</script>
