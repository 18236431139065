<template>
	<div class="container px-0">
		<div class="row">
			<div class="col-6">
				<h5 class="font-weight-bold">Serving</h5>
			</div>
			<div class="col-6">
				<h5 class="font-weight-bold">Driving</h5>
			</div>
		</div>

		<div class="row">
			<div class="col-6">
				<span class="dashboard-page--component-stats">{{total_time_serving}}</span>
			</div>
			<div class="col-6">
				<span class="dashboard-page--component-stats">{{total_time_driving}}</span>
			</div>
		</div>

		<div class="row">
			<div class="col-6">
				<span class="dashboard-page--component-stats">{{!isNaN(total_time_serving_percentage) ? numberFormatWCurrencyCheck(total_time_serving_percentage + '%') : '0%'}}</span>
			</div>
			<div class="col-6">
				<span class="dashboard-page--component-stats">{{!isNaN(total_time_serving_percentage) ? numberFormatWCurrencyCheck(total_time_driving_percentage + '%') : '0%'}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "ReportTimingTable",
		props: [
			'total_time_serving',
			'total_time_driving',
			'total_time_serving_percentage',
			'total_time_driving_percentage',
		],
	}
</script>