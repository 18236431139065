<template>
    <div class="dashboard-page">

        <!--Top Filters Header-->
        <report-nav-bar
                :routes="routes"
                :default_route_selected="[0]"
                :date_ranges="date_ranges"
                :refresh_button="true"
                :hide_today="true"
                :hide_avg="true"
                :start_date="start_date"
                :end_date="end_date"
                @load="loadData"

                :only_single_date="true"
                :has_open_route="has_open_route"
                @avg="avg = $event"
                @route_selected="route_selected = $event"
                @from_date="from_date = $event"
        ></report-nav-bar>

        <!--Loader-->
        <self-building-square-spinner
                v-if="loading"
                class="mx-auto"
                :animation-duration="6000"
                :size="40"
                color="#ff1d5e"
        />

        <!--Content of report-->
        <div v-else-if="daily_report" class="dashboard-page--content">

            <!--Daily Timings-->
            <div class="dashboard-page--component">
                <div class="row">
                    <div class="col-6">
                        <h4 class="dashboard-page--component-title">{{avg ? 'Average Daily Timings' : 'Total Daily Timings'}}</h4>
                    </div>
                    <div class="col-6">
                        <h4 class="dashboard-page--component-title">{{getHumanTime(compiled_daily_report.total_time)}} / {{compiled_daily_report.route_start_time}} - {{compiled_daily_report.route_end_time}}</h4>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <pie-chart
                                :data="[{value: compiled_daily_report.total_time_driving, name: 'Driving'},{value: compiled_daily_report.total_time - compiled_daily_report.total_time_driving, name: 'Serving'}]"
                                name="Timings"
                                :hide_values="true"
                                :color="colors"
                        ></pie-chart>
                    </div>
                    <div class="col-6 d-flex align-items-center">
                        <report-timing-table
                                :total_time_serving="getHumanTime(compiled_daily_report.total_time - compiled_daily_report.total_time_driving)"
                                :total_time_driving="getHumanTime(compiled_daily_report.total_time_driving)"
                                :total_time_serving_percentage="((compiled_daily_report.total_time - compiled_daily_report.total_time_driving) / compiled_daily_report.total_time * 100).toFixed(2)"
                                :total_time_driving_percentage="(compiled_daily_report.total_time_driving / compiled_daily_report.total_time * 100).toFixed(2)"
                        ></report-timing-table>
                    </div>
                </div>

                <hr class="dashboard-page--content-spacer">
            </div>

            <!--Daily Takings and GP-->
            <div class="dashboard-page--component">
                <div class="row">
                    <div class="col-5">
                        <h4 class="dashboard-page--component-title">{{avg ? 'Average Daily Takings & GP' : 'Total Daily Takings & GP'}}</h4>
                    </div>

                    <div class="col">
                        <h4 class="dashboard-page--component-title">Average Margin: {{numberFormatWCurrencyCheck((compiled_daily_report.total_gross_profit / compiled_daily_report.total_net * 100).toFixed(2) +  ' %')}}</h4>
                    </div>

                    <div class="col">
                        <h4 class="dashboard-page--component-title">Variance: {{compiled_daily_report.variance ? (numberFormatWCurrencyCheck('£' + (compiled_daily_report.variance).toFixed(2))) : '' }}</h4>
                    </div>
                </div>

                <report-generic-table
                        :data="[
						{title: avg ? 'Gross takings per day' : 'Total gross takings', value: '£' + compiled_daily_report.total_gross.toFixed(2)},
						{title: avg ? 'Av. GP / hr trading' : 'GP / hr trading', value: '£' + GPperHourTrading},
						{title: avg ? 'GP before variance per day' : 'Total GP before variance', value: '£' + compiled_daily_report.total_gross_profit.toFixed(2)},
						{title: avg ? 'Av. GP / hr serving' : 'GP / hr serving', value: '£' + GPperHourServing},
						{title: avg ? 'GP after variance per day' : 'Total GP after variance', value: '£' + compiled_daily_report.total_gross_inc_var.toFixed(2)},
						{title: avg ? 'Wastage per day' : 'Total wastage', value: '£' + compiled_daily_report.wastage.toFixed(2)},
                        {title: avg ? 'GP minus wastage per day' : 'GP minus wastage', value: '£' + (compiled_daily_report.total_gross_profit - compiled_daily_report.wastage).toFixed(2)},
					]"
                ></report-generic-table>

                <hr class="dashboard-page--content-spacer">
            </div>

            <!--Payments by type-->
            <div class="dashboard-page--component">
                <div class="row">
                    <div class="col-12">
                        <h4 class="dashboard-page--component-title">{{avg ? 'Average Payments By Type' : 'Total Payments By Type'}}</h4>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <pie-chart
                                :data="[
                                  {value: (parseFloat(compiled_daily_report.cash) + parseFloat(compiled_daily_report.cash_adjustment)).toFixed(2), name: 'Cash'},
                                  {value: (parseFloat(compiled_daily_report.card) + parseFloat(compiled_daily_report.card_adjustment)).toFixed(2), name: 'Card'},
                                  {value: compiled_daily_report.credit_accounts.toFixed(2), name: 'Credit Account'},
                                  {value: compiled_daily_report.app_payments.toFixed(2), name: 'App'}
                                ]"
                                name="Payments"
                                prefix="£"
                                :color="colors"
                        ></pie-chart>
                    </div>
                    <div class="col-6 d-flex align-items-center">
                        <div>
                          <h5 class="font-weight-bold">Before Adjustment</h5>

                          <report-payment-type-table
                                  :cash="{value: (compiled_daily_report.cash).toFixed(2), percentage: (compiled_daily_report.cash / compiled_daily_report.total_gross * 100).toFixed(2)}"
                                  :card="{value: (compiled_daily_report.card).toFixed(2), percentage: (compiled_daily_report.card / compiled_daily_report.total_gross * 100).toFixed(2)}"
                                  :credit_accounts="{value: (compiled_daily_report.credit_accounts).toFixed(2), percentage: (compiled_daily_report.credit_accounts / compiled_daily_report.total_gross * 100).toFixed(2)}"
                                  :app_payments="{value: (compiled_daily_report.app_payments).toFixed(2), percentage: (compiled_daily_report.app_payments / compiled_daily_report.total_gross * 100).toFixed(2)}"
                          ></report-payment-type-table>

                          <h5 class="font-weight-bold mt-4">After Adjustment</h5>

                          <report-payment-type-table
                              :cash="{value: ((parseFloat(compiled_daily_report.cash) + parseFloat(compiled_daily_report.cash_adjustment))).toFixed(2), percentage: ((parseFloat(compiled_daily_report.cash) + parseFloat(compiled_daily_report.cash_adjustment)) / compiled_daily_report.total_gross * 100).toFixed(2)}"
                              :card="{value: ((parseFloat(compiled_daily_report.card) + parseFloat(compiled_daily_report.card_adjustment))).toFixed(2), percentage: ((parseFloat(compiled_daily_report.card) + parseFloat(compiled_daily_report.card_adjustment)) / compiled_daily_report.total_gross * 100).toFixed(2)}"
                              :credit_accounts="{value: (compiled_daily_report.credit_accounts).toFixed(2), percentage: (compiled_daily_report.credit_accounts / compiled_daily_report.total_gross * 100).toFixed(2)}"
                              :app_payments="{value: (compiled_daily_report.app_payments).toFixed(2), percentage: (compiled_daily_report.app_payments / compiled_daily_report.total_gross * 100).toFixed(2)}"
                          ></report-payment-type-table>
                        </div>
                    </div>
                </div>

                <hr class="dashboard-page--content-spacer">
            </div>

            <!--Customer Stats-->
            <div class="dashboard-page--component">
                <div class="row">
                    <div class="col-12">
                        <h4 class="dashboard-page--component-title">{{avg ? 'Customer Stats' : 'Customer Stats'}}</h4>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <report-generic-table
                                :data="[
								{title: 'Active App Customers', value: compiled_daily_report.num_users},
								{title: 'Transactions using app', value: compiled_daily_report.total_transactions ? (compiled_daily_report.loyalty_redeemed / compiled_daily_report.total_transactions * 100).toFixed(2) + '%' : '0%'},
								{title: 'Estimated total active customers', value: numberFormatWCurrencyCheck(customer_details.estimated_active)},
								{title: 'Trans. ordered via App', value: compiled_daily_report.total_transactions ? (compiled_daily_report.app_ordered_transactions / compiled_daily_report.total_transactions * 100).toFixed(2) + '%' : '0%'},

								{title: '', value: ''},
								{title: '', value: ''},

								{title: 'New App customers', value: numberFormatWCurrencyCheck(customer_details.new_app_customers)},
								{title: 'Total loyalty stamps issued', value: Math.round(compiled_daily_report.loyalty_stamps_issued)},
								{title: 'Reactivated customers', value: numberFormatWCurrencyCheck(customer_details.reactivated_customers)},
								{title: 'Total loyalty cards redeemed', value: Math.round(compiled_daily_report.loyalty_redeemed)},
								{title: 'New inactive customers', value: numberFormatWCurrencyCheck(customer_details.inactive_customers)},
								{multiple: true, data: [{title: 'Drinks', value: Math.round(compiled_daily_report.loyalty_redeemed_drink)}, {title: 'Food', value: Math.round(compiled_daily_report.loyalty_redeemed_food)}]},

								// {title: 'Average App Order Gross', value: compiled_daily_report.app_ordered_gross},
								// {title: 'Average App Order GP', value: compiled_daily_report.app_ordered_gp},
							]"
                        ></report-generic-table>
                    </div>
                </div>

                <hr class="dashboard-page--content-spacer">
            </div>

            <!--Coffee Stops-->
            <div class="dashboard-page--component">
                <div class="row">
                    <div class="col-12">
                        <h4 class="dashboard-page--component-title">{{avg ? 'Average Coffee Stops & Transactions' : 'Total Coffee Stops & Transactions'}}</h4>
                    </div>
                </div>

                <report-generic-table
                        :data="[
						{title: avg ? 'Coffee stops per day' : 'Coffee stops attended', value: Math.round(compiled_daily_report.total_stops)},
						{title: avg ? 'Transactions per day' : 'Total transactions', value: Math.round(compiled_daily_report.total_transactions)},
						{title: avg ? 'Av. GP per coffee stop' : 'GP per coffee stop', value: '£' + (compiled_daily_report.total_gross_profit / compiled_daily_report.total_stops).toFixed(2), colour: '#777'},
						{title: avg ? 'Av. Transactions per stop' : 'Transactions per stop', value: format(compiled_daily_report.total_transactions / compiled_daily_report.total_stops, '1dp'), ignore_formatter: true, colour: '#777'},
						{title: avg ? 'Av. Transaction gross amount' : 'Transaction gross amount', value: '£' + (compiled_daily_report.total_transactions ? (compiled_daily_report.total_gross / compiled_daily_report.total_transactions).toFixed(2) : 0), colour: '#777'},
						{title: 'Transactions per hour trading', value: format(TransactionsPerHourTrading, '1dp'), ignore_formatter: true, colour: '#777'},
						{title: avg ? 'Av. transaction GP' : 'Transaction GP', value: '£' + (compiled_daily_report.total_transactions ? (compiled_daily_report.total_gross_profit / compiled_daily_report.total_transactions).toFixed(2) : 0), colour: '#777'},
						{title: 'Transactions per hour serving', value: format(TransactionsPerHourServing, '1dp'), ignore_formatter: true, colour: '#777'},
					]"
                ></report-generic-table>

                <hr class="dashboard-page--content-spacer">
            </div>

            <!--Coffee Stop Stats-->
            <div class="dashboard-page--component mt-4">
                <div class="row">
                    <div class="col-12">
                        <stop-stats-page
                                :colors="colors"
                                :routes="routes"
                                :franchise="franchise"

                                :default_selected_routes="route_selected"
                                :default_from_date="from_date"
                                :default_to_date="from_date"

                                :date_ranges="date_ranges"

                                :single_day_component="true"

                                :traffic_light_colours="traffic_light_colours"
                                :traffic_light_labels="traffic_light_labels"

                                :load_url="stop_stats_url"
                        ></stop-stats-page>
                    </div>
                </div>
            </div>

            <!--Cumulative GP over day-->
            <div class="dashboard-page--component">
                <div class="row">
                    <div class="col-12 mb-5">
                        <h4 class="dashboard-page--component-title">Cumulative GP Graph</h4>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <bar-chart
                                :bar_name="'Cumulative GP Over Day'"
                                bar_color="#5fa3e5"
                                prefix="£"
                                :bar_data="GPPerHour"
                        ></bar-chart>
                    </div>
                </div>

                <hr class="dashboard-page--content-spacer">
            </div>

            <!--Product Stats-->
            <div class="dashboard-page--component">
                <div class="row">
                    <div class="col-12">
                        <h4 class="dashboard-page--component-title">{{avg ? 'Average Product Stats' : 'Total Product Stats'}}</h4>
                    </div>
                </div>

                <report-generic-table
                        :data="[
						{title: avg ? 'Total Gourmet Drinks p/day' : 'Total Gourmet Drinks', value: num_hot_drinks + num_blended_drinks},
						{title: avg ? 'Total food items per day' : 'Total Food Items', value: num_chilled_food + num_hot_food},
						{title: 'Hot Drinks', value: num_hot_drinks},
						{title: 'Hot Food', value: num_hot_food},
						{title: 'Blended Drinks', value: num_blended_drinks},
						{title: 'Chilled Food', value: num_chilled_food},
					]"
                ></report-generic-table>

                <div class="row mt-4">
                    <div class="col-12">
                        <h4 class="font-weight-bold text-center">Percentage of GP</h4>
                    </div>
                    <div class="col-12 offset-sm-2 col-sm-8 offset-md-3 col-md-6">
                        <div class="row">
                            <div class="col-3">
                                <h5 class="font-weight-bold text-center">Gourmet Drinks</h5>
                            </div>
                            <div class="col-1">:</div>
                            <div class="col-3">
                                <h5 class="font-weight-bold text-center">Hot & Chilled Food</h5>
                            </div>
                            <div class="col-1">:</div>
                            <div class="col-3">
                                <h5 class="font-weight-bold text-center">Other</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 offset-sm-2 col-sm-8 offset-md-3 col-md-6">
                        <div class="row">
                            <div class="col-3">
                                <h5 class="font-weight-bold text-center">{{numberFormatWCurrencyCheck(GPGourmetDrinks + '%')}}</h5>
                            </div>
                            <div class="col-1">:</div>
                            <div class="col-3">
                                <h5 class="font-weight-bold text-center">{{numberFormatWCurrencyCheck(GPFood + '%')}}</h5>
                            </div>
                            <div class="col-1">:</div>
                            <div class="col-3">
                                <h5 class="font-weight-bold text-center">{{numberFormatWCurrencyCheck(GPOther + '%')}}</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="dashboard-page--content-spacer">
            </div>

            <!--Average Sandwich Platter Stats-->
            <div class="dashboard-page--component">
                <div class="row">
                    <div class="col-12">
                        <h4 class="dashboard-page--component-title">{{avg ? 'Average Sandwich Platter Stats' : 'Sandwich Platter Stats'}}</h4>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <report-generic-table
                                :data="[
								{title: 'Sandwich Platters p/week', value: Math.round(compiled_daily_report.sandwich_platters_total_sold / daily_report.num_weeks)},
								{title: 'GP per week', value: '£' + (compiled_daily_report.sandwich_platters_gp / daily_report.num_weeks)},
								{title: 'Platters per order', value: compiled_daily_report.sandwich_platters_total_sold / (compiled_daily_report.sandwich_platters_transactions == 0 ? 1 : compiled_daily_report.sandwich_platters_transactions)},
								{title: 'GP per order', value: '£' + compiled_daily_report.sandwich_platters_gp / (compiled_daily_report.sandwich_platters_transactions == 0 ? 1 : compiled_daily_report.sandwich_platters_transactions)},
							]"
                        ></report-generic-table>
                    </div>
                </div>

                <hr class="dashboard-page--content-spacer">
            </div>

            <!--Sales by Product Group-->
            <div class="dashboard-page--component mt-4">
                <div class="row">
                    <div class="col-12">
                        <sales-by-product-group
                                :load_url="product_sales_url"
                                :routes="routes"

                                :route_selected="route_selected"
                                :to_date="from_date"
                                :from_date="from_date"

                                :autoload="true"
                        ></sales-by-product-group>
                    </div>
                </div>
            </div>
        </div>

        <!--No data to show-->
        <div v-else>
            <h2>No results found</h2>
        </div>
    </div>
</template>

<script>
    import PieChart from "../components/reporting/PieChart";
    import BarChart from "../components/reporting/BarChart";
    import { SelfBuildingSquareSpinner  } from 'epic-spinners'
    import ReportTimingTable from "../components/reporting/ReportTimingTable";
    import ReportPaymentTypeTable from "../components/reporting/ReportPaymentTypeTable";
    import ReportGenericTable from "../components/reporting/ReportGenericTable";
    import ReportNavBar from "../components/reporting/ReportNavBar";
    import SalesByProductGroup from "../components/reporting/SalesByProductGroup";
    export default {
        name: "SingleDayReportsPage",
        components: {
            SalesByProductGroup,
            ReportNavBar,
            ReportGenericTable, ReportPaymentTypeTable, ReportTimingTable, PieChart, SelfBuildingSquareSpinner, BarChart},
        props: [
            'colors',
            'routes',
            'franchise',

            'start_date',
            'end_date',

            'load_url',
            'product_sales_url',
            'stop_stats_url',
            'has_open_route',
            'date_ranges',

            'traffic_light_labels',
            'traffic_light_colours'
        ],
        data(){
            return {
                loading: false,
                reload: false,

                avg: false,

                daily_report: null,
                site_sale_stats: null,
                sales_product_group: null,
                customer_details: null,
                hourly_data: null,

                route_selected: [],
                from_date: null,
                to_date: null,
            }
        },

        methods: {
            loadData(){
                let _this = this;

                if (_this.loading){
                    _this.reload = true;
                    return;
                }

                _this.loading = true;

                let _routes = null;
                _routes = [_this.routes[_this.route_selected].id];

                axios.post(_this.load_url, {
                    route: _routes,
                    from_date: _this.from_date,
                })
                    .then(function (response){
                        _this.loading = false;

                        if (_this.reload){
                            _this.reload = false;
                            _this.loadData();
                            return;
                        }

                        _this.daily_report = response.data.daily_report;
                        _this.sales_product_group = response.data.sales_product_group;
                        _this.customer_details = response.data.customer_details;
                        _this.hourly_data = response.data.hourly_data;
                    })
                    .catch(function (e){

                    });
            },


            getHumanTime(seconds){
                let date = moment.duration(Math.round(seconds) * 1000);
                return Math.round(date.hours()) + ' h ' + (date.minutes() > 9 ? date.minutes() : '0' + date.minutes()) + ' m';
            },

            getSalesProductGroupPieData(){
                let data = [], _this = this;
                _this.sales_product_group.forEach(function (spg){
                    data.push({
                        value: _this.avg ? spg._avg.gross_profit : spg.gross_profit,
                        name:  _this.avg ? spg._avg.product_group : spg.product_group
                    });
                });
                return data;
            },

            getProductGroupByName(name, use_avg = true){
                for (let i = 0; i < this.sales_product_group.length; i ++){
                    if (this.sales_product_group[i].product_group === name){
                        return this.avg && use_avg ?  this.sales_product_group[i]._avg : this.sales_product_group[i];
                    }
                }

                return null;
            }
        },

        computed: {
            routeName(){
                if (this.routes && this.routes[this.route_selected]){
                    return this.routes[this.route_selected].name || 'Ad Hoc';
                }

                return 'n/a';
            },

            compiled_daily_report(){
                return this.avg ? this.daily_report._avg : this.daily_report;
            },


            GPperHourTrading(){
                return (this.compiled_daily_report.total_time !== 0
                    ? this.compiled_daily_report.total_gross_profit / (this.compiled_daily_report.total_time / 60 / 60)
                    : 0).toFixed(2);
            },
            GPperHourServing(){
                return ((this.compiled_daily_report.total_time - this.compiled_daily_report.total_time_driving) !== 0
                    ? this.compiled_daily_report.total_gross_profit / ((this.compiled_daily_report.total_time - this.compiled_daily_report.total_time_driving) / 60 / 60)
                    : 0).toFixed(2);
            },
            TransactionsPerHourServing(){
                return ((this.compiled_daily_report.total_time - this.compiled_daily_report.total_time_driving)  !== 0
                    ? this.compiled_daily_report.total_transactions / ((this.compiled_daily_report.total_time - this.compiled_daily_report.total_time_driving) / 60 / 60)
                    : 0)
            },
            TransactionsPerHourTrading(){
                return (this.compiled_daily_report.total_time !== 0
                    ? this.compiled_daily_report.total_transactions / (this.compiled_daily_report.total_time / 60 / 60)
                    : 0)
            },
            GPGourmetDrinks(){
                if (!this.daily_report.total_gross_profit) return 0;

                let gp = parseFloat(this.getProductGroupByName('Hot Drinks', false).gross_profit)
                         + parseFloat(this.getProductGroupByName('Blended Drinks', false).gross_profit);
                return (gp / this.daily_report.total_gross_profit * 100).toFixed(2);
            },
            GPFood(){
                if (!this.daily_report.total_gross_profit) return 0;

                let gp = parseFloat(this.getProductGroupByName('Chilled Food', false).gross_profit) + parseFloat(this.getProductGroupByName('Hot Food', false).gross_profit);
                return (gp / this.daily_report.total_gross_profit * 100).toFixed(2);
            },
            GPOther(){
                if (!this.daily_report.total_gross_profit) return 0;

                let gp = 0, _this = this;

                this.sales_product_group.forEach(function (group){
                    if (group.product_group !== 'Hot Drinks' && group.product_group != 'Blended Drinks' && group.product_group !== 'Chilled Food' && group.product_group !== 'Hot Food'){
                        gp +=  parseFloat(group.gross_profit);
                    }
                });

                return (gp / this.daily_report.total_gross_profit * 100).toFixed(2);
            },
            GPPerHour(){
                let data = [];
                for (let key in this.hourly_data.data){
                    if (this.hourly_data.data.hasOwnProperty(key)){
                        data.push({
                            label: key,
                            value: this.hourly_data.data[key]
                                ? this.hourly_data.data[key]
                                : null
                        })
                    }
                }
                return data;
            },

            num_hot_drinks(){
                return Math.round(this.getProductGroupByName('Hot Drinks').number_of_sales);
            },
            num_blended_drinks(){
                return Math.round(this.getProductGroupByName('Blended Drinks').number_of_sales);
            },

            num_chilled_food(){
                return Math.round(this.getProductGroupByName('Chilled Food').number_of_sales);
            },
            num_hot_food(){
                return Math.round(this.getProductGroupByName('Hot Food').number_of_sales);
            },
        },
    }
</script>
