import stringFormatter from './sorters/default';
import numberFormatter from './sorters/number';
import dateFormatter from './sorters/date';
import timeFormatter from './sorters/time';

/**
 * Sorting class to allow arrays of data to be sorted by columns based on data type
 */
export default class {
    /**
     * Setup the sorter with all the values, the default sorting key and default direction
     * @param values
     * @param key
     * @param direction
     */
    constructor(values, key, direction) {
        this.setValues(values);
        this.setKey(key);
        this.setDirection(direction);
    }

    /**
     * returns an object of all available formatters
     * @returns {{date: {checkValue: {(*=): (boolean|*), (*): boolean}, getValue: {(*=): (*), (*): (*|null)}, compare: {(*=, *=): number, (*=, *=): number}}, number: {checkValue: {(*=): *, (*): boolean}, getValue: {(*=): (number), (*): (*|null)}, compare: {(*=, *=): number, (*=, *=): number}}, string: {checkValue(*): boolean, getValue(*): (*|null), compare(*=, *=): number}, time: {checkValue: {(*=): *, (*): boolean}, getValue: {(*=): (*), (*): (*|null)}, compare: {(*=, *=): number, (*=, *=): number}}}}
     */
    valueFormatters() {
        return {
            date: new dateFormatter(),
            number: new numberFormatter(),
            time: new timeFormatter(),
            string: new stringFormatter()
        };
    }

    /**
     * Set the key to sort by
     * @param key
     */
    setKey(key) {
        this.key = key;
        return this;
    }

    /**
     * Set the direction to sort
     * @param direction
     */
    setDirection(direction) {
        this.direction = direction;
        return this;
    }

    /**
     * Set the array of values to sort
     * @param values
     */
    setValues(values) {
        this.values = values;
        return this;
    }

    /**
     * works out the required formatter based on the checkValue function inside each formatter (if true use given formatter)
     * @returns {string}
     */
    calculateDataType() {
        let type = "string";
        let found = false;
        let item;
        //some allows the loop to be broken by returning true
        this.values.some(element => {
            //find the sorting element
            item = element[this.key];
            //if the sorting element exists
            if (item != null) {
                //tries to find a valid formatter for that value
                Object.keys(this.valueFormatters()).some(formatter => {
                    //if the value matches the formatters pattern
                    if (this.valueFormatters()[formatter].checkValue(item)) {
                        type = formatter;
                        found = true;
                        return true;
                        //break the loop and set the formatter
                    }
                });

                //return whether a formatter is found
                return found;
            }
        });

        //return found formatter
        return type;
    }

    /**
     * If the formatter exists use that otherwise use string (string is default format in most cases)
     * @param dataType
     * @returns {*|{checkValue, (*): boolean, getValue, (*): (*|null), compare, (*=, *=): number}}
     */
    getValueFormatter(dataType) {
        return this.valueFormatters()[dataType] || this.valueFormatters()["string"];
    }

    /**
     * Handles the sorting by calling all relevant functions to get the sorter to use then calls the sort function with that formatter
     * @returns {*}
     */
    sort() {
        let dataType = this.calculateDataType();

        //this should not be possible but if somehow the dataType is null the array cannot be sorted so return values
        if (dataType == null) {
            return this.values;
        }

        let valueFormatter = this.getValueFormatter(dataType);

        //clone the values array
        let arr = [...this.values];
        
        //get the sorted array
        let res = this.quickSort(arr, valueFormatter);

        //reverse the array depending on direction
        return this.direction == "desc" ? res.reverse() : res;
    }

    /**
     * Actual sorting logic here, uses quickSort to format the array
     * @param arr
     * @param valueFormatter
     * @returns {T[]|*}
     */
    quickSort(arr, valueFormatter) {
        //if array length < 2 it's always in order
        if (arr.length < 2) return arr;

        //set the pivot to the middle of the array
        const pivot = arr[Math.floor(arr.length / 2)];
        let left = [];
        let equal = [];
        let right = [];

        //calls the found formatters compare method to work out which is greater (returns either 1, -1 or 0)
        for (let element of arr) {
            let formattedValue = valueFormatter.compare(element[this.key], pivot[this.key]);

            if (formattedValue == 1)
                right.push(element);
            else if (formattedValue == -1)
                left.push(element);
            else equal.push(element);
        }

        //quick sort is recursive and returns an array each time so can be concatenated with the results of another iteration of quick sort each time
        return this.quickSort(left, valueFormatter)
            .concat(equal)
            .concat(this.quickSort(right, valueFormatter));
    }
}