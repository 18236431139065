export default function(value){
    let hours = parseInt(value);

    let minutes = ((parseFloat(value).toFixed(2) - hours) / 100) * 60;
    minutes = (minutes * 100 + '').padStart(2, '0');

    if(minutes == '60') {
        hours++;
        minutes = '00';
    }

    minutes = parseFloat(minutes).toFixed(0);
    return `${hours}:${minutes}h:m`;
}