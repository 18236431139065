<template>
    <modal
        :use-btn="true"
        btn-label="Edit"
        btn-class="btn-sm btn-primary"

        :title="`Edit Notification Group ${name}`"
    >
        <div class="form-group text-left">
            <label for="name" class="form-group-label">Enter Name</label>
            <input class="form-control" :class="{'is-invalid': errors.name != undefined}" name="name" id="name" v-model="data.name">
            <div class="invalid-feedback" v-if="errors.name != undefined">{{errors.name[0]}}</div>
        </div>

        <div class="text-right">
            <button type="submit" class="btn btn-primary" @click="submit">Update</button>
        </div>
    </modal>
</template>

<script>
export default {
    name: "EditNotificationGroup",

    props: {
        url: {
            required: true,
            type: String
        },

        name: {
            required: true,
            type: String
        }
    },

    data(){
        return {
            errors: {},

            data: {
                name: ''
            }
        }
    },

    beforeMount() {
        this.data.name = this.name;
    },

    methods: {
        submit(){
            axios.patch(this.url, this.data)
                .then((response) => {
                    if(response.data.success){
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                })
        }
    }
}
</script>