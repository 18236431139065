<template>
	<div class="dashboard-page">

		<!--Top Filters Header-->
		<report-nav-bar
			:routes="routes"
			:date_ranges="date_ranges"

			:default_range="0"
			:hide_today="true"
			:hide_avg="true"

			@load="loadData"

			@avg="avg = $event"
			@route_selected="route_selected = $event"
			@to_date="to_date = $event"
			@from_date="from_date = $event"

			:has_open_route="has_open_route"
		></report-nav-bar>

		<!--Loader-->
		<self-building-square-spinner
			v-if="loading"
			class="mx-auto"
			:animation-duration="6000"
			:size="40"
			color="#ff1d5e"
		/>

		<!--Content of report-->
		<div v-else-if="daily_report" class="dashboard-page--content">
			<!--GP per Date-->
			<div class="dashboard-page--component">
				<div class="row py-4">
					<div class="col-12">
						<bar-chart
							:bar_name="'GP / day'"
							prefix="£"
							bar_color="#5fa3e5"
							:bar_data="GPPerDateGraph"
							:line_data="TargetGP"
						></bar-chart>
						<hr>
					</div>
				</div>
			</div>

			<!--Trading Time per Date-->
			<div class="dashboard-page--component">
				<div class="row py-4">
					<div class="col-12">
						<bar-chart
							:bar_name="'Trading Time / day'"
							bar_color="#5fa3e5"
							suffix="h:m"
							:bar_data="TradingPerDateGraph"
							:line_data="TargetHour"
						></bar-chart>
						<hr>
					</div>
				</div>
			</div>

			<!--GP / H per Date-->
			<div class="dashboard-page--component">
				<div class="row py-4">
					<div class="col-12">
						<bar-chart
							bar_name="GP / H Trading"
							bar_color="#5fa3e5"
							prefix="£"
							:bar_data="GPPerHourPerDateGraph"
							:line_data="TargetGPHour"
						></bar-chart>
						<hr>
					</div>
				</div>
			</div>

			<!--GP / H Serving per Date-->
			<div class="dashboard-page--component">
				<div class="row py-4">
					<div class="col-12">
						<bar-chart
							bar_name="GP / H Serving"
							bar_color="#5fa3e5"
							prefix="£"
							:bar_data="GPPerHourServingPerDateGraph"
							:line_data="TargetGPHourServing"
						></bar-chart>
						<hr>
					</div>
				</div>
			</div>

			<!--Transactions per Date-->
			<div class="dashboard-page--component">
				<div class="row py-4">
					<div class="col-12">
						<bar-chart
							:bar_name="'Transactions / day'"
							bar_color="#5fa3e5"
							:bar_data="TransactionsPerDateGraph"
						></bar-chart>
						<hr>
					</div>
				</div>
			</div>

			<!--Wastage per Week-->
			<div class="dashboard-page--component">
				<div class="row py-4">
					<div class="col-12">
						<bar-chart
								bar_name="Average Daily Wastage / week"
								bar_color="#5fa3e5"
								prefix="£"
								:bar_data="WastagePerWeek"
						></bar-chart>
						<hr>
					</div>
				</div>
			</div>

			<!--GP minus wastage per Week-->
			<div class="dashboard-page--component">
				<div class="row py-4">
					<div class="col-12">
						<bar-chart
								bar_name="Average Daily GP minus Wastage"
								bar_color="#5fa3e5"
								prefix="£"
								:bar_data="GPMinusWastagePerWeek"
						></bar-chart>
						<hr>
					</div>
				</div>
			</div>

			<!--Cumulative GP over day-->
			<div class="dashboard-page--component">
				<div class="row py-4">
					<div class="col-12">
						<bar-chart
								:bar_name="'Cumulative GP Over Day'"
								bar_color="#5fa3e5"
								prefix="£"
								:bar_data="GPPerHour"
						></bar-chart>
						<hr>
					</div>
				</div>
			</div>

			<!--Stops Per Period-->
			<div class="dashboard-page--component">
				<div class="row py-4">
					<div class="col-12">
						<bar-chart
								:bar_name="(split != 'day' ? 'Average ' : '') + 'Stops / ' + split"
								bar_color="#5fa3e5"
								:bar_data="StopsPerPeriod"
						></bar-chart>
						<hr>
					</div>
				</div>
			</div>
		</div>

		<!--No data to show-->
		<div v-else>
			<h2>No results found</h2>
		</div>
	</div>
</template>

<script>
	import PieChart from "../components/reporting/PieChart";
	import { SelfBuildingSquareSpinner  } from 'epic-spinners'
	import ReportTimingTable from "../components/reporting/ReportTimingTable";
	import ReportPaymentTypeTable from "../components/reporting/ReportPaymentTypeTable";
	import ReportGenericTable from "../components/reporting/ReportGenericTable";
	import BarChart from "../components/reporting/BarChart";
	import ReportNavBar from "../components/reporting/ReportNavBar";
	export default {
		name: "DailyReportsGraphPage",
		components: {
			ReportNavBar,
			BarChart,
			ReportGenericTable, ReportPaymentTypeTable, ReportTimingTable, PieChart, SelfBuildingSquareSpinner},
		props: [
			'colors',
			'routes',
			'franchise',

			'load_url',

			'default_from_date',
			'default_to_date',

			'date_ranges',

			'has_open_route'
		],
		data(){
			return {
				loading: false,
				reload: false,

				avg: true,

				    hourly_data: null,
				daily_report: null,
				wastage: null,
				split: null,

				route_selected: [],
				from_date: null,
				to_date: null,
			}
		},

		methods: {
			loadData(){
				let _this = this;

				if (_this.loading){
					_this.reload = true;
					return;
				}

				_this.loading = true;

				let _routes = null;
				if(_this.route_selected.length > 0){
					_routes = _this.route_selected.filter(item => item != null).map(function(item){
						return _this.routes[item].id;
					});
				}

				axios.post(_this.load_url, {
					route: _routes,
					from_date: _this.from_date,
					to_date: _this.to_date,
				})
					.then(function (response){
						_this.loading = false;

						if (_this.reload){
							_this.reload = false;
							_this.loadData();
							return;
						}

						_this.daily_report = response.data.daily_report;
						_this.wastage = response.data.wastage;
						_this.hourly_data = response.data.hourly_data;
						_this.split = response.data.split;

					})
					.catch(function (e){

					});
			},
		},

		computed: {
			routeName(){
				if (this.routes && this.routes[this.route_selected]){
					return this.routes[this.route_selected].name || 'Ad Hoc';
				}

				return 'n/a';
			},

			GPPerDateGraph(){
				let data = [];
				for (let key in this.daily_report){
					if (this.daily_report.hasOwnProperty(key)){
						data.push({
							label: key,
							value: Math.round(this.daily_report[key] ? this.daily_report[key].gp : null)
						})
					}
				}
				return data;
			},
			TradingPerDateGraph(){
				let data = [];
				for (let key in this.daily_report){
					if (this.daily_report.hasOwnProperty(key)){
						data.push({
							label: key,
							value: (this.daily_report[key] ? this.daily_report[key].trading_time / 60 / 60  : null)
						})
					}
				}
				return data;
			},
			GPPerHourPerDateGraph(){
				let data = [];
				for (let key in this.daily_report){
					if (this.daily_report.hasOwnProperty(key)){
						data.push({
							label: key,
							value: Math.round(this.daily_report[key] ? this.daily_report[key].gp_per_hr : null)
						})
					}
				}
				return data;
			},
			GPPerHourServingPerDateGraph(){
				let data = [];
				for (let key in this.daily_report){
					if (this.daily_report.hasOwnProperty(key)){
						data.push({
							label: key,
							value: Math.round(this.daily_report[key] ? this.daily_report[key].gp_per_hr_serving : null)
						})
					}
				}
				return data;
			},
			TransactionsPerDateGraph(){
				let data = [];
				for (let key in this.daily_report){
					if (this.daily_report.hasOwnProperty(key)){
						data.push({
							label: key,
							value: Math.round(this.daily_report[key] ? this.daily_report[key].transactions : null)
						})
					}
				}
				return data;
			},


			//Targets
			TargetGP(){
				let data = [];
				for (let key in this.daily_report){
					if (this.daily_report.hasOwnProperty(key)){
						data.push({
							label: key,
							value: this.daily_report[key] && this.daily_report[key].targets && this.daily_report[key].targets.gp !== null
								? this.daily_report[key].targets.gp
								: null
						})
					}
				}
				return data;
			},
			TargetHour(){
				let data = [];
				for (let key in this.daily_report){
					if (this.daily_report.hasOwnProperty(key)){
						data.push({
							label: key,
							value: this.daily_report[key] && this.daily_report[key].targets && this.daily_report[key].targets.trading_time !== null
								? this.daily_report[key].targets.trading_time
								: null
						})
					}
				}
				return data;
			},
			TargetGPHour(){
				let data = [];
				for (let key in this.daily_report){
					if (this.daily_report.hasOwnProperty(key)){
						data.push({
							label: key,
							value: this.daily_report[key] && this.daily_report[key].targets && this.daily_report[key].targets.gp_h !== null
									? this.daily_report[key].targets.gp_h
									: null
						})
					}
				}
				return data;
			},
			TargetGPHourServing(){
				let data = [];
				for (let key in this.daily_report){
					if (this.daily_report.hasOwnProperty(key)){
						data.push({
							label: key,
							value: this.daily_report[key] && this.daily_report[key].targets && this.daily_report[key].targets.gp_h_serving !== null
									? this.daily_report[key].targets.gp_h_serving
									: null
						})
					}
				}
				return data;
			},
			WastagePerWeek(){
				let data = [];
				for (let key in this.wastage){
					if (this.wastage.hasOwnProperty(key)){
						data.push({
							label: key,
							value: this.wastage[key] && this.wastage[key].wastage !== null
									? this.wastage[key].wastage
									: null
						})
					}
				}

				return data;
			},
			GPMinusWastagePerWeek(){
				let data = [];
				for (let key in this.wastage){
					if (this.wastage.hasOwnProperty(key)){
						data.push({
							label: key,
							value: this.wastage[key] && this.wastage[key].gp_minus_wastage !== null
									? this.wastage[key].gp_minus_wastage
									: null
						})
					}
				}
				return data;
			},
			GPPerHour(){
				let data = [];
				for (let key in this.hourly_data.data){
					if (this.hourly_data.data.hasOwnProperty(key)){
						data.push({
							label: key,
							value: this.hourly_data.data[key]
									? this.hourly_data.data[key]
									: null
						})
					}
				}
				return data;
			},
			StopsPerPeriod(){
				let data = [];
				for (let key in this.daily_report){
					if (this.daily_report.hasOwnProperty(key)){
						data.push({
							label: key,
							value: this.daily_report[key] && this.daily_report[key].stop_count
									? this.daily_report[key].stop_count
									: null
						})
					}
				}
				return data;
			}
		},
	}
</script>