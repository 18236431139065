<template>
	<div class="chart-wrapper position-relative">
		<div :id="'bar-chart-' + _uid" v-bind:class="no_margin ? '' : 'graph-position'" :style="{width: width ? width : '100%', height: currentHeight ? currentHeight + 'px' : '330px'}"></div>
		<button class="position-absolute btn btn-primary" @click="expand">+</button>
	</div>
</template>

<script>
	export default {
		name: "BarChart",
		props: [
			'width',
			'height',

			'title',
			'bar_name',
			'bar_secondary_name',
			'line_name',

			'hide_values',
			'no_margin',

			'prefix',
			'suffix',

			'bar_color',
			'bar_secondary_color',
			'line_color',

			'bar_data',
			'bar_secondary_data',
			'line_data',
		],

		data(){
			return {
				pie: null,
				isExpanded: false,
				currentHeight: null,
				defaultHeight: null,
				config: {
					tooltip : {
						trigger: 'item',
						formatter: (params, ticket, callback) => {
							return params.name + ': ' + this.numberFormatWCurrencyCheck((this.prefix ? this.prefix : '') + params.value + (this.suffix ? this.suffix : ''), { minimumFractionDigits: 0, maximumFractionDigits: 2 });
						}
					},
					legend: {
						data:['text1','text2']
					},
					xAxis: [
						{
							type: 'category',
							data: [],
						}
					],
					yAxis: [
						{
							type: 'value',
							name: '',
							min: 0,
							axisLabel: {
								formatter: '{value}'
							}
						},
						{
							type: 'value',
							name: '',
							min: 0,
							axisLabel: {
								formatter: '{value}'
							}
						},
						{
							type: 'value',
							name: '',
							min: 0,
							axisLabel: {
								formatter: '{value}'
							}
						}
					],

					series: [
						{
							name: '',
							type: 'bar',
							stack: 'two',
							data: [

							],
						},
						{
							name: '',
							type: 'line',
							data:[

							],
						},
						{
							name: '',
							type: 'bar',
							stack: 'two',
							data: [

							]
						}
					],
				}
			}
		},

		beforeMount(){
			this.prepareData();
		},

		mounted(){
			this.setup();
		},

		methods: {
			expand(){
				this.currentHeight = this.isExpanded ? this.defaultHeight : parseFloat(this.defaultHeight) * 2;
				this.pie.resize({height: this.currentHeight});
				this.isExpanded = !this.isExpanded;
			},
			prepareData(){
				this.defaultHeight = this.height == undefined ? 330 : this.height;
				this.currentHeight = this.defaultHeight;

				if (this.title){
					this.config.title = {
						text: this.title,
						x: 'center'
					};
				}

				this.config.yAxis[0].name = this.bar_name || '';
				this.config.yAxis[0].axisLabel.formatter = (value, index) => {
					return (this.prefix || '') + `${value} ` + (this.suffix || '');
				};
				this.config.series[0].name = this.bar_name || '';
				this.config.series[0].data = this.bar_data || [];
				this.config.series[0].color = this.bar_color || ['#ae1648'];
				this.config.xAxis[0].data = [];

				if (this.bar_data){
					let _this = this;
					_this.bar_data.forEach(function (bar){
						_this.config.xAxis[0].data.push(bar.label.replace(' ', '\n'));
					});
				}

				if (this.bar_secondary_data){
					this.config.yAxis[2].axisLabel.formatter = (this.prefix || '') + '{value} ' + (this.suffix || '');
					this.config.series[2].name = this.bar_secondary_name || '';
					this.config.series[2].data = this.bar_secondary_data || [];
					this.config.series[2].color = this.bar_secondary_color || ['#ff8500'];
				}


				this.config.series[1].name = this.line_name || '';
				this.config.series[1].data = this.line_data || [];
				this.config.series[1].color = this.line_color || ['#05c1a6'];

				if (this.suffix || this.prefix){
					let self = this;
					this.config.tooltip.formatter = function(params, ticket, callback){
						return params.name + ': ' + self.numberFormatWCurrencyCheck((self.prefix ? self.prefix : '') + params.value + (self.suffix ? self.suffix : ''));
					}
				}
			},
			setup(){
				this.pie = echarts.init(document.getElementById('bar-chart-' + this._uid));
				this.pie.setOption(this.config);
			}
		},

		watch: {
			bar_data(){
				this.prepareData();
				this.pie.setOption(this.config);
			}
		}
	}
</script>