<template>
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <div class="row">
                    <div class="col-8">
                        <label for="name">Event Name</label>
                        <input type="text" class="form-control" name="name" id="name" aria-describedby="name-help" v-model="event.name" required>
                        <div class="invalid-feedback" v-if="errors.name">
                            {{errors.name}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-8">
                        <label for="rapos">Uses RAPOS?</label>
                        <select class="form-control" id="rapos" name="rapos" required v-model="rapos" :disabled="prefill.rapos != 2">
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="rapos" v-show="rapos == 1">
                <div class="form-group">
                    <div class="row">
                        <div class="col-8">
                            <label for="type">Event Type</label>
                            <select class="form-control" id="type" name="type" v-model="type" :disabled="prefill.rapos != 2" :required="rapos == 1">
                                <option value="" disabled selected>Select Type</option>
                                <option v-for="option in options" v-bind:value="option">
                                    {{ option }}
                                </option>
                            </select>
                            <div class="invalid-feedback" v-if="errors.type">
                                {{errors.type}}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="type == 'One Off'">

                </div>
                <div v-show="type == 'Recurring'">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-8">
                                <label for="type">Event Frequency</label>
                                <select class="form-control" id="frequency" name="frequency" v-model="event.frequency" :required="type == 'Recurring'">

                                    <option value="" disabled selected>Select Frequency</option>
                                    <option value="0">Weekly</option>
                                    <option value="1">Fortnightly</option>
                                </select>
                                <div class="invalid-feedback" v-if="errors.type">
                                    {{errors.type}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-8">
                                <label for="type">First Date</label>
                                <input class="form-control w-100" type="search" name="recurring_first_date" placeholder="Select Date:" aria-label="Select Date:" id="flatpickme1" :required="type == 'Recurring'">
                                <div class="invalid-feedback" v-if="errors.recurring_first_date">
                                    {{errors.recurring_first_date}}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div v-show="type == 'Occasional'">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-8">
                                <label for="type">All known event dates</label>
                                <input class="form-control w-100" type="search" name="occasional_event_dates" placeholder="Select Dates:" aria-label="Select Dates:" id="flatpickme3" :required="type == 'Occasional'">
                                <div class="invalid-feedback" v-if="errors.occasional_event_dates">
                                    {{errors.occasional_event_dates}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="type == 'One Off'">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-8">
                                <label for="type">Event Date</label>
                                <input class="form-control w-100" type="search" name="one_off_event_date" placeholder="Select Date:" aria-label="Select Date:" id="flatpickme4" :required="type == 'One Off'">
                                <div class="invalid-feedback" v-if="errors.one_off_event_date">
                                    {{errors.one_off_event_date}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="type == 'One Off Multi-Day'">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-8">
                                <label for="type">Event Date Range</label>
                                <input class="form-control w-100" type="search" name="one_off_event_dates" placeholder="Select Dates:" aria-label="Select Dates:" id="flatpickme5" :required="type == 'One Off Multi-Day'">
                                <div class="invalid-feedback" v-if="errors.one_off_event_dates">
                                    {{errors.one_off_event_dates}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </div>

            <div class="nonrapos" v-show="rapos === '0'">
                <div class="form-group">
                    <div class="row">
                        <div class="col-8">
                            <label for="type">Event Date</label>
                            <input class="form-control w-100" name="nonrapos_date" type="search" placeholder="Select Date:" aria-label="Select Date:" id="flatpickme2" :required="rapos == '0'">
                            <div class="invalid-feedback" v-if="errors.type">
                                {{errors.type}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-8">
                            <label for="non_rapos_profit">Total Profit</label>
                            <input type="text" class="form-control" name="non_rapos_profit" id="non_rapos_profit" aria-describedby="name-help" v-model="event.nonrapos_profit" :required="rapos == '0'">
                            <div class="invalid-feedback" v-if="errors.non_rapos_proift">
                                {{errors.non_rapos_profit}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6" v-show="type != 0">
            <div class="form-group">
                <div class="row">
                    <div class="col-8">
                        <label for="extra_costs">Extra Costs (£) - Editable on instance</label>
                        <input type="text" class="form-control" name="extra_costs" id="extra_costs" aria-describedby="name-help" v-model="event.extra_costs" :required="type != '0'">
                        <div class="invalid-feedback" v-if="errors.extra_costs">
                            {{errors.extra_costs}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {

        props: ["errors", "options", "event","prefill"],

        data() {
            return {
                type: 0,
                rapos: 2
            };
        },
        watch:{
            rapos: function () {
                if(this.rapos == 0){
                    this.type = 0;
                }
            }
        },
        mounted() {
            console.log('Component mounted.');
            this.type =  this.prefill.type;
            this.rapos = this.prefill.rapos;
                flatpickr("#flatpickme1", {
                dateFormat: "Y-m-d",
                altInput: true,
                altFormat: "F j, Y",
                defaultDate: this.prefill.dates[1]
            });
            flatpickr('#flatpickme2', {
                dateFormat: "Y-m-d",
                altInput: true,
                altFormat: "F j, Y",
                defaultDate: this.prefill.dates[2]
            });
            flatpickr("#flatpickme3", {
                dateFormat: "Y-m-d",
                altInput: true,
                altFormat: "F j, Y",
                mode: "multiple",
                defaultDate: this.prefill.dates[3]
            });
            flatpickr("#flatpickme4", {
                dateFormat: "Y-m-d",
                altInput: true,
                altFormat: "F j, Y",
                defaultDate: this.prefill.dates[4]
            });
            flatpickr("#flatpickme5", {
                dateFormat: "Y-m-d",
                altInput: true,
                altFormat: "F j, Y",
                mode: "range",
                defaultDate: this.prefill.dates[5]
            });

        }
    }
</script>
