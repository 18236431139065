import currency from './formatters/currency';
import percentage from './formatters/percentage';
import time from './formatters/time';
import nearest5 from "./formatters/nearest5";
import oneDP from './formatters/oneDP';

/**
 * Formats the inputted value into a given format
 * @param value
 * @param forceType
 * @param locale
 */
export default function(value, forceType = null, locale = 'en-GB') {

    /**
     * Finds the formatter needed for the inputted value
     * @param value
     * @param forceType
     */
    const format = (value, forceType) => getFormatter(forceType)(value, locale);

    /**
     * returns the formatter selected if it exists if not it returns the default
     * @param forceType
     */
    const getFormatter = (forceType) => availableFormatters[forceType] || defaultFormatter;

    /**
     * Default formatter has no decimal places so format as that
     * @param value
     * @param locale
     */
    const defaultFormatter = (value, locale) =>
        Intl.NumberFormat(locale, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(value);

    /**
     * An object of all available formatters these must all return a single value
     */
    const availableFormatters = {
        currency: currency,
        percentage: percentage,
        time: time,
        nearest5: nearest5,
        "1dp": oneDP,
    };

    /**
     * Gets the formatted value
     */
    return format(value, forceType);
}