<template>
    <div class="row">
        <div class="col-12 col-md-3 product-information">
            <h4 class="top-padding">Create Notification Set</h4>

            <div class="form-group top-padding">
                <label class="control-label" for="name">Name</label>
                <input type="text" class="form-control" :class="{'is-invalid': errors.name !== undefined}" v-model="data.name" id="name" name="name">
                <div class="invalid-feedback" v-if="errors.name !== undefined">{{errors.name[0]}}</div>
            </div>

            <button class="btn btn-primary mb-3" @click="saveNotificationSet">Save Notification Set</button>
        </div>
        <div class="col-12 col-md-9 bg-white">
            <h4 class="top-padding">Add Notifications</h4>

            <ul class="list-group top-padding mb-3">
                <draggable v-model="data.notifications" group="notifications" @start="drag=true" @end="drag=false">
                    <transition-group>
                        <li class="list-group-item" v-for="(notification, index) in data.notifications" :key="`list--${index}`">
                            <div class="row">
                                <div class="col-12 col-md-5 col-lg-6 top-padding pt-md-0">
                                    <div class="form-group mb-0">
                                        <label :for="getSelectClass(index)" class="control-label">Template</label>
                                        <select :id="getSelectClass(index)" class="form-control w-100" :class="{'is-invalid': errors[`notifications.${index}.notification_id`] !== undefined}" required="required" v-model="data.notifications[index].notification_id">
                                            <option value="" disabled selected>Select Template</option>
                                            <option v-for="notificationTemplate in notificationTemplates" :value="notificationTemplate.id" :key='`list--${index}-template--${notificationTemplate.id}`'>{{notificationTemplate.title}}</option>
                                        </select>
                                        <div class="invalid-feedback" v-if="errors[`notifications.${index}.notification_id`] !== undefined">{{ errors[`notifications.${index}.notification_id`][0] }}</div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4 top-padding pt-md-0">
                                    <div class="form-group mb-0">
                                        <label :for="`list--${index}-number`" class="control-label">Days Before Next Notification</label>
                                        <input :id="`list--${index}-number`" type="number" class="form-control w-100" :class="{'is-invalid': errors[`notifications.${index}.days_between`] !== undefined}" v-model="data.notifications[index].days_between">
                                        <div class="invalid-feedback" v-if="errors[`notifications.${index}.days_between`] !== undefined">{{ errors[`notifications.${index}.days_between`][0] }}</div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-3 col-lg-2 top-padding pt-md-0 d-flex align-items-end">
                                    <button class="btn btn-danger w-100" @click="removeRow(index)">Remove Notification</button>
                                </div>
                            </div>
                        </li>
                    </transition-group>
                </draggable>
                <li class="list-group-item" :class="{'is-invalid': errors.notifications !== undefined}">
                    <button class="btn btn-primary" @click="addNewRow">Add Another Notification</button>
                </li>
                <div class="invalid-feedback" v-if="errors.notifications !== undefined">{{ errors.notifications[0] }}</div>
            </ul>
        </div>
    </div>
</template>
<script>
    import draggable from 'vuedraggable';
    export default {
        components: {
            draggable,
        },

        props: {
            notificationTemplates: {
                type: Array,
                required: true
            },

            notificationSet: {
                type: Object
            },

            copy: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                data: {
                    name: null,

                    notifications: []
                },

                errors: {}
            }
        },

        beforeMount(){
            if(this.notificationSet !== undefined){
                this.data.name          = this.notificationSet.name;
                this.data.notifications = Object.values(this.notificationSet.notifications)
                                                .map((item, index) => {
                                                    return {
                                                        id: index,
                                                        notification_id: item.id,
                                                        days_between  : item.pivot.days_between
                                                    };
                                                });
            }
        },

        methods: {
            addNewRow(){
                if(this.errors.notifications !== undefined){
                    this.$delete(this.errors, 'notifications');
                }

                this.data.notifications.push({
                    days_between: 1,
                    template_id: null
                });
            },

            removeRow(index){
                this.data.notifications.splice(index, 1);
            },

            saveNotificationSet(){
                this.errors = {};

                axios({
                    method: (this.notificationSet !== undefined && !this.copy)
                        ? 'PUT'
                        : 'POST',

                    url: (this.notificationSet !== undefined && !this.copy)
                        ? `/admin/default_notifications/sets/${this.notificationSet.id}`
                        : '/admin/default_notifications/sets',

                    data: this.data
                }).then((response) => {
                    window.location.href = (this.notificationSet !== undefined && !this.copy)
                        ? "/admin/default_notifications/sets?updated"
                        : "/admin/default_notifications/sets?created";
                }).catch((error) => {
                    this.errors = error.response.data.errors;
                })
            },

            getSelectClass(index){
                return `list--${index}-select`
                    + (
                        this.errors[`notifications.${index}.notification_id`] !== undefined
                            ? '-has-error'
                            : ''
                    );
            }
        }
    }
</script>
