<template>
    <input type="text" class="form-control" v-model="currentValue">
</template>

<script>
    export default {
        name: "Flatpickr",

        props: {
            config: {
                type: Object,
                default: () => ({
                    enableTime: true,
                    dateFormat: "Y-m-d H:i:S"
                })
            },

            value: {
                type: String,
                default: null
            }
        },

        data(){
            return {
                instance: false,

                currentValue: ''
            }
        },

        mounted(){
            if(this.instance !== false){
                return
            }

            this.instance = $(this.$el).flatpickr({...this.config});
        },

        watch: {
            value(newValue) {
                if(newValue !== this.currentValue && this.instance !== false){
                    this.instance.setDate(newValue);
                }
            },

            currentValue(value) {
                this.$emit('input', value);
            }
        },

        beforeDestroy() {
            this.instance.destroy();
            this.instance = false;
        }
    }
</script>
