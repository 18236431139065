var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-page"},[(_vm.single_day_component == undefined)?_c('report-nav-bar',{attrs:{"routes":_vm.routes,"date_ranges":_vm.date_ranges,"default_range":0,"hide_today":true,"hide_avg":true,"show_active_status":true},on:{"load":_vm.loadData,"active_status":function($event){_vm.active_status = $event},"route_selected":function($event){_vm.route_selected = $event},"to_date":function($event){_vm.to_date = $event},"from_date":function($event){_vm.from_date = $event}}}):_vm._e(),_vm._v(" "),(_vm.loading)?_c('self-building-square-spinner',{staticClass:"mx-auto",attrs:{"animation-duration":6000,"size":40,"color":"#ff1d5e"}}):(_vm.stop_stats)?_c('div',{staticClass:"dashboard-page--content"},[(_vm.single_day_component == undefined)?_c('div',{staticClass:"dashboard-page--component"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h4',{staticClass:"dashboard-page--component-title"},[_vm._v("Overview")])])])]):_vm._e(),_vm._v(" "),(_vm.single_day_component == undefined)?_c('report-generic-table',{attrs:{"data":[
						{title: 'Starting no. of coffee stops', value: _vm.stop_stats.starting_stops},
						{title: 'No. of coffee stops dropped', value: _vm.stop_stats.stops_dropped},
						{title: 'Ending no. of coffee stops', value: _vm.stop_stats.ending_stops},
						{title: 'No. of coffee stops added', value: _vm.stop_stats.stops_added},
						{title: '', value: ''},
						{title: '', value: ''},
						{title: 'Av. coffee stops per day', value: _vm.stop_stats.avg_stops_day},
						{title: 'Transactions per coffee stop', value: _vm.stop_stats.avg_transactions_per_stop},
						{title: 'Av. GP per coffee stop', value: '£' + (_vm.stop_stats.avg_gp_per_stop)},
						{title: 'Av. transaction gross amount', value: '£' + _vm.stop_stats.avg_transactions_gross} ]}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"dashboard-page--component"},[_c('div',{staticClass:"row pt-4"},[_c('div',{staticClass:"col-12 text-left"},[_c('h4',{staticClass:"dashboard-page--component-title"},[_vm._v("Coffee Stop Statistics")])]),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('site-sale-stats-table',{attrs:{"traffic_light_colours":_vm.traffic_light_colours,"traffic_light_labels":_vm.traffic_light_labels,"site_sale_stats":_vm.site_sale_stats,"active_status":_vm.active_status,"route_selected":_vm.route_selected,"min_max":_vm.min_max,"single_day_component":_vm.single_day_component,"franchise":_vm.franchise}})],1)])])],1):_c('div',[_c('h2',[_vm._v("No results found")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }