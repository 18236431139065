<template>
	<div class="dashboard-page--header">
		<div class="row">
			<div class="col-4">
				<h4 class="dashboard-page--header-title d-block w-100">{{routeName}}</h4>
			</div>
			<div class="col-8 d-flex justify-content-end">
				<div class="my-auto" v-if="only_single_date == undefined">
					<span>Choose Period:</span>
				</div>

				<div class="input-date">
					<label class="input-date--label" for="datepicker_from">
						{{only_single_date == undefined ? 'From:' : 'Choose Date:'}}
					</label>
					<div class="input-date--picker_wrapper">
						<input @change="setFlatPickr(false)" id="datepicker_from" v-model="from_date">
					</div>
					<div class="input-date--img">
						<img :src="asset('/img/calendar.svg')" alt="" />
					</div>
				</div>

				<div class="input-date" v-if="only_single_date == undefined">
					<label class="input-date--label" for="datepicker_to">To: </label>
					<div class="input-date--picker_wrapper">
						<input @change="setFlatPickr(false)" id="datepicker_to" v-model="to_date">
					</div>
					<div class="input-date--img">
						<img :src="asset('/img/calendar.svg')" alt="" />
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-6">
				<div class="d-inline">
					<label style="font-weight: 600" for="route-select">{{ isEvent ? 'Recurring Event' : 'Route' }}</label>
					<select id="route-select" class="form-control d-inline mr-2" style="width: auto" v-model="route_selected" v-bind:multiple="only_single_date == undefined">
						<option :value="null">-- Select All --</option>
						<option v-for="(route, index) in routes" :value="index">{{route.name}}</option>
					</select>
				</div>

				<div v-if="!hide_avg" class="input-toggle">
					<div class="input-toggle--item" v-on:click="avg = false" v-bind:class="!avg ? 'active' : ''">
						TOTALS
					</div>
					<div class="input-toggle--item" v-on:click="avg = true" v-bind:class="avg ? 'active' : ''">
						AVG
					</div>
				</div>

				<div v-if="show_active_status" class="input-toggle">
					<div class="input-toggle--item" v-on:click="active_status = 'active'" v-bind:class="active_status === 'active' ? 'active' : ''">
						ACTIVE
					</div>
					<div class="input-toggle--item" v-on:click="active_status = 'drop_added'" v-bind:class="active_status === 'drop_added' ? 'active' : ''">
						DROPPED AND ADDED
					</div>
					<div class="input-toggle--item" v-on:click="active_status = 'all'" v-bind:class="active_status === 'all' ? 'active' : ''">
						ALL SITES
					</div>
				</div>

				<div v-if="show_active_product_status" class="input-toggle">
					<div class="input-toggle" v-if="route_selected.length === 0 || route_selected.length === 1 && route_selected[0] === null">
						<div class="input-toggle--item" v-on:click="active_product_status = 'daily'" v-bind:class="active_product_status === 'daily' ? 'active' : ''">
							DAILY ROUNDS
						</div>
						<div class="input-toggle--item" v-on:click="active_product_status = 'events'" v-bind:class="active_product_status === 'events' ? 'active' : ''">
							EVENTS
						</div>
						<div class="input-toggle--item" v-on:click="active_product_status = 'all'" v-bind:class="active_product_status === 'all' ? 'active' : ''">
							COMBINED
						</div>
					</div>
					<div class="input-toggle" v-else>
						<div class="input-toggle--item active">
							DAILY ROUNDS
						</div>
						<div class="input-toggle--item events">
							EVENTS
						</div>
						<div class="input-toggle--item all">
							COMBINED
						</div>
					</div>
				</div>

				<div v-if="refresh_button" class="d-inline-flex ml-2">
					<button class="btn btn-primary py-1 my-auto" v-on:click="loadData">reload</button>
				</div>
				<div v-if="only_single_date" class="d-inline-flex ml-2">
					<button v-if="has_open_route" class="btn btn-primary py-1 my-auto" v-on:click="loadLiveData">live data</button>
				</div>
			</div>
			<div class="col-6 text-right" v-if="only_single_date == undefined">
				<ul class="tab-list">
					<li
						class="tab-list--item"
						v-for="(range, index) in date_ranges_reverse"
						v-on:click="range_selected = index"
						v-bind:class="range_selected === index ? 'active' : ''"
					>
						{{range.name}}
					</li>
					<li
						v-if="!hide_today"
						class="tab-list--item"
						v-on:click="range_selected = null"
						v-bind:class="range_selected === null ? 'active' : ''"
					>TODAY</li>
				</ul>
			</div>

			<div class="col-12">
				<hr/>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "ReportNavBar",
		props: [
			'routes',
			'date_ranges',

			'refresh_button',

			'default_avg',

			'start_date',
			'end_date',

			'default_range',
			'default_route_selected',

			'hide_today',
			'hide_avg',
			'show_active_status',
			'show_active_product_status',
			'only_single_date',

			'isEvent',

			'has_open_route'
		],

		data(){
			return {
				route_selected: [],
				from_date: null,
				to_date: null,

				avg: true,
				active_status: 'all',
				active_product_status: 'all',

				today: null,

				range_selected: null,
				date_ranges_reverse: null,
				debounce_timer: null,

				ignore_range_change: false,
			}
		},

		beforeMount(){
			if(this.default_avg != undefined){
				this.avg = this.default_avg;
			}

			this.today = moment().locale('en-gb').format('Y-MM-DD');
			if(this.today > this.end_date) {
				this.today = this.end_date;
			}

			if(this.start_date != undefined){
				this.from_date = this.start_date;
			} else {
				this.from_date = this.today;
			}
			this.to_date = this.today;

			if(
					this.end_date != undefined &&
					(
						this.default_range > -1 ||
						this.hide_today ||
						(this.default_route_selected != undefined && this.default_route_selected.length > 0)
					)
				)
			{
				this.ignore_range_change = true;
			}


			this.date_ranges_reverse = Object.assign([], this.date_ranges).reverse();

			if (this.default_range > -1){

				this.range_selected = (this.date_ranges.length - 1) - this.default_range;
			}
			else if (this.hide_today){
				this.range_selected = this.date_ranges.length - 1; //select the first (last)
			}

			if(this.default_route_selected != undefined){
				if (this.default_route_selected.length > 0){
					this.route_selected = this.default_route_selected;
				}
			}
		},

		mounted(){
			this.initSelect2();
			if (!(this.default_range > -1)){
				this.setFlatPickr();
			}
		},

		methods: {
			initSelect2(){
				let self = this;
				this.$nextTick(function () {
					$('#route-select')
							.select2()
							.on('change', function() {
								console.log('234');
								self.route_selected = $(this).val();
							});
				});
			},
			setFlatPickr(using_defaults = false){
				this.$nextTick(function (){
					$('#datepicker_from').flatpickr({
						altInput: true,
						altFormat: "d/m/Y",
						dateFormat: "Y-m-d",
						defaultDate: this.from_date
					});

					if(this.only_single_date == undefined) {
						if (Date.parse(this.from_date) > Date.parse(this.to_date)) {
							this.to_date = this.from_date;
						} else if (this.to_date === this.today && this.to_date !== this.from_date) {
							if (this.end_date != undefined) {
								this.to_date = moment(this.today).locale('en-gb').format('Y-MM-DD');
							} else {
								this.to_date = moment().locale('en-gb').subtract(1, 'days').format('Y-MM-DD');
							}
						}

						$('#datepicker_to').flatpickr({
							altInput: true,
							altFormat: "d/m/Y",
							dateFormat: "Y-m-d",
							defaultDate: this.to_date,
							minDate: this.from_date ? this.from_date : null,
							// maxDate: this.from_date === this.today ? 'today' : moment().locale('en-gb').subtract(1, 'days').format('Y-MM-DD')
							maxDate: this.today
						});

						// Check dates stuff
						if (Date.parse(this.from_date) === Date.parse(this.to_date)) {
							if (this.from_date === this.today) {
								this.range_selected = null; // Today
							} else if (!using_defaults) {
								this.range_selected = -1; //No range
							}
						} else if (!using_defaults) {
							this.range_selected = -1; //No range
						}
					}

					this.loadData();
				});
			},

			loadData(){
				let _this = this;
				if (_this.debounce_timer){
					clearTimeout(_this.debounce_timer);
				}

				_this.debounce_timer = setTimeout(function (){
					_this.$emit('load');
				}, 150);
			},

			loadLiveData(){
				this.from_date = this.today;
				this.setFlatPickr(true);
				this.loadData();
			}
		},

		computed: {
			routeName(){
				if (this.routes && this.routes[this.route_selected]){
					return this.routes[this.route_selected].name || 'Ad Hoc';
				}

				return this.isEvent ? '(All Events)' : '(All Routes)';
			}
		},

		watch: {
			avg(val){
				this.$emit('avg', val);
			},

			active_status(val){
				this.$emit('active_status', val);
			},

			active_product_status(val){
				this.$emit('active_product_status', val);
			},

			range_selected(val){
				if(this.only_single_date == undefined) {
					if (this.ignore_range_change) {
						this.ignore_range_change = false;
						return;
					}

					//No range is selected, no need for further action;
					if (this.range_selected === -1) {
						return;
					}

					//Today is selected
					if (this.range_selected === null) {
						this.from_date = this.today;
						this.to_date = this.today;
						this.setFlatPickr(true);
						return;
					}

					//A range was selected
					let range = this.date_ranges_reverse[this.range_selected].range;
					if (range.day) {
						range.day--;
					}

					this.from_date = moment(this.today).locale('en-gb')
							.subtract(range.day + 1, 'days')
							.subtract(range.week, 'weeks')
							.subtract(range.month, 'months')
							.subtract(range.year, 'years')
							.format('Y-MM-DD');

					this.to_date = moment(this.today).locale('en-gb').subtract(1, 'days').format('Y-MM-DD');

					this.setFlatPickr(true);
				}
			},
			route_selected(val){
				console.log('365');
				this.$emit('route_selected', val);
				this.loadData();
			},

			from_date(val){
				this.$emit('from_date', val);

				this.loadData();
			},
			to_date(val){
				if(this.only_single_date == undefined) {
					this.$emit('to_date', val);
					this.loadData();
				}
			}
		}
	}
</script>