<template>
    <div class="notification-set">
        <modal
            :use-btn="true"
            btn-class="btn-primary"
            btn-label="Use Set"

            :title="`Use Notification Set ${name}`"
        >
            <template>
                <div class="form-group">
                    <label class="control-label d-block text-left" :for="`start_date--${id}`">Start Date/Time</label>
                    <input type="text" class="form-control" :class="{'is-invalid': errors.start_date !== undefined}" v-model="data.start_date" :id="`start_date--${id}`" name="start_date">
                    <div class="invalid-feedback" v-if="errors.start_date !== undefined">{{errors.start_date[0]}}</div>
                </div>

                <notification-recipients
                    class="text-left"

                    :recipient-types="recipientTypes"
                    :routes="routes"
                    :franchise-number="franchiseNumber"

                    @recipient="data.recipient = $event"
                    @route="data.route = $event"
                    @stops="data.stop = $event"
                    @send-to-disabled-stops="data.send_to_disabled_stops = $event"
                    @send-to-only-treat-stops="data.send_to_only_treat_stops = $event"

                    :id-prefix="id"
                ></notification-recipients>
            </template>

            <template name="footer">
                <button class="btn btn-primary mt-3" @click="saveSetUsage">Use Set</button>
            </template>
        </modal>
    </div>
</template>

<script>
import NotificationRecipients from './generic/NotificationRecipients.vue';

export default {
    components: {
        NotificationRecipients
    },

    props: {
        id: {
            type: Number,
            required: true
        },

        name: {
            type: String,
            required: true
        },

        franchiseNumber: {
            type: String,
            required: true
        },

        routes: {
            required: true,
            type: Array
        },

        recipientTypes: {
            required: true,
            type: Array
        },
    },

    data(){
        return {
            isOpen: false,

            errors: {},

            data: {
                notification_set_id: null,
                start_date: null,

                recipient: null,
                route: null,
                stop: [],
                send_to_disabled_stops: 0,
                send_to_only_treat_stops: null
            }
        }
    },

    mounted(){
        this.data.notification_set_id = this.id;

        $(`#start_date--${this.id}`).flatpickr({
            enableTime: true,
            dateFormat: "Y-m-d H:i:S",
            defaultHour: 8,
            defaultMinute: 30
        });
    },

    methods: {
        saveSetUsage(){
            this.errors = {};

            axios.post(`/franchise/${this.franchiseNumber}/notifications/sets`, this.data)
                .then((response) => {
                    window.location.reload();
                }).catch((error) => {
                    this.errors = error.response.data.errors;
                })
        },

        maybeCloseModal(e){
            if(e.target.classList.contains('generic-modal__container')){
                this.isOpen = false;
            }
        }
    }
}
</script>
