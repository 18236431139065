<template>
    <div>
        <div v-for="sss in site_sale_stats_table">
            <modal
                title="Update Traffic Light"

                :value="activeModal == sss.stop_id"
                @input="activeModal = null"
            >
                <template>
                    <div class="alert alert-warning" v-if="sss.dated_dropped">This stop has been dropped, please add back to the route map to re-enable it</div>

                    <div v-else>
                        <stop-traffic-light-form
                            :submit-url="`/franchise/${franchise.franchise_number}/stops/${sss.stop_id}/traffic_lights`"
                            :colours="traffic_light_colours"
                            :use-axios="true"
                            :cancel_button="true"

                            :value="sss.traffic_light"

                            @success="activeModal = null;sss.traffic_light=$event"
                        ></stop-traffic-light-form>
                    </div>
                </template>
            </modal>
        </div>

        <table class="table table-striped table-sm text-center" v-if="single_day_component == undefined">
            <thead class="thead-dark">
            <tr>
                <table-sorting-helper rowspan="2" @sort="sort('site_name')" :sort-direction="sort_direction" :is-sorted="isSorted('site_name')">Coffee Stop</table-sorting-helper>
                <table-sorting-helper v-if="uniqueRoutes > 1" rowspan="2" @sort="sort('route_name')" :sort-direction="sort_direction" :is-sorted="isSorted('route_name')">Route</table-sorting-helper>

                <table-sorting-helper rowspan="2" @sort="sort('dated_added')" :sort-direction="sort_direction" :is-sorted="isSorted('dated_added')">Date Site Added</table-sorting-helper>

                <table-sorting-helper rowspan="2" @sort="sort('traffic_light')" :sort-direction="sort_direction" :is-sorted="isSorted('traffic_light')">Traffic Light</table-sorting-helper>

                <th scope="col" class="light-label" colspan="7">Profits</th>

                <table-sorting-helper rowspan="2" @sort="sort('num_stops')" :sort-direction="sort_direction" :is-sorted="isSorted('num_stops')">No. of Visits</table-sorting-helper>

                <th scope="col" class="light-label" colspan="2">No Sales</th>

                <table-sorting-helper rowspan="2" @sort="sort('dated_dropped')" :sort-direction="sort_direction" :is-sorted="isSorted('dated_dropped')">Date Site Dropped</table-sorting-helper>
            </tr>
            <tr>
                <table-sorting-helper @sort="sort('gross_profit')" :sort-direction="sort_direction" :is-sorted="isSorted('gross_profit')">Avg. GP</table-sorting-helper>
                <table-sorting-helper @sort="sort('gp_hour_serving')" :sort-direction="sort_direction" :is-sorted="isSorted('gp_hour_serving')">Avg. GP / Hr Serving</table-sorting-helper>
                <table-sorting-helper @sort="sort('avg_amount_transactions')" :sort-direction="sort_direction" :is-sorted="isSorted('avg_amount_transactions')">Avg. Transaction Amount</table-sorting-helper>
                <table-sorting-helper @sort="sort('time_on_site')" :sort-direction="sort_direction" :is-sorted="isSorted('time_on_site')">Avg. Time on Site</table-sorting-helper>
                <table-sorting-helper @sort="sort('transactions')" :sort-direction="sort_direction" :is-sorted="isSorted('transactions')">No. Transactions</table-sorting-helper>
                <table-sorting-helper @sort="sort('average_transactions')" :sort-direction="sort_direction" :is-sorted="isSorted('average_transactions')">Avg. Transactions</table-sorting-helper>
                <table-sorting-helper @sort="sort('percent_margin')" :sort-direction="sort_direction" :is-sorted="isSorted('percent_margin')">Percent Margin</table-sorting-helper>

                <table-sorting-helper @sort="sort('num_no_sales')" :sort-direction="sort_direction" :is-sorted="isSorted('num_no_sales')">No. of 'No Sales'</table-sorting-helper>
                <table-sorting-helper @sort="sort('percentage_no_sales')" :sort-direction="sort_direction" :is-sorted="isSorted('percentage_no_sales')">% 'No Sales Visits'</table-sorting-helper>
            </tr>
            </thead>
            <tbody>
            <tr v-for="sss in site_sale_stats_table" :class="{'dropped-row': sss.dated_dropped != null}">
                <td>{{sss.site_name}}</td>
                <td v-if="uniqueRoutes > 1">{{sss.route_name}}</td>
                <td>{{sss.dated_added || 'N/A'}}</td>
                <td>
                    <div class="traffic-light" :class="trafficLightClass(sss)" v-if="sss.traffic_light != 'N/A'" @click="activeModal = sss.stop_id" v-tooltip="trafficLightLabel(sss)"></div>

                    <span v-else>N/A</span>
                </td>
                <td class="min-max-cell" v-bind:class="{
                    'lowest': (parseFloat(sss.gross_profit) <= parseFloat(min_max.lowest_gp)),
                    'highest': (parseFloat(sss.gross_profit) >= parseFloat(min_max.highest_gp))
                 }">{{numberFormatWCurrencyCheck('£' + sss.gross_profit)}}</td>
                <td class="min-max-cell min-max-cell--light" v-bind:class="{
                    'lowest': (parseFloat(sss.gp_hour_serving) <= parseFloat(min_max.lowest_gp_hour)),
                    'highest': (parseFloat(sss.gp_hour_serving) >= parseFloat(min_max.highest_gp_hour))
                 }">{{numberFormatWCurrencyCheck('£' + sss.gp_hour_serving)}}</td>
                <td>{{numberFormatWCurrencyCheck('£' + sss.avg_amount_transactions)}}</td>
                <td>{{sss.time_on_site}}</td>
                <td>{{sss.transactions}}</td>
                <td>{{numberFormatWCurrencyCheck(sss.average_transactions, {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
                <td>{{numberFormatWCurrencyCheck(sss.percent_margin + '%', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
                <td>{{numberFormatWCurrencyCheck(sss.num_stops)}}</td>
                <td>{{numberFormatWCurrencyCheck(sss.num_no_sales)}}</td>
                <td class="min-max-cell min-max-cell--light" v-bind:class="{
                    'lowest': (parseFloat(sss.percentage_no_sales) >= parseFloat(min_max.lowest_no_sales)),
                    'highest': (parseFloat(sss.percentage_no_sales) <= parseFloat(min_max.highest_no_sales))
                 }">{{numberFormatWCurrencyCheck(sss.percentage_no_sales + '%')}}</td>
                <td>{{sss.dated_dropped}}</td>
            </tr>
            </tbody>
        </table>
        <table class="table table-striped table-sm text-center" v-else>
            <thead class="thead-dark">
                <tr>
                    <table-sorting-helper @sort="sort('site_name')" :sort-direction="sort_direction" :is-sorted="isSorted('site_name')">Coffee Stop</table-sorting-helper>
                    <table-sorting-helper @sort="sort('traffic_light')" :sort-direction="sort_direction" :is-sorted="isSorted('traffic_light')">Traffic Light</table-sorting-helper>
                    <table-sorting-helper @sort="sort('started_at')" :sort-direction="sort_direction" :is-sorted="isSorted('started_at')">Clock In</table-sorting-helper>
                    <table-sorting-helper @sort="sort('completed_at')" :sort-direction="sort_direction" :is-sorted="isSorted('completed_at')">Clock Out</table-sorting-helper>
                    <table-sorting-helper @sort="sort('time_on_site')" :sort-direction="sort_direction" :is-sorted="isSorted('time_on_site')">Time on Site</table-sorting-helper>
                    <table-sorting-helper @sort="sort('gross_sales')" :sort-direction="sort_direction" :is-sorted="isSorted('gross_sales')">Gross Sales</table-sorting-helper>
                    <table-sorting-helper @sort="sort('net_profit')" :sort-direction="sort_direction" :is-sorted="isSorted('net_profit')">Net Sales</table-sorting-helper>
                    <table-sorting-helper @sort="sort('percent_margin')" :sort-direction="sort_direction" :is-sorted="isSorted('percent_margin')">Margin (%)</table-sorting-helper>
                    <table-sorting-helper @sort="sort('gross_profit')" :sort-direction="sort_direction" :is-sorted="isSorted('gross_profit')">Gross Profit</table-sorting-helper>
                    <table-sorting-helper @sort="sort('gp_hour_serving')" :sort-direction="sort_direction" :is-sorted="isSorted('gp_hour_serving')">GP / Hr Serving</table-sorting-helper>
                </tr>
            </thead>
            <tbody>
            <tr v-for="sss in site_sale_stats_table" :class="{'dropped-row': sss.dated_dropped != null}">
                <td>{{sss.site_name}}</td>
                <td>
                    <div class="traffic-light" :class="trafficLightClass(sss)" v-if="sss.traffic_light != 'N/A'" @click="activeModal = sss.stop_id" v-tooltip="trafficLightLabel(sss)"></div>

                    <span v-else>N/A</span>
                </td>
                <td>{{getDate(sss.started_at)}}</td>
                <td>{{getDate(sss.completed_at)}}</td>
                <td>{{sss.time_on_site}}</td>
                <td>{{numberFormatWCurrencyCheck('£' + sss.gross_sales)}}</td>
                <td>{{numberFormatWCurrencyCheck('£' + sss.net_profit)}}</td>
                <td>{{numberFormatWCurrencyCheck(sss.percent_margin + '%')}}</td>
                <td>{{numberFormatWCurrencyCheck('£' + sss.gross_profit)}}</td>
                <td>{{numberFormatWCurrencyCheck('£' + sss.gp_hour_serving)}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
	import Sorter from '../../../../sorter';
	import TableSortingHelper from "./helpers/TableSortingHelper";

  export default {
		name: "SiteSaleStatsTable",
		components: {TableSortingHelper},
		props: [
            'traffic_light_colours',
            'traffic_light_labels',
			'site_sale_stats',
			'active_status',
			'route_selected',
			'min_max',
			'single_day_component',
            'franchise'
		],

		data(){
			return {
				sort_direction: 'asc',
				sort_prop: (this.single_day_component) ? 'started_at' : 'site_name',
				sorter: '',

                activeModal: null
			}
		},

		beforeMount() {
		  this.colours = this.traffic_light_colours;
			this.sorter = new Sorter([], this.sort_prop, this.sort_direction);
		},

		methods:{
			sort(prop) {
				//if already select reverse direction
				if(prop === this.sort_prop)
					this.sort_direction = this.sort_direction === 'asc'
						? 'desc'
						: 'asc';
				else
					//defaults to asc
					this.sort_direction = 'asc';

				this.sort_prop = prop;
			},
			isSorted(prop) {
				return this.sort_prop == prop;
			},
			getDate(timestamp, format = 'H:mm'){
				return moment.unix(timestamp).format(format);
			},

			trafficLightClass(stop){
			    const colours = {
                    0: 'unassigned',
                    1: 'green',
                    2: 'amber',
                    3: 'red',
                    5: 'dropped',
                    6: 'black-square',
                }

                if(stop.dated_dropped) {
                  return `traffic-light--dropped`;
                }
                return `traffic-light--${colours[stop.traffic_light]}`;
            },
      trafficLightLabel(stop){

        if(stop.dated_dropped) {
          return `${this.traffic_light_labels[5]}`;
        }

        return `${this.traffic_light_labels[stop.traffic_light]}`;
      },
		},

		computed: {
			uniqueRoutes(){
				this.site_sale_stats.reduce(function(routes, item){
					routes[item.route_name] = true;
					return routes;
				}, {});

				return Object.keys(
							this.site_sale_stats.reduce(function(routes, item){
								routes[item.route_name] = true;
									return routes;
								}, {}
							)
						).length;
			},

			site_sale_stats_table(){
				let
					sss = this.site_sale_stats_sorted,
					table = [];

				if (this.active_status === 'all'){
					table = sss;
				}
				else if (this.active_status === 'active'){
					sss.forEach(function (stop){
						if (stop.dated_dropped === null)
							table.push(stop);
					});
				}
				else if (this.active_status === 'drop_added'){
					sss.forEach(function (stop){
						if (stop.dated_dropped !== null || stop.added)
							table.push(stop);
					});
				}

				return table;
			},

			site_sale_stats_sorted(){
				return this
							.sorter
							.setValues(Object.assign([], this.site_sale_stats))
							.setDirection(this.sort_direction)
							.setKey(this.sort_prop)
							.sort();
			}
		}
	}
</script>
