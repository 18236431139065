<template>
	<div class="dashboard-page">

		<!--Top Filters Header-->
		<report-nav-bar
			:routes="routes"
			:default_route_selected="[]"
			:date_ranges="date_ranges"

			:default_range="0"
			:hide_today="true"
			:hide_avg="true"
			:show_active_product_status="false"

			@route_selected="route_selected = $event"
			@to_date="to_date = $event"
			@from_date="from_date = $event"
		></report-nav-bar>

		<!--Content of report-->
		<div class="dashboard-page--content">

			<div class="dashboard-page--component mt-4">
				<div class="row">
					<div class="col-12">
						<h2>Product Group Sales Graph</h2>
					</div>
					<div class="col-12">
						<product-group-sales-graph
							:load_url="load_product_url"

							:product_groups="product_groups"

							:route_selected="route_selected"
							:to_date="to_date"
							:from_date="from_date"

							:routes="routes"

							cost-label="GP"
						></product-group-sales-graph>
					</div>
				</div>
			</div>

			<div class="dashboard-page--component mt-4">
				<div class="row">
					<div class="col-12">
						<h2>Wastage Graph</h2>
					</div>
					<div class="col-12">
						<product-group-sales-graph
							:load_url="load_wastage_url"

							:product_groups="product_groups"

							:route_selected="route_selected"
							:to_date="to_date"
							:from_date="from_date"

							:routes="routes"

							cost-label="COST"
						></product-group-sales-graph>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ReportNavBar from "../components/reporting/ReportNavBar";
	import ProductGroupSalesGraph from "../components/reporting/ProductGroupSalesGraph";
	export default {
		name: "ProductWastageStatsPage",
		components: {
			ProductGroupSalesGraph,
			ReportNavBar
		},
		props: [
			'colors',
			'routes',
			'franchise',

			'load_product_url',
			'load_wastage_url',

			'product_groups',

			'default_from_date',
			'default_to_date',

			'date_ranges',
		],
		data(){
			return {
				active_product_status: 'all',

				product_stats: null,

				route_selected: [],
				from_date: null,
				to_date: null,
			}
		},

		methods: {

		},

		computed: {
			routeName(){
				if (this.routes && this.routes[this.route_selected]){
					return this.routes[this.route_selected].name || 'Ad Hoc';
				}

				return 'n/a';
			},
		},
	}
</script>