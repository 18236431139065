<template>
    <div class="row">
        <div class="col-6" v-show="franchiseNumber != ''">
            <label class="d-block text-left" :for="`recipient${finalIdPrefix}`">Recipient</label>
            <select2 class="form-control" :id="`recipient${finalIdPrefix}`" v-model="data.recipient">
                <option value="" disabled selected>Select recipient type</option>
                <option v-for="(recipient,key) in recipientTypes" :value="key">{{recipient}}</option>
            </select2>
        </div>
        <div class="col-6">
            <div id="route-select" v-show="data.recipient == 1 || data.recipient == 3">
                <label class="d-block text-left" :for="`route${finalIdPrefix}`">Route</label>
                <select2 ref="route" class="form-control" :id="`route${finalIdPrefix}`" v-model="data.route">
                    <option value="" disabled selected>Select route</option>
                    <option v-for="route in routes" :value="route.id">{{route.name}}</option>
                </select2>
            </div>
            <div id="stop-select" v-show="data.recipient == 2">
                <label class="d-block text-left" :for="`stop${finalIdPrefix}`">Stops</label>
                <div class="d-flex notification-stops">
                    <select2 ref="stop" class="form-control" :id="`stop${finalIdPrefix}`" multiple="multiple" v-model="data.stop" :options="{ placeholder: 'Select Stops', templateResult: formatStopDropdownElement }">
                        <optgroup v-for="route in routes" :label="route.name">
                            <option v-for="stop in route.stops" :value="stop.id" :data-treat-stop="stop.treat_stop">{{stop.name}}</option>
                        </optgroup>
                    </select2>
                    <button class="btn btn-primary" @click="SelectStops">Select {{data.stop.length > 0 ? 'None' : 'All'}}</button>
                </div>
            </div>
        </div>
        <div class="col-6 mt-3" v-if="data.recipient == 0 || data.recipient == 1">
            <label class="d-block text-left" :for="`disabled-days${finalIdPrefix}`">Who would you like to send this to?</label>
            <select2 ref="send_to_disabled_stops" class="form-control" :id="`disabled-days${finalIdPrefix}`" v-model="data.send_to_disabled_stops">
                <option :value="0">Only send to sites visited on scheduled send date</option>
                <option :value="1">Send to all applicable sites</option>
            </select2>
        </div>

        <div class="col-6 mt-3" v-if="data.recipient == 0 || data.recipient == 1">
            <label class="d-block text-left" :for="`send_to_only_treat_stops${finalIdPrefix}`">Which stops do you want to send this notification to?</label>
            <select2 ref="send_to_only_treat_stops" class="form-control" :id="`send_to_only_treat_stops${finalIdPrefix}`" v-model="data.send_to_only_treat_stops">
                <option :value="null">All stops</option>
                <option :value="0">All stops except treat stops</option>
                <option :value="1">Only Treat Stops</option>
            </select2>
        </div>
    </div>
</template>

<script>
    import Select2 from "./Select2";

    export default {
        name: 'NotificationRecipients',

        components: {
            Select2
        },

        props: {
            idPrefix: {
                default: ''
            },

            franchiseNumber: {
                required: true
            },

            recipientTypes: {
                required: true,
                type: Array
            },

            routes: {
                required: true,
                type: [Array, Object]
            }
        },

        data(){
            return {
                data: {
                    recipient: null,

                    route: null,
                    stop: [],

                    send_to_disabled_stops: 0,
                    send_to_only_treat_stops: null
                }
            }
        },

        methods: {
            SelectStops(e){
                //stop submitting the form
                e.preventDefault();

                if(this.data.recipient == 2){ //Stop Customers

                    this.data.stop = this.data.stop.length > 0
                        ? []
                        : Object.values(this.routes)
                                .reduce((carry, route) => {
                                    Object.values(route.stops)
                                        .forEach((stop) => {
                                            carry.push(stop.id);
                                        })

                                    return carry;
                                }, []);
                }
            },

            formatStopDropdownElement(state){
                if (!state.id || state.element.dataset.treatStop == 0) {
                    return state.text;
                }

                return $(
                    `<div class='d-flex justify-content-between'><span>${state.text}</span><span>(Treat Stop)</span></div>`
                )
            }
        },

        computed: {
            finalIdPrefix(){
                return this.idPrefix !== ''
                    ? `-${this.idPrefix}`
                    : '';
            }
        },

        watch: {
            'data.recipient'(value){
                this.data.stop = [];

                //if we have 1 route and we're selecting routes auto-select that route
                this.data.route = (value == 1 && this.routes.length == 1)
                    ? this.routes[0].id
                    : null;

                this.$emit('recipient', value);
            },

            'data.route'(value){
                this.$emit('route', value);
            },

            'data.stop'(value){
                this.$emit('stops', value);
            },

            'data.send_to_disabled_stops'(value){
                this.$emit('send-to-disabled-stops', value);
            },

            'data.send_to_only_treat_stops'(value){
                this.$emit('send-to-only-treat-stops', value);
            }
        }
    }
</script>
