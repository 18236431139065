<template>
    <div>
        <div class="alert alert-danger alert-dismissible fade show" role="alert" v-for="(error, index) in errors">
            {{error[0]}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="removeError(index)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <form method="POST" id="rapos-form" :action="submitUrl" @submit="submit">
            <input type="hidden" name="_token" :value="csrfToken" v-if="csrfToken != undefined" >

            <select class="form-control select2" name="traffic_light" v-model="trafficLight">
                <option :value="index" v-for="(option, index) in options" :disabled="index === 0">{{option}}</option>
            </select>

            <textarea name="notes" class="form-control my-3" rows="6" placeholder="Reason"></textarea>

            <div class="d-flex justify-content-end">
              <button class="btn btn-primary" type="submit">Save</button>
              <button v-if="cancel_button" class="btn btn-secondary ml-1" type="button" @click.prevent="cancel">Cancel</button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: "StopTrafficLightForm",

    props: {
        value: {
          required: true
        },

        colours: {
          required: true
        },

        submitUrl: {
            required: true
        },

        csrfToken: {
            type: String
        },

        useAxios: {
          type: Boolean,
          default: false
        },
        cancel_button: {
          type: Boolean,
          default: false
        },
    },

    data(){
        return {
            errors: {},
            trafficLight: 0
        }
    },

    beforeMount() {
      this.options = this.colours;
      this.trafficLight = this.value;
    },

    methods: {
        submit(e){
            if(this.useAxios){
                e.preventDefault();

                axios.post(
                    this.submitUrl,
                    new FormData(e.target)
                ).then((response) => {
                    this.$emit('success', this.trafficLight)
                }).catch((error) => {
                    this.errors = error.response.data.errors;
                });
            }
        },

        cancel(e) {
            this.$emit('success', this.value)
        },

        removeError(index){
            Vue.delete(this.errors, index);
        }
    }
}
</script>
