<template>

</template>

<script>
    export default {

        props: ["pricemodes","editdata"],

        data() {
            return {
                items: [{"counter":1,"name":0,"cost":0.00,"net_prices":[],"gross_prices":[],"id":null}],
                counter: 1,
                modes: null,
                vat: "1",
                loyalty: null,
                vatRate: process.env.MIX_VAT_RATE
            };
        },
        mounted() {
            this.modes = JSON.parse(this.pricemodes);
            let prefill = JSON.parse(this.editdata);
            this.loyalty = prefill.campaign_type;
            if(prefill != null)
            {
                this.items = [];
                this.vat = ((prefill.vat === 1) ? '1' : '0');
                for(var i = 0; i < prefill.sizes.length; i++) {
                    let name = "0";
                    if(prefill.sizes[i].potential_size_id != null)
                    {
                        name = prefill.sizes[i].potential_size_id;
                    }
                    this.items.push({
                        "counter": i+1,
                        "name": name ,
                        "cost": prefill.sizes[i].cost_price,
                        "net_prices": {},
                        "gross_prices": {},
                        "id": prefill.sizes[i].id
                    });
                    let self = this;
                    for(var j = 0; j<prefill.sizes[i].prices.length;j++)
                    {
                        if(prefill.sizes[i].prices[j] != null) {
                            self.items[i].gross_prices[prefill.sizes[i].prices[j].price_mode_id] = prefill.sizes[i].prices[j].price;
                            self.items[i].net_prices[prefill.sizes[i].prices[j].price_mode_id] = prefill.sizes[i].prices[j].price / (1 + ((prefill.vat === 1) ? self.vatRate : 0));
                        }
                    };
                    //this.items[i].net_prices.push([this.modes[j],prefill.sizes[i].prices[j].price / (1 + ((prefill.vat === 1) ? this.vatRate : 0))});
                }

                this.counter = i+1;
            }
            console.log(prefill);
            console.log('Component mounted.')
        },
        methods: {
            newSize() {
                this.counter++;
                let item = {"counter":this.counter,"name":"default","cost":0.00,"net_prices":[],"gross_prices":[],"id":null};
                this.items.push(item);
            },
            deleteSize(size){
                console.log(size);
                if(this.items.length > 1)
                {
                    let index = this.items.indexOf(size);
                    console.log(this.items);
                    if(index > -1)
                    {
                        this.items.splice(index,1);
                    }
                    console.log(this.items);
                }
                else{
                    alert('There must be at least one size!');
                }

            },
            calcNetPrice(item, key)
            {

            },
            round(value, decimals) {
                return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
            }

        }
    }
</script>
