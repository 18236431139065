<template>
    <form method="POST" id="rapos-form" class="col-12" :action="submitUrl" enctype="multipart/form-data" @submit.prevent="submitForm">
        <div class="row">
            <!-- MESSAGES -->
            <div class="col-12" v-if="Object.keys(errors).length > 0 || (message != null)">
                <div class="alert alert-success" v-if="message != null">
                    {{message}}
                </div>
                <div class="alert alert-danger" role="alert" v-for="error in errors">
                    {{error[0]}}
                </div>
            </div>

            <div class="col-12" v-if="franchiseNumber == ''">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label for="template">Notification Group</label>
                            <select2
                                class="form-control"
                                :class="{'is-invalid': errors[`groups`] !== undefined}"

                                id="group"
                                required="required"
                                v-model="data.groups"
                                :options="select2GroupConfig"

                                :multiple="true"

                                :has-error="errors[`group`] !== undefined"
                            >
                                <option v-if="notification.notification_group != undefined" :value="notification.notification_group.name">{{notification.notification_group.name}}</option>
                            </select2>
                            <div class="invalid-feedback" v-if="errors[`group`] !== undefined">{{ errors[`group`][0] }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="form-group">
                    <label for="title">Subject</label>
                    <input type="text" class="form-control" :class="{'is-invalid': errors[`title`] !== undefined}" name="title" id="title" maxlength="100" aria-describedby="title" required placeholder="Subject" v-model="data.title">
                    <div class="invalid-feedback" v-if="errors[`title`] !== undefined">{{ errors[`title`][0] }}</div>
                </div>
            </div>

            <!-- Notification Type -->
            <div class="col-12 col-md-6 mt-2">
                <label for="template">Type</label>
                <select2
                    class="form-control"
                    :class="{'is-invalid': errors[`template`] !== undefined}"
                    id="template"
                    required="required"
                    v-model="data.template"

                    :readonly="notification.franchise_id === null"

                    :has-error="errors[`template`] !== undefined"
                >
                    <option value="" disabled selected>Select type</option>
                    <option v-for="template in templates" :value="template.id">{{template.name}}</option>
                </select2>
                <div class="invalid-feedback" v-if="errors[`template`] !== undefined">{{ errors[`template`][0] }}</div>
            </div>

            <!-- Send At -->
            <div class="col-12 col-md-6 mt-2" v-if="franchiseNumber != ''">
                <div class="form-group">
                    <div class="d-flex justify-content-between">
                        <label for="send_at">Send At</label>

                        <div class="d-flex">
                            <div class="custom-control custom-radio mr-4">
                                <input class="custom-control-input" type="radio" name="toggle_send_at" id="toggle_send_at_scheduled" value="0" v-model="data.toggle_send_at">
                                <label class="custom-control-label" for="toggle_send_at_scheduled">Scheduled</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" name="toggle_send_at" id="toggle_send_at_now" value="1" v-model="data.toggle_send_at">
                                <label class="custom-control-label" for="toggle_send_at_now">Send Now</label>
                            </div>
                        </div>
                    </div>
                    <flatpickr
                        :class="{'is-invalid': errors[`send_at`] !== undefined}"
                        placeholder="Choose Date & Time"
                        v-model="data.send_at"
                        id="send_at"
                        name="send_at"

                        :disabled="data.toggle_send_at == '1'"
                        v-show="data.toggle_send_at == '0'"

                        :config="{
                            enableTime: true,
                            dateFormat: 'Y-m-d H:i:S',
                            defaultHour: 8,
                            defaultMinute: 30
                        }"
                    />
                    <div class="invalid-feedback" v-if="errors[`send_at`] !== undefined">{{ errors[`send_at`][0] }}</div>
                </div>
            </div>

            <!-- Recipient Data -->
            <div class="col-12 mt-2">
                <notification-recipients
                    :recipient-types="recipientTypes"
                    :routes="routes"
                    :franchise-number="franchiseNumber"

                    @recipient="data.recipient = $event"
                    @route="data.route = $event"
                    @stops="data.stop = $event"
                    @send-to-disabled-stops="data.send_to_disabled_stops = $event"
                    @send-to-only-treat-stops="data.send_to_only_treat_stops = $event"
                ></notification-recipients>
            </div>

            <!-- Images -->
            <div class="col-12 mt-2">
                <div class="prefilled-images my-2 row" v-if="data.use_images">
                    <div class="col-4" v-for="image in notification.images">
                        <img :src="image.s3_url" class="mw-100">
                    </div>

                    <button class="btn btn-primary ml-3 mt-2" @click="data.use_images = false">Use New Images</button>
                </div>

                <div class="row mt-3" v-else>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <h5>Use Existing Image</h5>

                            <label for="existing-image" class="dropzone" @click="toggleSelectImageModal">
                                <span class="d-block">Select from image gallery</span>
                                <input type="text" id="existing-image" name="image">
                                <div class="dropzone-no-images" v-if="currentImage.length == 0">
                                    <button class="btn btn-primary">Select Image</button>
                                </div>
                                <div class="dropzone-images" v-else>
                                    <div class="dropzone-image-wrapper">
                                        <img :src="currentImage[0].full_url" class="dropzone-image">
                                        <p class="dropzone-file-name mb-0">{{currentImage[0].name}}</p>
                                    </div>
                                </div>
                            </label>

                            <modal title="Select Existing Image" v-model="selectImageOpen">
                                <select2
                                    class="form-control w-100"

                                    :options="select2Config"
                                    :value="data.images"

                                    @input="selectExistingImage"
                                    @input-object="currentImage = $event"
                                ></select2>
                            </modal>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <h5>Upload image from your device</h5>

                            <dropzone
                                class="mb-0"

                                name="Images"
                                @input="selectNewImage"

                                title="Select New Image"
                                :value="data.images"
                            ></dropzone>
                            <span class="font-italic mb-2">Please ensure you have permission to use the image.</span>

                            <div v-if="isNewImage && data.images != null">
                                <span class="d-block font-weight-bold mt-3">Save this image to your library?</span>
                                <span class="d-block mb-2">Tick the box to save the image to your library</span>

                                <div class="d-flex">
                                    <input class="form-control mr-2 flex-grow-1" placeholder="Enter Image Name" v-model="data.upload_image_name">

                                    <label class="tickbox form-control w-auto cursor-pointer text-light" :class="{'bg-primary': data.upload_to_library, 'text-white': data.upload_to_library}">
                                        <input class="form-check-input sr-only" type="checkbox" :value="true" v-model="data.upload_to_library">

                                        <i class="fas fa-check"></i>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-12 mt-2">
                <div class="form-group">
                    <label for="body">Body</label>
                    <redactor
                        :class="{'is-invalid': errors[`body`] !== undefined}"

                        v-model="data.body"
                        name="body"
                        id="body"
                        placeholder="Body"
                        :config="{
                            formatting: ['p', 'h1', 'h2', 'h3'],
                            buttons: ['format', 'bold', 'ul'],
                            stylesClass: 'notification-styles'
                        }"
                    />
                    <div class="invalid-feedback" v-if="errors[`body`] !== undefined">{{ errors[`body`][0] }}</div>
                </div>
            </div>

            <div class="col-12 d-flex justify-content-center">
                <button type="submit" class="btn btn-primary mt-5" :disabled="hasSent">{{franchiseNumber != '' ? 'Send/Schedule Notification' : 'Save Pre-Designed Notification'}}</button>
            </div>
        </div>
    </form>
</template>

<script>
    import Select2 from "./generic/Select2";
    import Redactor from "./generic/Redactor";
    import Dropzone from "./generic/Dropzone";
    import NotificationRecipients from "./generic/NotificationRecipients";
    import Flatpickr from "./generic/Flatpickr";
    import Modal from "./generic/Modal";

    export default {
        name: "NotificationCreate",
        components: {Modal, Flatpickr, Dropzone, Redactor, Select2, NotificationRecipients},
        props: {
            templates: {
                required: true,
                type: Object
            },
            routes: {
                required: false,
                type: Array,
                default: () => []
            },
            notificationTypes: {
                required: true,
                type: Array
            },
            recipientTypes: {
                required: true,
                type: Array
            },
            submitUrl: {
                required: true,
                type: String
            },
            redirectUrl: {
                required: true,
                type: String
            },
            franchiseNumber: {
                required: false,
                type: String,
                default: ''
            },
            notification: {
                required: false,
                type: Object,
                default: () => { return {}; }
            },
            imageLibraryUrl: {
                required: true,
                type: String
            },
            groupUrl: {
                type: String,
                default: null
            }
        },

        data() {
            return {
                data: {
                    groups: [],
                    title: null,
                    route: null,
                    stop: [],
                    recipient: null,
                    template: null,
                    type: null,
                    body: null,
                    images: null,
                    send_at: null,
                    toggle_send_at: '0',
                    notification_id: null,
                    use_images: false,
                    send_to_disabled_stops: 0,
                    send_to_only_treat_stops: null,
                    upload_to_library: false,
                    upload_image_name: null
                },

                selectImageOpen: false,
                isNewImage: false,
                currentImage: [],

                hasSent: false,
                message: null,
                errors: {}
            }
        },

        beforeMount(){
            this.data = {
                ...this.data,
                ...{
                    body          : this.notification.body,
                    title         : this.notification.title,
                    template      : this.notification.notification_template_id,

                    use_images    : this.notification.images != undefined && this.notification.images.length > 0,
                    notification_id: this.notification.id
                }
            };

            if(this.franchiseNumber == '' && this.notification.notification_groups != undefined){
                this.data.groups = Object.values(this.notification.notification_groups).map((group) => { return group.name });
            }
        },

        methods: {
            selectNewImage(e){
                this.data.images = e;

                this.currentImage = [];
                this.data.upload_to_library = false;
                this.data.upload_image_name = null;
                this.isNewImage = true;
            },

            selectExistingImage(e){
                this.data.images = e;
                this.selectImageOpen = false;
                this.isNewImage = false;
                this.data.upload_to_library = false;
                this.data.upload_image_name = null;
            },

            toggleSelectImageModal(e){
                e.preventDefault();

                this.selectImageOpen = !this.selectImageOpen;

                this.data.images = null;
            },

            submitForm(){
                if(this.hasSent){
                    return;
                }

                this.hasSent = true;
                let hasSentImages = false;
                let formData = new FormData();

                Object.keys(this.data).forEach((item) => {
                    if(this.data[item] == null){
                        return;
                    }

                    if(item == 'images'){
                        hasSentImages = true;

                        if(typeof this.data[item] == 'object'){
                            formData.append(
                                item,
                                this.data[item][0],
                                this.data[item][0].name
                            );
                        } else {
                            formData.append(item, this.data[item]);
                        }

                    } else if(typeof this.data[item] == 'object' && this.data[item] != null) {
                        Object.values(this.data[item]).forEach((value) => {
                            formData.append(item + '[]', value);
                        });
                    } else {
                        formData.append(item, this.data[item]);
                    }
                });

                if(this.data.use_images || (!hasSentImages && confirm('You have not attached any images - proceed anyway?')) || hasSentImages){
                    axios.post(this.submitUrl, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                         }
                    }).then((response) => {
                        this.message = response.data.message;
                        this.errors = {};

                        window.location.href = this.redirectUrl;
                    }).catch((error) => {
                        this.hasSent = false;
                        this.errors = error.response.data.errors;
                    });
                } else {
                    this.hasSent = false;
                }
            },

            genericSelect2AjaxConfig(url, callback = null) {
                return {
                    ajax: {
                        url: url,
                        delay: 250,
                        cache: true,
                        processResults: callback != null ? callback : function(data, params) {
                            params.page = params.page || 1;

                            return {
                                results: data.data,
                                pagination: {
                                    more: params.page < data.last_page
                                }
                            };
                        }
                    },
                    templateSelection: function(record){
                        return record.name || record.text;
                    }
                }
            }
        },

        computed: {
            select2GroupConfig() {
                return {
                    ...this.genericSelect2AjaxConfig(this.groupUrl, function(data, params){
                        params.page = params.page || 1;

                        return {
                            results: Object.values(data.data).map(function(item){
                                return {
                                    id: item.name,
                                    name: item.name
                                };
                            }),
                            pagination: {
                                more: params.page < data.last_page
                            }
                        };
                    }),
                    tags: true,
                    multiple: true,
                    placeholder: 'Select Group',
                    templateResult: function(record){
                        return record.name || record.text;
                    }
                }
            },

            select2Config() {
                return {
                    ...this.genericSelect2AjaxConfig(this.imageLibraryUrl),
                    placeholder: 'Select Image',
                    templateResult: function(record){
                        if(record.loading){
                            return record.text;
                        }

                        return $(`
                            <div class='d-flex justify-space-between align-items-center'>
                                <div class='mr-3'>
                                    <img src='${record.full_url}' height='40' loading='lazy' alt='${record.name} Image'>
                                </div>
                                <div>${record.name}</div>
                            </div>
                        `);
                    }
                }
            },

            templateImageCount(){
                return this.data.template == null ? 0 : this.templates[this.data.template].num_images;
            },

            templateImageTitle(){
                return this.data.template == null ? 'Images (select template first)' : 'Images (up to ' + this.templates[this.data.template].num_images + ' press ctrl to upload all at the same time)';
            }
        },

        watch: {
            'data.toggle_send_at'(value){
                if(value == "1"){
                    this.data.send_at = null;
                }
            }
        }
    }
</script>
