<template>
	<div class="dashboard-page">

		<!--Top Filters Header-->
		<report-nav-bar
			:routes="routes"
			:date_ranges="date_ranges"

			:default_range="0"
			:hide_today="true"
			:hide_avg="true"
			:show_active_status="true"

			@load="loadData"

			@active_status="active_status = $event"
			@route_selected="route_selected = $event"
			@to_date="to_date = $event"
			@from_date="from_date = $event"

			v-if="single_day_component == undefined"
		></report-nav-bar>

		<!--Loader-->
		<self-building-square-spinner
			v-if="loading"
			class="mx-auto"
			:animation-duration="6000"
			:size="40"
			color="#ff1d5e"
		/>

		<!--Content of report-->
		<div v-else-if="stop_stats" class="dashboard-page--content">
			<div class="dashboard-page--component" v-if="single_day_component == undefined">
				<div class="row">
					<div class="col-12">
						<h4 class="dashboard-page--component-title">Overview</h4>
					</div>
				</div>
			</div>

			<report-generic-table
				:data="[
						{title: 'Starting no. of coffee stops', value: stop_stats.starting_stops},
						{title: 'No. of coffee stops dropped', value: stop_stats.stops_dropped},
						{title: 'Ending no. of coffee stops', value: stop_stats.ending_stops},
						{title: 'No. of coffee stops added', value: stop_stats.stops_added},
						{title: '', value: ''},
						{title: '', value: ''},
						{title: 'Av. coffee stops per day', value: stop_stats.avg_stops_day},
						{title: 'Transactions per coffee stop', value: stop_stats.avg_transactions_per_stop},
						{title: 'Av. GP per coffee stop', value: '£' + (stop_stats.avg_gp_per_stop)},
						{title: 'Av. transaction gross amount', value: '£' + stop_stats.avg_transactions_gross},
					]"

				v-if="single_day_component == undefined"
			></report-generic-table>

			<!--Site Sale Statistics-->
			<div class="dashboard-page--component">
				<div class="row pt-4">
					<div class="col-12 text-left">
						<h4 class="dashboard-page--component-title">Coffee Stop Statistics</h4>
					</div>
					<div class="col-12">
						<site-sale-stats-table
                :traffic_light_colours="traffic_light_colours"
                :traffic_light_labels="traffic_light_labels"

                :site_sale_stats="site_sale_stats"
							:active_status="active_status"
							:route_selected="route_selected"
							:min_max="min_max"
							:single_day_component="single_day_component"
                            :franchise="franchise"
						></site-sale-stats-table>
					</div>
<!--					<div class="col-12 row sss-pie-charts">-->
<!--						<div class="col-6 sss-chart">-->
<!--							<pie-chart-->
<!--								:data="getGPPerStopPieData()"-->
<!--								name="GP"-->
<!--								title="Profit Per Stop"-->
<!--								:no_margin="true"-->
<!--								prefix="£"-->
<!--								:color="colors"-->
<!--							></pie-chart>-->
<!--						</div>-->
<!--						<div class="col-6 sss-chart-2">-->
<!--							<pie-chart-->
<!--								:data="getGPPerMinutePieData()"-->
<!--								name="GP / Minute"-->
<!--								title="Profit Per Minute (per stop)"-->
<!--								:no_margin="true"-->
<!--								prefix="£"-->
<!--								:color="colors"-->
<!--							></pie-chart>-->
<!--						</div>-->
<!--					</div>-->
				</div>
			</div>
		</div>

		<!--No data to show-->
		<div v-else>
			<h2>No results found</h2>
		</div>
	</div>
</template>

<script>
	import PieChart from "../components/reporting/PieChart";
	import { SelfBuildingSquareSpinner  } from 'epic-spinners'
	import ReportTimingTable from "../components/reporting/ReportTimingTable";
	import ReportPaymentTypeTable from "../components/reporting/ReportPaymentTypeTable";
	import ReportGenericTable from "../components/reporting/ReportGenericTable";
	import BarChart from "../components/reporting/BarChart";
	import ReportNavBar from "../components/reporting/ReportNavBar";
	import SiteSaleStatsTable from "../components/reporting/SiteSaleStatsTable";
	export default {
		name: "StopStatsPage",
		components: {
			SiteSaleStatsTable,
			ReportNavBar,
			BarChart,
			ReportGenericTable, ReportPaymentTypeTable, ReportTimingTable, PieChart, SelfBuildingSquareSpinner},
		props: [
			'colors',
			'routes',
			'franchise',
      'traffic_light_colours',
      'traffic_light_labels',
			'load_url',

			'default_from_date',
			'default_to_date',

			'date_ranges',

			'single_day_component',
			'default_selected_routes'
		],
		data(){
			return {
				loading: false,
				reload: false,

        active_status: 'all',

				stop_stats: null,
				site_sale_stats: null,
				min_max: null,

				route_selected: [],
				from_date: null,
				to_date: null,

				total_count: null,
				total_days: null,

				total_sale_data: null
			}
		},

		mounted(){
			if(this.single_day_component){
				this.from_date = this.default_from_date;
				this.to_date = this.default_from_date;
				this.route_selected = this.default_selected_routes;
				this.loadData();
			}
		},

		methods: {
			loadData(){
				let _this = this;

				if (_this.loading){
					_this.reload = true;
					return;
				}

				_this.loading = true;

				let _routes = null;
				if(_this.route_selected.length > 0){
					if(Array.isArray(_this.route_selected)) {
						_routes = _this.route_selected.filter(item => item != null).map(function(item){
							return _this.routes[item].id;
						});
					} else {
						_routes = [_this.routes[_this.route_selected].id];
					}
				}

				axios.post(_this.load_url, {
					route: _routes,
					from_date: _this.from_date,
					to_date: _this.to_date,
					single_day_component: _this.single_day_component
				})
					.then(function (response){
						_this.loading = false;

						if (_this.reload){
							_this.reload = false;
							_this.loadData();
							return;
						}

						_this.stop_stats = response.data.stop_stats;
						_this.site_sale_stats = response.data.site_sale_stats;
						_this.total_sale_data = response.data.total_sale_data;
						_this.min_max = response.data.min_max;
						_this.total_count = response.data.total_count;
						_this.total_days = response.data.total_days;
					})
					.catch(function (e){

					});
			},



			getGPPerStopPieData(){
				let data = [], _this = this;
				_this.site_sale_stats.forEach(function (stat){
					data.push({
						name: stat.site_name,
						value: parseFloat(stat.gross_profit)
					});
				});
				return data;
			},

			getGPPerMinutePieData(){
				let data = [], _this = this;
				_this.site_sale_stats.forEach(function (stat){
					data.push({
						name: stat.site_name,
						value: parseFloat(stat.profit_per_min).toFixed(2)
					});
				});
				return data;
			},
		},

		computed: {
			routeName(){
				if (this.routes && this.routes[this.route_selected]){
					return this.routes[this.route_selected].name || 'Ad Hoc';
				}

				return 'n/a';
			},

			GPPerDateGraph(){
				let data = [];
				for (let key in this.stop_stats){
					if (this.stop_stats.hasOwnProperty(key)){
						data.push({
							label: key,
							value: Math.round(this.stop_stats[key] ? this.stop_stats[key].gp : [])
						})
					}
				}
				return data;
			},
			TradingPerDateGraph(){
				let data = [];
				for (let key in this.stop_stats){
					if (this.stop_stats.hasOwnProperty(key)){
						data.push({
							label: key,
							value: Math.round(this.stop_stats[key] ? this.stop_stats[key].trading_time / 60 / 60 : [])
						})
					}
				}
				return data;
			},
			GPPerHourPerDateGraph(){
				let data = [];
				for (let key in this.stop_stats){
					if (this.stop_stats.hasOwnProperty(key)){
						data.push({
							label: key,
							value: Math.round(this.stop_stats[key] ? this.stop_stats[key].gp_per_hr : [])
						})
					}
				}
				return data;
			},
			GPPerHourServingPerDateGraph(){
				let data = [];
				for (let key in this.stop_stats){
					if (this.stop_stats.hasOwnProperty(key)){
						data.push({
							label: key,
							value: Math.round(this.stop_stats[key] ? this.stop_stats[key].gp_per_hr_serving : [])
						})
					}
				}
				return data;
			},
			TransactionsPerDateGraph(){
				let data = [];
				for (let key in this.stop_stats){
					if (this.stop_stats.hasOwnProperty(key)){
						data.push({
							label: key,
							value: Math.round(this.stop_stats[key] ? this.stop_stats[key].transactions : [])
						})
					}
				}
				return data;
			},


			//Targets
			TargetGP(){
				let data = [];
				for (let key in this.stop_stats){
					if (this.stop_stats.hasOwnProperty(key)){
						data.push({
							label: key,
							value: this.stop_stats[key] && this.stop_stats[key].targets && this.stop_stats[key].targets.gp !== null
								? this.stop_stats[key].targets.gp
								: null
						})
					}
				}
				return data;
			},
			TargetHour(){
				let data = [];
				for (let key in this.stop_stats){
					if (this.stop_stats.hasOwnProperty(key)){
						data.push({
							label: key,
							value: this.stop_stats[key] && this.stop_stats[key].targets && this.stop_stats[key].targets.trading_time !== null
								? this.stop_stats[key].targets.trading_time
								: null
						})
					}
				}
				return data;
			},
			TargetGPHour(){
				let data = [];
				for (let key in this.stop_stats){
					if (this.stop_stats.hasOwnProperty(key)){
						data.push({
							label: key,
							value: this.stop_stats[key] && this.stop_stats[key].targets && this.stop_stats[key].targets.gp_h !== null
								? this.stop_stats[key].targets.gp_h
								: null
						})
					}
				}
				return data;
			},
			SiteStopValues(){
				let
					sss = this.site_sale_stats,
					data = {
						'gp': 0,
						'total_count': 0,
						'avg_stops_day': 0,
						'avg_gp_per_stop': 0,
						'transactions_per_stop': 0,
						'avg_transction_gp': 0
					};

				Object.values(this.total_sale_data).forEach(stop => {
					let include = false;

					if(
						this.active_status === 'all' ||
						this.active_status === 'active' && !stop.dropped ||
						this.active_status === 'drop_added' && ( stop.added || stop.dropped )
					){
						include = true;
					}

					if(include) {
						data['gp'] += parseFloat(stop.gross_profit);
						data['avg_stops_day'] += stop.num_stops;
						data['avg_gp_per_stop'] += parseFloat(stop.gross_profit);
						data['transactions_per_stop'] += stop.transactions;
						data['avg_transction_gp'] += parseFloat(stop.gross_profit);
						data['total_count']++;
					}
				});

				return data;
			}
		},

		// watch: {
		// 	default_from_date(){
		// 		if(this.single_day_component){
		// 			this.from_date = this.default_from_date;
		// 			this.to_date = this.default_from_date;
		// 			this.loadData();
		// 		}
		// 	},
		// 	default_selected_routes(){
		// 		if(this.single_day_component){
		// 			this.route_selected = this.default_selected_routes;
		// 			this.loadData();
		// 		}
		// 	}
		// }
	}
</script>
