<template>
    <div class="generic-modal__wrapper">
        <button v-if="useBtn" class="btn" :class="btnClass" @click="open">{{btnLabel}}</button>
        <div class="generic-modal__container" :class="{active: currentValue}" @click="close">
            <div class="generic-modal" @click="$event.stopPropagation()">
                <div class="generic-modal__header d-flex justify-content-between align-items-center" v-if="title != null">
                    <div>{{title}}</div>
                    <div @click="close"> <!-- Must have a div wrapper as using font awesome svg so i is replaced -->
                        <i class="fa fa-times generic-modal__header--close"></i>
                    </div>
                </div>

                <div class="generic-modal__content">
                    <div class="generic-modal__content--title">{{subtitle}}</div>
                    <div class="generic-modal__content--content">
                        <slot></slot>
                    </div>

                    <div class="generic-modal__button-group">
                        <slot name="footer"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Modal",

    props: {
        title: {
            type: String,
            default: null
        },

        subtitle: {
            type: String,
            default: null
        },

        value: {
            type: Boolean
        },

        useBtn: {
            type: Boolean,
            default: false
        },

        btnClass: {
            type: String,
            default: 'btn-primary'
        },

        btnLabel: {
            type: String,
            default: 'View'
        }
    },

    data(){
        return {
            currentValue: this.value
        }
    },

    methods: {
        open(){
            this.toggle(true);
        },

        close(){
            this.toggle(false);
        },

        toggle(value, emit = true){
            this.currentValue = value;

            if(emit){
                this.$emit('input', this.currentValue);
            }
        }
    },

    watch: {
        value(newValue){
            this.currentValue = newValue;
        }
    }
}
</script>
