<template>
	<div class="container px-0">

		<!--Loader-->
		<self-building-square-spinner
			v-if="loading"
			class="mx-auto"
			:animation-duration="6000"
			:size="40"
			color="#ff1d5e"
		/>
		<div v-else-if="data" class="row">
			<div class="col-6">
				<h4 class="dashboard-page--component-title">Sales by Product Group</h4>
				<generic-report-table
					sort_by="gross_profit"
					sort_direction="desc"
					:data="data.sales_records"
					:formatters="{
						'gross_profit': 'currency'
					}"
				></generic-report-table>
			</div>
			<div class="col-6">
				<h4 class="dashboard-page--component-title">Wastage by Product Group</h4>
				<generic-report-table
					sort_by="wastage"
                    sort_direction="desc"
					:data="data.wastage_records"
					:formatters="{
						'wastage': 'currency'
					}"
				></generic-report-table>
			</div>
		</div>
	</div>
</template>

<script>
	import GenericReportTable from "./GenericReportTable";
	import { SelfBuildingSquareSpinner  } from 'epic-spinners'
	export default {
		name: "SalesByProductGroup",
		components: {GenericReportTable, SelfBuildingSquareSpinner},
		props: [
			'load_url',
			'routes',

			'active_product_status',
			'route_selected',
			'to_date',
			'from_date',

			'autoload',
		],

		data(){
			return {
				loading: false,
				reload: false,

				data: null
			}
		},

		mounted(){
			if(this.autoload){
				this.loadData();
			}
		},

		methods: {
			loadData(){
				let _this = this;

				if (_this.loading){
					_this.reload = true;
					return;
				}

				_this.loading = true;

				let _routes = null;
				if(_this.route_selected.length > 0) {
					if(Array.isArray(_this.route_selected)) {
						_routes = _this.route_selected.filter(item => item != null).map(function (item) {
							return _this.routes[item].id;
						});
					} else {
						_routes = [_this.routes[_this.route_selected].id];
					}

				}

				axios.post(_this.load_url, {
					route: _routes,
					filter: _this.active_product_status,
					from_date: _this.from_date,
					to_date: _this.to_date,
				})
					.then(function (response){
						_this.loading = false;

						if (_this.reload){
							_this.reload = false;
							_this.loadData();
							return;
						}

						_this.data = response.data;
					})
					.catch(function (e){

					});
			},
		},

		watch: {
			active_product_status(){
				this.loadData();
			},
			route_selected(){
				this.loadData();
			},
			to_date(){
				this.loadData();
			},
			from_date(){
				this.loadData();
			},
		}
	}
</script>