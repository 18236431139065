/**
 * String formatter (used as default and other sorters extend this)
 */
export default class {
    /**
     * As default make sure this always matches so just return true with no condition
     * @param value
     * @returns {boolean}
     */
    checkValue(value) {
        return true;
    }

    /**
     * used to calculate the value for the formatter to use (-- means there is no data)
     * @param value
     * @returns {null|*}
     */
    getValue(value) {
        //if the value is already null no processing is necessary
        if(value == null){
            return '';
        }

        //no data exists in cell so return null instead for sorting
        if(value.toString().includes('--')) {
            return '';
        }

        return value;
    }

    /**
     * compares the 2 values and returns 1,-1,0 for the sorter
     * @param a
     * @param b
     * @returns {number} returns either (-1,1 or 0)
     */
    compare(a, b) {

        //get the values to compare
        a = this.getValue(a);
        b = this.getValue(b);

        //compare the values and if they aren't equal return either 1 or -1 (sorter uses these values to know which is greater/lesser)
        if (a > b) {
            return 1;
        } else if (a < b) {
            return -1;
        }

        return 0;
    }
}