<template>
	<div class="container px-0" style="min-height: 400px">
		<div class="row">
			<div class="col-8">
				<!--Loader-->
				<self-building-square-spinner
					v-if="loading"
					class="mx-auto"
					:animation-duration="6000"
					:size="40"
					color="#ff1d5e"
				/>

				<div v-else-if="data">
					<div class="colors mb-5 d-flex">
						<div v-for="color in colors" class="ml-2">
							<div class="badge badge-pill badge-primary ml-2 d-inline-block" style="width: 16px; height: 16px" :style="{'background-color': color.color}"></div>
							{{color.name}}
						</div>
					</div>

					<line-chart
						:data="data.data"
						:headers="data.headers"
					></line-chart>
				</div>
			</div>
			<div class="col-4">
				<div class="row">
					<div class="col-12">
						<h6>Select Product Group(s)</h6>
					</div>
				</div>
				<div class="row">
					<div class="col-12 d-flex">
						<div class="input-toggle my-auto">
							<div class="input-toggle--item" v-on:click="gp = true" v-bind:class="gp ? 'active' : ''">
								{{costLabel == undefined ? 'COST' : costLabel}}
							</div>
							<div class="input-toggle--item" v-on:click="gp = false" v-bind:class="!gp ? 'active' : ''">
								UNITS
							</div>
						</div>
						<a
							href="javascript:void(0)"
							class="btn btn-primary py-auto my-auto ml-3"
							v-on:click="toggle(null)"
						>
							{{isActive(null) ? 'Select none' : 'Select all'}}
						</a>
					</div>
				</div>
				<div class="row mt-3">
					<div class="col-4" v-for="product_group in product_groups">
						<a
							href="javascript:void(0)"
							class="badge badge-pill p-2 mb-2 w-100"
							v-on:click="toggle(product_group.id)"
							v-bind:class="isActive(product_group.id) ? 'badge-primary' : 'badge-secondary'"
						>{{product_group.name}}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { SelfBuildingSquareSpinner  } from 'epic-spinners'
	import LineChart from "./LineChart";
	export default {
		name: "ProductGroupSalesGraph",
		components: {LineChart, SelfBuildingSquareSpinner},
		props: [
			'load_url',
			'routes',

			'product_groups',
			'route_selected',
			'to_date',
			'from_date',

			'costLabel'
		],

		data(){
			return {
				loading: false,
				reload: false,

				data: null,

				gp: true,

				product_groups_selected: []
			}
		},

		beforeMount(){
			this.toggle(null);
		},

		methods: {
			loadData(){
				let _this = this;

				if (_this.loading){
					_this.reload = true;
					return;
				}
				if (!this.to_date || !this.from_date){
					return;
				}

				_this.loading = true;

				let _routes = null;
				if(_this.route_selected.length > 0){
					_routes = _this.route_selected.map(function(item){
						return _this.routes[item].id;
					});
				}

				axios.post(_this.load_url, {
					route: _routes,
					filter: _this.active_product_status,
					from_date: _this.from_date,
					to_date: _this.to_date,
					product_groups: _this.product_groups_selected,
					gp: _this.gp
				})
					.then(function (response){
						_this.loading = false;

						if (_this.reload){
							_this.reload = false;
							_this.loadData();
							return;
						}

						_this.data = response.data;
					})
					.catch(function (e){

					});
			},

			toggle(id){
				let _this = this;

				if (id === null){
					if (_this.product_groups_selected.length === _this.product_groups.length){
						_this.product_groups_selected = [];
					}
					else {
						_this.product_groups_selected = [];
						_this.product_groups.forEach(function (group){
							_this.product_groups_selected.push(group.id);
						});
					}
				}
				else {
					for (let i = 0; i < _this.product_groups_selected.length; i ++){
						if (_this.product_groups_selected[i] === id){
							_this.product_groups_selected.splice(i, 1);
							this.loadData();
							return;
						}
					}

					_this.product_groups_selected.push(id);
				}

				this.loadData();
			},

			isActive(id){
				if (id === null){
					return this.product_groups_selected.length === this.product_groups.length;
				}
				for (let i = 0; i < this.product_groups_selected.length; i ++){
					if (this.product_groups_selected[i] === id){
						return true;
					}
				}
				return false;
			}
		},

		computed: {
			colors(){
				if (!this.data) return [];

				let _data = [];

				this.data.data.forEach(function (record){
					_data.push({
						name: record.name,
						color: record.colour
					});
				});

				return _data;
			}
		},

		watch: {
			route_selected(){
				this.loadData();
			},
			to_date(){
				this.loadData();
			},
			from_date(){
				this.loadData();
			},
			gp(){
				this.loadData();
			},
		}
	}
</script>